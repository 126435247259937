import {
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_RESPONSE,
  GET_REVIEWS_ERROR,
  GET_OFFICIAL_BRANDS_REQUEST,
  GET_OFFICIAL_BRANDS_RESPONSE,
  GET_OFFICIAL_BRANDS_ERROR,
  GET_FIX_UP_IN_MEDIA_REQUEST,
  GET_FIX_UP_IN_MEDIA_RESPONSE,
  GET_FIX_UP_IN_MEDIA_ERROR,
  GET_SUCURSALES_CERCANAS_REQUEST,
  GET_SUCURSALES_CERCANAS_RESPONSE,
  GET_SUCURSALES_CERCANAS_ERROR,
  GET_SEARCH_MODELS_ERROR,
  GET_SEARCH_MODELS_RESPONSE,
  GET_SEARCH_MODELS_REQUEST,
  GET_TOP_BANNER_REQUEST,
  GET_TOP_BANNER_RESPONSE,
  GET_TOP_BANNER_ERROR,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_RESPONSE,
  GET_PARTNERS_ERROR,
  GET_INVESTORS_REQUEST,
  GET_INVESTORS_RESPONSE,
  GET_INVESTORS_ERROR,
  GET_PROMOTIONAL_BANNER_REQUEST,
  GET_PROMOTIONAL_BANNER_RESPONSE,
  GET_PROMOTIONAL_BANNER_ERROR,
  GET_FAQ_REQUEST,
  GET_FAQ_RESPONSE,
  GET_FAQ_ERROR,
  GET_FAQ_PATH_REQUEST,
  GET_FAQ_PATH_RESPONSE,
  GET_FAQ_PATH_ERROR
} from 'redux/actions/common.actions';
import STATUS from 'constants/status';

const initialState = {
  reviews: [],
  lastWeekReviews: [],
  officialBrands: [],
  fixUpInMedia: [],
  sucursalesCercanas: [],
  modelsByText: [],
  topBanner: [],
  partners: [],
  investors: [],
  promotionalBanner: [],
  FAQ: [],
  FAQPath: [],
  getInvestorsStatus: STATUS.idle,
  getReviewsStatus: STATUS.idle,
  getLastWeekReviewsStatus: STATUS.idle,
  getOfficialBrandsStatus: STATUS.idle,
  getFixUpInMediaStatus: STATUS.idle,
  getSucursalesCercanasStatus: STATUS.idle,
  getModelsByTextStatus: STATUS.idle,
  getTopBannersStatus: STATUS.idle,
  getPartnersStatus: STATUS.idle,
  getPromotionalBannerStatus: STATUS.idle,
  getFAQStatus: STATUS.idle,
  getFAQPathStatus: STATUS.idle
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEWS_REQUEST:
      return {
        ...state,
        getReviewsStatus: STATUS.loading
      };
    case GET_REVIEWS_RESPONSE:
      return {
        ...state,
        getReviewsStatus: STATUS.success,
        reviews: action.res
      };
    case GET_REVIEWS_ERROR:
      return {
        ...state,
        getReviewsStatus: STATUS.error
      };
    case GET_OFFICIAL_BRANDS_REQUEST:
      return {
        ...state,
        getOfficialBrandsStatus: STATUS.loading
      };
    case GET_OFFICIAL_BRANDS_RESPONSE:
      return {
        ...state,
        officialBrands: action.res,
        getOfficialBrandsStatus: STATUS.success
      };
    case GET_OFFICIAL_BRANDS_ERROR:
      return {
        ...state,
        getOfficialBrandsStatus: STATUS.error
      };
    case GET_FIX_UP_IN_MEDIA_REQUEST:
      return {
        ...state,
        getFixUpInMediaStatus: STATUS.loading
      };
    case GET_FIX_UP_IN_MEDIA_RESPONSE:
      return {
        ...state,
        fixUpInMedia: action.res,
        getFixUpInMediaStatus: STATUS.success
      };
    case GET_FIX_UP_IN_MEDIA_ERROR:
      return {
        ...state,
        getFixUpInMediaStatus: STATUS.error
      };
    case GET_SUCURSALES_CERCANAS_REQUEST:
      return {
        ...state,
        getSucursalesCercanasStatus: STATUS.loading
      };
    case GET_SUCURSALES_CERCANAS_RESPONSE:
      return {
        ...state,
        sucursalesCercanas: action.res,
        getSucursalesCercanasStatus: STATUS.success
      };
    case GET_SUCURSALES_CERCANAS_ERROR:
      return {
        ...state,
        getSucursalesCercanasStatus: STATUS.error
      };
    case GET_SEARCH_MODELS_REQUEST:
      return {
        ...state,
        getModelsByTextStatus: STATUS.loading
      };
    case GET_SEARCH_MODELS_RESPONSE:
      return {
        ...state,
        modelsByText: action.res,
        getModelsByTextStatus: STATUS.success
      };
    case GET_SEARCH_MODELS_ERROR:
      return {
        ...state,
        getModelsByTextStatus: STATUS.error
      };
    case GET_TOP_BANNER_REQUEST:
      return {
        ...state,
        getTopBannersStatus: STATUS.loading
      };
    case GET_TOP_BANNER_RESPONSE:
      return {
        ...state,
        topBanner: action.res,
        getTopBannersStatus: STATUS.success
      };
    case GET_TOP_BANNER_ERROR:
      return {
        ...state,
        getTopBannersStatus: STATUS.error
      };
    case GET_PARTNERS_REQUEST:
      return {
        ...state,
        getPartnersStatus: STATUS.loading
      };
    case GET_PARTNERS_RESPONSE:
      return {
        ...state,
        partners: action.res,
        getPartnersStatus: STATUS.success
      };
    case GET_PARTNERS_ERROR:
      return {
        ...state,
        getPartnersStatus: STATUS.error
      };
    case GET_INVESTORS_REQUEST:
      return {
        ...state,
        getInvestorsStatus: STATUS.loading
      };
    case GET_INVESTORS_RESPONSE:
      return {
        ...state,
        investors: action.res,
        getInvestorsStatus: STATUS.success
      };
    case GET_INVESTORS_ERROR:
      return {
        ...state,
        getInvestorsStatus: STATUS.error
      };

    case GET_PROMOTIONAL_BANNER_REQUEST:
      return {
        ...state,
        getPromotionalBannerStatus: STATUS.loading
      };
    case GET_PROMOTIONAL_BANNER_RESPONSE:
      return {
        ...state,
        promotionalBanner: action.res,
        getPromotionalBannerStatus: STATUS.success
      };
    case GET_PROMOTIONAL_BANNER_ERROR:
      return {
        ...state,
        getPromotionalBannerStatus: STATUS.error
      };
    case GET_FAQ_REQUEST:
      return {
        ...state,
        getFAQStatus: STATUS.loading
      };
    case GET_FAQ_RESPONSE:
      return {
        ...state,
        FAQ: action.res,
        getFAQStatus: STATUS.success
      };
    case GET_FAQ_ERROR:
      return {
        ...state,
        getFAQStatus: STATUS.error
      };
    case GET_FAQ_PATH_REQUEST:
      return {
        ...state,
        getFAQPathStatus: STATUS.loading
      };
    case GET_FAQ_PATH_RESPONSE:
      return {
        ...state,
        FAQPath: action.res,
        getFAQPathStatus: STATUS.success
      };
    case GET_FAQ_PATH_ERROR:
      return {
        ...state,
        getFAQPathStatus: STATUS.error
      };

    default:
      return state;
  }
};

export default CommonReducer;
