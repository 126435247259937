import { Autocomplete, TextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import theme from '../../styles/theme';

const TextFieldAutocomplete = ({
  options,
  onChange,
  value,
  labelField,
  valueField,
  label,
  ...props
}) => {
  return (
    <StyledAutocomplete
      {...props}
      disablePortal
      options={options || []}
      ListboxProps={{
        sx: {
          fontSize: 17,
          color: theme.palette.primary.main,
          fontWeight: 400,
          fontFamily: 'Inter, sans-serif',
          backgroundColor: '#ffffff',
          height: 'max-content',
          padding: 0
        }
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'number') {
          let val = options.find((option) => option[valueField] === value);
          if (val) return val[labelField];
        }
        return option[labelField] ?? '';
      }}
      onChange={(e, value) => onChange(value[valueField])}
      value={value}
      renderInput={(params) => (
        <StyledTextField color="lightblue" value={value ?? ''} {...params} placeholder={label} />
      )}
      isOptionEqualToValue={(option, value) =>
        value === undefined || value === '' || option === value
      }
    />
  );
};

export default TextFieldAutocomplete;

export const StyledTextField = muiStyled(TextField)(() => {
  return {
    backgroundColor: 'white',
    borderRadius: 12,
    width: '100%'
  };
});

const StyledAutocomplete = muiStyled(Autocomplete)(() => {
  return {
    borderRadius: 28,
    width: '100%',
    border: 'none',
    display: 'flex',
    flexGrow: 1
  };
});
