import { StyledTitle } from 'components/common/styled-span';
import { StyledStepContainer } from '../../components/styled-overlay';
import { Grid } from '@mui/material';
import DeviceCard from 'components/device-card/device-card';
import { styled as muiStyled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import pricingActions from 'redux/actions/pricing.actions';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import STATUS from '../../../../constants/status';
import LoaderComponent from 'components/loader/loader-component';

const DeviceStep = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.pricing.devices);
  const deviceByName = useSelector((state) => state.pricing.deviceByName);
  const deviceByNameStatus = useSelector((state) => state.pricing.getDeviceByNameStatus);
  const selectedDevice = useSelector((state) => state.pricing.device.type);
  const navigate = useNavigate();
  const { device } = useParams();
  useEffect(() => {
    if (device && deviceByNameStatus === STATUS.idle) {
      dispatch(pricingActions.getDeviceByName.request(device));
    }
  }, [device, deviceByNameStatus]);
  useEffect(() => {
    if (deviceByNameStatus === STATUS.success && !selectedDevice) {
      dispatch(pricingActions.device.update('type', deviceByName));
      setActiveStep(1);
    }
  }, [deviceByName, deviceByNameStatus]);
  useEffect(() => {
    dispatch(pricingActions.getDevices.request());
  }, []);
  const selectDevice = (device) => {
    dispatch(pricingActions.device.update('type', device));
    if (setActiveStep) {
      setActiveStep((prevCount) => prevCount + 1);
    }
    navigate(`/reparar/${device?.url_amigable}`);
  };
  return (
    <StyledStepContainer>
      <StyledTitle>¿Qué necesitas reparar?</StyledTitle>
      <Grid container spacing={2} justifyContent="center">
        {devices?.length ? (
          devices.map((device) => (
            <StyledGridItem key={device?.id} item xs={'auto'} onClick={() => selectDevice(device)}>
              <DeviceCard device={device} />
            </StyledGridItem>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100% !important',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}>
            <LoaderComponent />
          </div>
        )}
      </Grid>
    </StyledStepContainer>
  );
};

export default DeviceStep;

const StyledGridItem = muiStyled(Grid)(() => {
  return {
    cursor: 'pointer'
  };
});
