export const isMobile = () => {
  return window.innerWidth < 900;
};

export const isNullUndefEmptyStr = (object) => {
  return Object.values(object).some((value, key) => {
    if (key == 7 || key == 5) {
      return false;
    }
    return value === null || value === undefined || value === '';
  });
};

export const PageIncludedInList = (currentPage, list) => {
  return list.some((page) => currentPage.includes(page));
};

export const fullImageURL = (url) => {
  // eslint-disable-next-line no-undef
  return `${process.env.REACT_APP_STORAGE_URL}/${url}`;
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const isValidEmail = (value) => {
  let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  return regex.test(value);
};

export const scrollToSectionSmoothly = (targetElementId, duration = 1000, hasNavbar = false) => {
  const targetElement = document.querySelector(targetElementId);
  const targetPosition = targetElement.offsetTop;
  const windowHeight = window.innerHeight;
  let startTime;

  function scroll(timestamp) {
    if (!startTime) {
      startTime = timestamp;
    }

    const timeElapsed = timestamp - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

    let scrollToY =
      targetPosition - (hasNavbar ? windowHeight / 5 - targetElement.clientHeight / 2 : 0);

    window.scrollTo(0, window.scrollY + (scrollToY - window.scrollY) * easeOutCubic(progress));

    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  }

  requestAnimationFrame(scroll);
};

export function formatNumber(number) {
  // Convertir a número y redondear hacia abajo para eliminar decimales
  let integerPart = Math.floor(number);

  // Convertir a cadena y reemplazar la coma por un punto
  integerPart = integerPart?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return integerPart;
}

export function formatNumberInText(text) {
  const formattedText = text.replace(/(\$\d+(?:\.\d+)?)/g, (match) => {
    // Verificar si el número está dentro de <del> o <strong> y aplicar el formato en consecuencia
    if (/<del>/i.test(match)) {
      return match; // Mantener el formato original si está dentro de <del>
    } else if (/<strong>/i.test(match)) {
      const number = parseFloat(match.replace(/\$/g, ''));
      const formattedNumber = formatNumber(number);
      return `<strong>$${formattedNumber}</strong>`;
    } else {
      // Si no está dentro de <del> o <strong>, simplemente aplicar el formato
      const number = parseFloat(match.replace(/\$/g, ''));
      const formattedNumber = formatNumber(number);
      return `$${formattedNumber}`;
    }
  });

  return formattedText;
}
