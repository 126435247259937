import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import theme from '../../styles/theme';

const CustomSelect = ({ options, onChange, label, valueField, labelField, value }) => {
  return (
    <FormControl fullWidth>
      <InputLabel style={{ fontSize: 14, fontWeight: 700, fontFamily: 'Inter, sans-serif' }}>
        {label}
      </InputLabel>
      <StyledSelect
        onChange={onChange}
        label={label}
        value={value}
        id="select"
        sx={{
          '& #select': {
            fontSize: 18,
            color: theme.palette.primary.main,
            fontFamily: 'Inter, sans-serif'
          }
        }}>
        {options?.map((option) => (
          <MenuItem
            key={option.id}
            value={option[valueField]}
            style={{
              fontSize: 14,
              fontWeight: 700,
              color: theme.palette.primary.main,
              fontFamily: 'Inter, sans-serif'
            }}>
            {option[labelField]}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default CustomSelect;

const StyledSelect = muiStyled(Select)(() => {
  return {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    width: '100%',
    maxHeight: '250px'
  };
});
