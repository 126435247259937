import { styled as muiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledButton = muiStyled(Button)(({ theme, style = {}, ...props }) => {
  return [
    {
      color: props.fontcolor,
      borderRadius: 28,
      textTransform: 'none',
      fontWeight: 'bold',
      width: props.fullWidth ? '100%' : 'max-content',
      [theme.breakpoints.down('xs')]: {
        width: props.width ?? '100%'
      },
      fontFamily: 'Inter, sans-serif',
      // minHeight: !props.height && '50px',
      height: props.height,
      display: 'flex',
      gap: props.gap ?? '10px',
      paddingLeft: !props.padding && 20,
      paddingRight: !props.padding && 20,
      padding: props.padding,
      whiteSpace: 'nowrap'
    },
    style
  ];
});

export default StyledButton;
