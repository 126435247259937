import { styled as muiStyled } from '@mui/material/styles';

const StyledSection = muiStyled('section')(({ theme, ...props }) => {
  return {
    height: '100%',
    width: '100%',
    zIndex: props.zIndex,
    margin: props.margin,
    [theme.breakpoints.down('md')]: {
      padding: '15px',
      scrollMarginTop: '130px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '1200px',
      alignSelf: 'center',
      scrollMarginTop: '170px'
    }
  };
});

export default StyledSection;
