import Carousel from 'react-elastic-carousel';

const CustomCarousel = ({ children, xs, sm, md, lg, loop, reff, renderArrow }) => {
  const breakPoints = [
    { width: 1, itemsToShow: xs ?? 1, itemsToScroll: 1 },
    { width: 450, itemsToShow: sm ?? 2, itemsToScroll: 1 },
    { width: 750, itemsToShow: md ?? 3, itemsToScroll: 1 },
    { width: 900, itemsToShow: lg ?? 4, itemsToScroll: 1 }
  ];
  let resetTimeout;
  return (
    <Carousel
      breakPoints={breakPoints}
      ref={reff}
      pagination={false}
      enableAutoPlay={loop}
      autoPlaySpeed={8000}
      isRTL={false}
      onNextEnd={() => {
        if (loop) {
          resetTimeout = setTimeout(() => {
            reff?.current?.goTo(0);
          }, 8000); // same time
          clearTimeout(resetTimeout);
        }
      }}
      renderArrow={renderArrow}>
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
