import ReviewCard from '../review-card/review-card';
import { Grid } from '@mui/material';
import { isMobile } from '../../../utilities/utils';

const ReviewsGrid = ({ reviews }) => (
  <Grid container spacing={3} justifyContent={isMobile() && 'center'}>
    {reviews.map((review) => (
      <Grid
        key={review.id}
        container
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        justifyContent={isMobile() && 'center'}>
        <ReviewCard review={review} />
      </Grid>
    ))}
  </Grid>
);

export default ReviewsGrid;
