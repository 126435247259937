import { MenuItem, Select } from '@mui/material';
import COUNTRIES from '../../../utilities/countries';
import { useNavigate } from 'react-router-dom';

const RegionButton = () => {
  const navigate = useNavigate();

  const handleChange = (e) => {
    localStorage.setItem('region-id', e.target.value);
    localStorage.setItem(
      'region-area-code',
      COUNTRIES.find((country) => country.id === e.target.value).areaCode
    );
    navigate(0);
  };
  const currentRegion = JSON.parse(localStorage.getItem('region-id'));
  return (
    <div>
      <Select value={currentRegion} onChange={handleChange} displayEmpty>
        {COUNTRIES.map((country) => (
          <MenuItem disabled={!country.available} key={country.id} value={country.id}>
            <div style={{ display: 'flex', gap: 5 }}>
              <img alt={'country flag'} src={country.image} height={20} width={30} loading="lazy" />
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default RegionButton;
