import SectionHeader from '../section-header';
import SECTIONS_DATA from './sections-data';
import HowItWorks from '../how-it-works';
import { ButtonContainer, ServiceContainer, ServicePaddingContainer } from '../overlay/styled';
import FaqComponent from '../faq-component';
import StyledButton from 'components/common/styled-button';

const Remote = ({ handleClick, data }) => {
  const DATA = SECTIONS_DATA.remote;
  return (
    <ServiceContainer id="remote">
      <SectionHeader image={DATA.image} description={DATA.description} title={DATA.title} />
      <ServicePaddingContainer>
        <HowItWorks steps={DATA.steps} />
        <ButtonContainer onClick={handleClick}>
          <StyledButton color="secondary" variant="contained">
            Cotizar mi reparación ahora
          </StyledButton>
        </ButtonContainer>
        <FaqComponent FAQ={data || []} />
      </ServicePaddingContainer>
    </ServiceContainer>
  );
};

export default Remote;
