export const MODIFY_DEVICE = 'MODIFY_DEVICE';
export const UPDATE_FLAW = 'UPDATE_FLAW';
export const UPDATE_ANSWERS = 'UPDATE_ANSWERS';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CHOOSE_SERVICE = 'CHOOSE_SERVICE';
export const CHOOSE_SUCURSAL = 'CHOOSE_SUCURSAL';
export const CHOOSE_DATE = 'CHOOSE_DATE';
export const CHOOSE_HOUR = 'CHOOSE_HOUR';

export const GET_PROVINCIAS_REQUEST = 'GET_PROVINCIAS_REQUEST';
export const GET_PROVINCIAS_RESPONSE = 'GET_PROVINCIAS_RESPONSE';
export const GET_PROVINCIAS_ERROR = 'GET_PROVINCIAS_ERROR';

export const GET_BARRIOS_REQUEST = 'GET_BARRIOS_REQUEST';
export const GET_BARRIOS_RESPONSE = 'GET_BARRIOS_RESPONSE';
export const GET_BARRIOS_ERROR = 'GET_BARRIOS_ERROR';

export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
export const GET_DEVICES_RESPONSE = 'GET_DEVICES_RESPONSE';
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR';

export const GET_BRAND_BY_DEVICE_REQUEST = 'GET_BRAND_BY_DEVICE_REQUEST';
export const GET_BRAND_BY_DEVICE_RESPONSE = 'GET_BRAND_BY_DEVICE_RESPONSE';
export const GET_BRAND_BY_DEVICE_ERROR = 'GET_BRAND_BY_DEVICE_ERROR';

export const GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST = 'GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST';
export const GET_MODELS_BY_BRAND_AND_DEVICE_RESPONSE = 'GET_MODELS_BY_BRAND_AND_DEVICE_RESPONSE';
export const GET_MODELS_BY_BRAND_AND_DEVICE_ERROR = 'GET_MODELS_BY_BRAND_AND_DEVICE_ERROR';

export const GET_FLAWS_BY_DEVICE_REQUEST = 'GET_FLAWS_BY_DEVICE_REQUEST';
export const GET_FLAWS_BY_DEVICE_RESPONSE = 'GET_FLAWS_BY_DEVICE_RESPONSE';
export const GET_FLAWS_BY_DEVICE_ERROR = 'GET_FLAWS_BY_DEVICE_ERROR';

export const GET_COLORS_BY_MODEL_REQUEST = 'GET_COLORS_BY_MODEL_REQUEST';
export const GET_COLORS_BY_MODEL_RESPONSE = 'GET_COLORS_BY_MODEL_RESPONSE';
export const GET_COLORS_BY_MODEL_ERROR = 'GET_COLORS_BY_MODEL_ERROR';

export const GET_DEVICE_BY_NAME_REQUEST = 'GET_DEVICE_BY_NAME_REQUEST';
export const GET_DEVICE_BY_NAME_RESPONSE = 'GET_DEVICE_BY_NAME_RESPONSE';
export const GET_DEVICE_BY_NAME_ERROR = 'GET_DEVICE_BY_NAME_ERROR';

export const GET_BRAND_BY_NAME_REQUEST = 'GET_BRAND_BY_NAME_REQUEST';
export const GET_BRAND_BY_NAME_RESPONSE = 'GET_BRAND_BY_NAME_RESPONSE';
export const GET_BRAND_BY_NAME_ERROR = 'GET_BRAND_BY_NAME_ERROR';

export const GET_QUESTIONS_BY_FLAWS_REQUEST = 'GET_QUESTIONS_BY_FLAWS_REQUEST';
export const GET_QUESTIONS_BY_FLAWS_RESPONSE = 'GET_QUESTIONS_BY_FLAWS_RESPONSE';
export const GET_QUESTIONS_BY_FLAWS_ERROR = 'GET_QUESTIONS_BY_FLAWS_ERROR';

export const GET_GEOLOCATION_REQUEST = 'GET_GEOLOCATION_REQUEST';
export const GET_GEOLOCATION_RESPONSE = 'GET_GEOLOCATION_RESPONSE';
export const GET_GEOLOCATION_ERROR = 'GET_GEOLOCATION_ERROR';

export const POST_CLIENT_REQUEST = 'POST_CLIENT_REQUEST';
export const POST_CLIENT_RESPONSE = 'POST_CLIENT_RESPONSE';
export const POST_CLIENT_ERROR = 'POST_CLIENT_ERROR';

export const GET_SOLUCION_REQUEST = 'GET_SOLUCION_REQUEST';
export const GET_SOLUCION_RESPONSE = 'GET_SOLUCION_RESPONSE';
export const GET_SOLUCION_ERROR = 'GET_SOLUCION_ERROR';

export const GET_SERVICIOS_DISPONIBLES_REQUEST = 'GET_SERVICIOS_DISPONIBLES_REQUEST';
export const GET_SERVICIOS_DISPONIBLES_RESPONSE = 'GET_SERVICIOS_DISPONIBLES_RESPONSE';
export const GET_SERVICIOS_DISPONIBLES_ERROR = 'GET_SERVICIOS_DISPONIBLES_ERROR';

export const GET_SUCURSALES_YO_LO_LLEVO_REQUEST = 'GET_SUCURSALES_YO_LO_LLEVO_REQUEST';
export const GET_SUCURSALES_YO_LO_LLEVO_RESPONSE = 'GET_SUCURSALES_YO_LO_LLEVO_RESPONSE';
export const GET_SUCURSALES_YO_LO_LLEVO_ERROR = 'GET_SUCURSALES_YO_LO_LLEVO_ERROR';

export const POST_UPDATE_DERIVACION_REQUEST = 'POST_UPDATE_DERIVACION_REQUEST';
export const POST_UPDATE_DERIVACION_RESPONSE = 'POST_UPDATE_DERIVACION_RESPONSE';
export const POST_UPDATE_DERIVACION_ERROR = 'POST_UPDATE_DERIVACION_ERROR';

export const POST_END_DERIVACION_REQUEST = 'POST_END_DERIVACION_REQUEST';
export const POST_END_DERIVACION_RESPONSE = 'POST_END_DERIVACION_RESPONSE';
export const POST_END_DERIVACION_ERROR = 'POST_END_DERIVACION_ERROR';

export const CHOOSE_SOLUCION = 'CHOOSE_SOLUCION';

export const GET_DISCOUNT_REQUEST = 'GET_DISCOUNT_REQUEST';
export const GET_DISCOUNT_RESPONSE = 'GET_DISCOUNT_RESPONSE';
export const GET_DISCOUNT_ERROR = 'GET_DISCOUNT_ERROR';

export const RESET_STATE = 'RESET_STATE';

const pricingActions = {
  device: {
    update: (key, data) => ({ type: MODIFY_DEVICE, key, data }),
    updateFlaw: (key) => ({ type: UPDATE_FLAW, key }),
    updateAnswers: (answer) => ({ type: UPDATE_ANSWERS, answer })
  },
  client: {
    update: (data) => ({ type: UPDATE_CLIENT, data })
  },
  service: {
    chooseService: (service) => ({ type: CHOOSE_SERVICE, service }),
    chooseSucursal: (sucursal) => ({ type: CHOOSE_SUCURSAL, sucursal }),
    chooseDate: (date) => ({ type: CHOOSE_DATE, date }),
    chooseHour: (hour) => ({ type: CHOOSE_HOUR, hour })
  },
  solucion: {
    chooseSolucion: (solucion) => ({ type: CHOOSE_SOLUCION, solucion })
  },
  getProvincias: {
    request: () => ({ type: GET_PROVINCIAS_REQUEST }),
    response: (res) => ({ type: GET_PROVINCIAS_RESPONSE, res }),
    error: (err) => ({ type: GET_PROVINCIAS_ERROR, err })
  },
  getBarrios: {
    request: (id_provincia) => ({ type: GET_BARRIOS_REQUEST, id_provincia }),
    response: (res) => ({ type: GET_BARRIOS_RESPONSE, res }),
    error: (err) => ({ type: GET_BARRIOS_ERROR, err })
  },
  getDevices: {
    request: () => ({ type: GET_DEVICES_REQUEST }),
    response: (res) => ({ type: GET_DEVICES_RESPONSE, res }),
    error: (err) => ({ type: GET_DEVICES_ERROR, err })
  },
  getBrandsByDevice: {
    request: (id_device) => ({ type: GET_BRAND_BY_DEVICE_REQUEST, id_device }),
    response: (res) => ({ type: GET_BRAND_BY_DEVICE_RESPONSE, res }),
    error: (err) => ({ type: GET_BRAND_BY_DEVICE_ERROR, err })
  },
  getModelsByBrandAndDevice: {
    request: (id_brand, id_device) => ({
      type: GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST,
      id_brand,
      id_device
    }),
    response: (res) => ({ type: GET_MODELS_BY_BRAND_AND_DEVICE_RESPONSE, res }),
    error: (err) => ({ type: GET_MODELS_BY_BRAND_AND_DEVICE_ERROR, err })
  },
  getFlawsByDevice: {
    request: (id_device) => ({ type: GET_FLAWS_BY_DEVICE_REQUEST, id_device }),
    response: (res) => ({ type: GET_FLAWS_BY_DEVICE_RESPONSE, res }),
    error: (err) => ({ type: GET_FLAWS_BY_DEVICE_ERROR, err })
  },
  getQuestionsByFlaws: {
    request: (flaws) => ({ type: GET_QUESTIONS_BY_FLAWS_REQUEST, flaws }),
    response: (res) => ({ type: GET_QUESTIONS_BY_FLAWS_RESPONSE, res }),
    error: (err) => ({ type: GET_QUESTIONS_BY_FLAWS_ERROR, err })
  },
  getColors: {
    request: (id_model) => ({ type: GET_COLORS_BY_MODEL_REQUEST, id_model }),
    response: (res) => ({ type: GET_COLORS_BY_MODEL_RESPONSE, res }),
    error: (err) => ({ type: GET_COLORS_BY_MODEL_ERROR, err })
  },
  getDeviceByName: {
    request: (device_name) => ({ type: GET_DEVICE_BY_NAME_REQUEST, device_name }),
    response: (res) => ({ type: GET_DEVICE_BY_NAME_RESPONSE, res }),
    error: (err) => ({ type: GET_DEVICE_BY_NAME_ERROR, err })
  },
  getBrandByName: {
    request: (brand_name) => ({ type: GET_BRAND_BY_NAME_REQUEST, brand_name }),
    response: (res) => ({ type: GET_BRAND_BY_NAME_RESPONSE, res }),
    error: (err) => ({ type: GET_BRAND_BY_NAME_ERROR, err })
  },
  getGeolocation: {
    request: (altitude, longitude) => ({ type: GET_GEOLOCATION_REQUEST, altitude, longitude }),
    response: (res) => ({ type: GET_GEOLOCATION_RESPONSE, res }),
    error: (err) => ({ type: GET_GEOLOCATION_ERROR, err })
  },
  postClientData: {
    request: (data) => ({ type: POST_CLIENT_REQUEST, data }),
    response: (res) => ({ type: POST_CLIENT_RESPONSE, res }),
    error: (err) => ({ type: POST_CLIENT_ERROR, err })
  },
  getSolucion: {
    request: (id) => ({ type: GET_SOLUCION_REQUEST, id }),
    response: (res) => ({ type: GET_SOLUCION_RESPONSE, res }),
    error: (err) => ({ type: GET_SOLUCION_ERROR, err })
  },
  getServiciosDisponibles: {
    request: (id) => ({ type: GET_SERVICIOS_DISPONIBLES_REQUEST, id }),
    response: (res) => ({ type: GET_SERVICIOS_DISPONIBLES_RESPONSE, res }),
    error: (err) => ({ type: GET_SERVICIOS_DISPONIBLES_ERROR, err })
  },
  getSucursalesYoLoLlevo: {
    request: (id) => ({ type: GET_SUCURSALES_YO_LO_LLEVO_REQUEST, id }),
    response: (res) => ({ type: GET_SUCURSALES_YO_LO_LLEVO_RESPONSE, res }),
    error: (err) => ({ type: GET_SUCURSALES_YO_LO_LLEVO_ERROR, err })
  },
  postUpdateDerivacion: {
    request: (data) => ({ type: POST_UPDATE_DERIVACION_REQUEST, data }),
    response: (res) => ({ type: POST_UPDATE_DERIVACION_RESPONSE, res }),
    error: (err) => ({ type: POST_UPDATE_DERIVACION_ERROR, err })
  },
  postEndDerivacion: {
    request: (data) => ({ type: POST_END_DERIVACION_REQUEST, data }),
    response: (res) => ({ type: POST_END_DERIVACION_RESPONSE, res }),
    error: (err) => ({ type: POST_END_DERIVACION_ERROR, err })
  },
  getDiscount: {
    request: (code) => ({ type: GET_DISCOUNT_REQUEST, code }),
    response: (res) => ({ type: GET_DISCOUNT_RESPONSE, res }),
    error: (err) => ({ type: GET_DISCOUNT_ERROR, err })
  }
};

export default pricingActions;
