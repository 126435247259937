import { styled as muiStyled } from '@mui/material/styles';
import mapMarker from 'assets/map-marker.svg';
import { StyledSpan } from 'components/common/styled-span';

const AddressCard = ({ address }) => {
  const { nombre, direccion_aproximada } = address;
  return (
    <ComponentContainer>
      <img src={mapMarker} height={18} width={12} alt="map marker" loading="lazy" />
      <TextContainer>
        <StyledSpan fontWeight="bold" fontSize={18}>
          {' '}
          {nombre}{' '}
        </StyledSpan>
        <StyledSpan fontWeight={400} fontSize={12}>
          {direccion_aproximada}
        </StyledSpan>
      </TextContainer>
    </ComponentContainer>
  );
};

export default AddressCard;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  };
});

const TextContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    gap: '5px',
    flexDirection: 'column'
  };
});
