import { styled as muiStyled } from '@mui/material/styles';
import confirmacionBanner from 'assets/banners/confirmationBanner.png';
import { PageContainer } from '../../components/common/styled-page-container';
import TextContainer from '../../components/common/styled-text-section';
import { StyledDescription, StyledSpan, StyledTitle } from '../../components/common/styled-span';
import StyledSection from '../../components/common/styled-section';
import { Button, Typography } from '@mui/material';
import ServicePass from '../../components/service-pass/service-pass';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import qr from './MOCKED/QR.png';
import LoaderComponent from 'components/loader/loader-component';
import { RESET_STATE } from 'redux/actions/pricing.actions';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const ConfirmacionPage = () => {
  const [showPass, setShowPass] = useState(false);
  const data = useSelector((state) => state.pricing.endDerivacion?.data);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const SERVICE_PASS = {
    QR: qr,
    name: `${data?.nombre} ${data?.apellido}`,
    serviceCenter: data?.serviceCenter,
    appointment: data?.fecha_hora,
    clientNumber: data?.cliente_derivacion_id,
    applicationNumber: data?.derivacion_id
  };
  const withoutContract =
    data?.estado_nombre == 'A Cotizar'
      ? {
          subtitle:
            'Nuestros expertos buscarán el mejor precio para reparar tu equipo y te contactarán en las próximas horas.',
          roundOneTitle: 'Te enviamos la cotización',
          roundTwoTitle: `Coordinamos el servicio`
        }
      : {
          subtitle:
            'Te contactará un especialista para poder asesorarte y que puedas elegir la mejor opción para volver a tener tu equipo como nuevo.',
          roundOneTitle: 'Te contactamos a la brevedad por mail o teléfono',
          roundTwoTitle: 'Coordinamos el servicio'
        };
  const options = {
    ['2']: {
      subtitle:
        'Registramos con éxito tu solicitud.<br>Nuestro equipo coordinará el retiro del equipo.',
      roundTwoTitle: 'Embalá y etiquetá tu equipo',
      roundTwoSubtitle: `Embalá de la mejor forma que puedas tu equipo e identificalo con el número de solicitud ${data?.derivacion_id}`,
      roundThreeSubtitle:
        'Te confirmaremos el retiro de tu equipo y posteriormente lo recibirás nuevamente reparado como nuevo.'
    },
    ['4']: {
      subtitle:
        'Registramos con éxito tu solicitud.<br>¡Te esperamos para dejar tu equipo como nuevo!',
      roundTwoTitle: 'Acercate a la sucursal',
      roundTwoSubtitle: `Recordá mostrar tu FixUP! pass o mencionar tu número de solicitud ${data?.derivacion_id} para una atención exclusiva.`,
      roundThreeSubtitle: 'Vas a recibir en tiempo real el estado de tu reparación.'
    },
    ['7']: {
      subtitle:
        'Registramos con éxito tu solicitud.<br>Nuestro equipo coordinará el retiro del equipo.',
      roundTwoTitle: 'Embalá y etiquetá tu equipo',
      roundTwoSubtitle: `Embalá de la mejor forma que puedas tu equipo e identificalo con el número de solicitud ${data?.derivacion_id}`,
      roundThreeSubtitle:
        'Te confirmaremos el retiro de tu equipo y posteriormente lo recibirás nuevamente reparado como nuevo.'
    },
    ['71']: {
      subtitle:
        'Registramos con éxito tu solicitud.<br>Nuestro equipo coordinará el retiro del equipo.',
      roundTwoTitle: 'Embalá y etiquetá tu equipo',
      roundTwoSubtitle: `Embalá de la mejor forma que puedas tu equipo e identificalo con el número de solicitud ${data?.derivacion_id}`,
      roundThreeSubtitle:
        'Te confirmaremos el retiro de tu equipo y posteriormente lo recibirás nuevamente reparado como nuevo.'
    }
  };

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      dispatch({ type: RESET_STATE });
    }
  }, [data]);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     if (isLoading && !data) {
  //       navigate('/error');
  //     }
  //   }, 10000);

  //   // Cleanup function to clear the timeout if the component unmounts or data is loaded
  //   return () => clearTimeout(timeoutId);
  // }, [isLoading, data, navigate]);

  return (
    <PageContainer>
      {!isLoading ? (
        <>
          <BackgroundGradient>
            <BackgroundImage src={confirmacionBanner} />
          </BackgroundGradient>
          <StyledSection zIndex={15}>
            <TextContainer>
              <StyledTitle color="white">
                ¡Gracias,{' '}
                {data?.nombre
                  ? `${data?.nombre?.charAt(0).toUpperCase()}${data?.nombre?.slice(1)}`
                  : ''}
                !
              </StyledTitle>
              <StyledDescription color="white" style={{ maxWidth: 375, lineHeight: '1.4rem' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: options[data?.servicio_id]?.subtitle || withoutContract.subtitle
                  }}
                />
              </StyledDescription>
            </TextContainer>
            <InfoBoxContainer>
              <InfoBox>
                <StyledTitle>¿Qué sigue?</StyledTitle>
                <Step>
                  <RoundedNumber>1</RoundedNumber>
                  <TextStep>
                    {data?.contrato_id ||
                    data?.servicio_id === 7 ||
                    data?.servicio_id === 4 ||
                    data?.servicio_id === 71 ||
                    data?.servicio_id === 2 ? (
                      <>
                        <StyledSpan fontWeight={700} fontSize={18}>
                          Revisá tu email
                        </StyledSpan>
                        <StyledSpan fontWeight={400} fontSize={14}>
                          Te enviamos toda esta información detallada. No olvides ver en SPAM.
                        </StyledSpan>
                      </>
                    ) : (
                      <StyledSpan fontWeight={700} fontSize={18}>
                        {withoutContract.roundOneTitle}
                      </StyledSpan>
                    )}
                  </TextStep>
                </Step>
                <Step>
                  <RoundedNumber>2</RoundedNumber>
                  <TextStep>
                    {data?.contrato_id ||
                    data?.servicio_id === 71 ||
                    data?.servicio_id === 7 ||
                    data?.servicio_id === 4 ||
                    data?.servicio_id === 2 ? (
                      <>
                        <StyledSpan fontWeight={700} fontSize={18}>
                          {options[data?.servicio_id]?.roundTwoTitle || ''}
                        </StyledSpan>
                        <StyledSpan fontWeight={400} fontSize={14}>
                          {options[data?.servicio_id]?.roundTwoSubtitle || ''}
                        </StyledSpan>
                        {(data?.servicio_id === 7 ||
                          data?.servicio_id === 2 ||
                          data?.servicio_id === 71) && (
                          <StyledSpan fontWeight={400} fontSize={14}>
                            <a
                              href="https://blog.fixupweb.com/sugerencias-para-embalar-tu-equipo/"
                              target="blank">
                              (Mirá acá las sugerencias para embalarlo)
                            </a>
                          </StyledSpan>
                        )}
                      </>
                    ) : (
                      <StyledSpan fontWeight={700} fontSize={18}>
                        {withoutContract.roundTwoTitle}
                      </StyledSpan>
                    )}
                  </TextStep>
                </Step>
                <Step>
                  <RoundedNumber>3</RoundedNumber>
                  <TextStep>
                    <StyledSpan fontWeight={700} fontSize={18}>
                      Volvé a tener tu equipo, como nuevo
                    </StyledSpan>
                    {data && (
                      <StyledSpan fontWeight={400} fontSize={14}>
                        {options[data?.servicio_id]?.roundThreeSubtitle || ''}
                      </StyledSpan>
                    )}
                  </TextStep>
                </Step>
                <CenteredDiv>
                  <StyledSpan fontWeight={400} fontSize={14}>
                    Número de solicitud: {data?.derivacion_id} | Número de cliente:{' '}
                    {data?.cliente_derivacion_id}
                  </StyledSpan>
                </CenteredDiv>
              </InfoBox>
              {data?.servicio_id === 4 && (
                <CenteredDiv>
                  <FixUpPassButton variant="contained" onClick={() => setShowPass(true)}>
                    VER MI FIXUP! PASS
                  </FixUpPassButton>
                </CenteredDiv>
              )}
            </InfoBoxContainer>
            <ServicePass
              data={SERVICE_PASS}
              open={showPass}
              handleClose={() => setShowPass(false)}
            />
          </StyledSection>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100% !important',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
            flex: 1,
            marginTop: '20vh'
          }}>
          <LoaderComponent />
          <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>
            Creando tu solicitud
          </Typography>
        </div>
      )}
    </PageContainer>
  );
};

export default ConfirmacionPage;

const BackgroundGradient = muiStyled('div')(() => {
  return {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100vh',
    backgroundImage: `linear-gradient(180deg, rgba(67,120,226,1) 0%, rgba(109,142,217,1) 46%, rgba(156,166,205,1) 100%)`
  };
});

const BackgroundImage = muiStyled('img')(({ theme }) => {
  return {
    maxWidth: '50vw',
    position: 'absolute',
    right: '50px',
    top: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      maxWidth: '100vw',
      top: '350px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      // Estilo específico para el rango de 740px a 900px
      top: '220px'
    }
  };
});

const CenteredDiv = muiStyled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

const InfoBoxContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    [theme.breakpoints.down('md')]: {
      maxWidth: '90vw'
    }
  };
});

const InfoBox = muiStyled('div')(() => {
  return {
    borderRadius: 10,
    backgroundColor: 'rgba(243, 244, 248, 0.6)',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '20px',
    maxWidth: 'max-content'
  };
});

const TextStep = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  };
});

const RoundedNumber = muiStyled('div')(({ theme }) => {
  return {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 50,
    minWidth: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: 700
  };
});

const Step = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px'
  };
});

const FixUpPassButton = muiStyled(Button)(({ theme }) => {
  return {
    borderRadius: 50,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    marginTop: '40px',
    width: 'max-content',
    '&:hover': {
      color: 'white'
    }
  };
});
