import packageImage from 'assets/services/Package.png';
import mapImage from 'assets/services/Maps.png';
import googlemapsimage from './googlemapsimage.png';
import joystick from 'assets/devices/joystick.png';

export const SERVICE_OPTIONS_RECOMMENDED = [
  {
    id: 1,
    name: 'Repuesto original',
    recommended: true,
    price: '12.000',
    discount: 20,
    image: joystick,
    cuotas: 'O 6 cuotas de $2.000',
    estimatedPrice: true,
    info: ['Certificado por Sony', 'Con 100 días de garantía', '100% recomendado'],
    description: {
      title: 'Que significa esto?',
      text: 'Esto quiere decir que el disco duro se fundió y necesita que lo reemplacemos por otro. La información de tu disco duro la vamos a respaldar y agregarla en el nuevo.'
    }
  }
];

export const SERVICES = [
  {
    title: 'Lo retiramos en tu casa',
    image: packageImage,
    additionalInfo: {
      title: 'Retiro gratis en tu zona',
      subtitle: 'Sucursales que tenés cerca',
      text: [
        {
          title: 'Belgrano',
          subtitle: 'Centro Fixup! en Juramento y Cabildo'
        },
        {
          title: 'Núñez',
          subtitle: 'Centro Fixup! en Cabildo y Congreso'
        },
        {
          title: 'Palermo',
          subtitle: 'Centro Fixup! en Santa Fe y Vidt'
        }
      ]
    },
    id: 1
  },
  {
    name: 'ITakeIt',
    title: 'Vos lo traes',
    image: mapImage,
    additionalInfo: {
      title: 'Retiro gratis en tu zona',
      subtitle: 'Sucursales que tenés cerca',
      text: [
        {
          title: 'Belgrano',
          subtitle: 'Centro Fixup! en Juramento y Cabildo'
        },
        {
          title: 'Núñez',
          subtitle: 'Centro Fixup! en Cabildo y Congreso'
        },
        {
          title: 'Palermo',
          subtitle: 'Centro Fixup! en Santa Fe y Vidt'
        }
      ]
    },
    id: 2
  },
  {
    name: 'Remote',
    title: 'Remoto',
    description: '¡Acercate a un punto FixUP!',
    image: mapImage,
    additionalInfo: {
      title: 'Retiro gratis en tu zona',
      subtitle: 'Sucursales que tenés cerca',
      text: [
        {
          title: 'Belgrano',
          subtitle: 'Centro Fixup! en Juramento y Cabildo'
        },
        {
          title: 'Núñez',
          subtitle: 'Centro Fixup! en Cabildo y Congreso'
        },
        {
          title: 'Palermo',
          subtitle: 'Centro Fixup! en Santa Fe y Vidt'
        }
      ]
    },
    id: 3
  }
];

export const SUCURSALS = [
  {
    name: 'Plaza Italia',
    description: 'A pocos metros del Jardín Botánico',
    additionalInfo: 'Abierto ahora / 24Hrs',
    image: googlemapsimage,
    type: 'Centro Autorizado',
    id: 1
  },
  {
    name: 'Distrito Arcos',
    description: 'Cerca de Pacífico',
    additionalInfo: 'Reparación Express',
    image: googlemapsimage,
    type: 'Centro Autorizado',
    id: 2
  },
  {
    name: 'Distrito Arcos',
    description: 'Cerca de Pacífico',
    additionalInfo: 'Reparación Express',
    image: googlemapsimage,
    type: 'FixUP! Point',
    id: 3
  },
  {
    name: 'Distrito Arcos',
    description: 'Cerca de Pacífico',
    additionalInfo: 'Reparación Express',
    image: googlemapsimage,
    type: 'Sucursal Inteligente',
    id: 4
  }
];

export const SUCURSAL_DATES = [
  {
    id: 1,
    title: 'Lunes 11/07',
    hoursAvailable: [
      {
        id: 1,
        title: '8hrs - 12hrs'
      },
      { id: 2, title: '13hrs - 16hrs' },
      { id: 3, title: '17hrs - 19hrs' }
    ]
  },
  {
    id: 2,
    title: 'Martes 12/07',
    hoursAvailable: [
      { id: 1, title: '9hrs - 10hrs' },
      { id: 2, title: '13hrs - 14hrs' },
      { id: 3, title: '15hrs - 17hrs' }
    ]
  },
  {
    id: 3,
    title: 'Miercoles 13/07',
    hoursAvailable: [
      {
        id: 1,
        title: '10hrs - 12hrs'
      },
      { id: 2, title: '13hrs - 16hrs' },
      { id: 3, title: '18hrs - 21hrs' }
    ]
  }
];
