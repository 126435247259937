import StyledSection from '../common/styled-section';
import { Grid, Stack } from '@mui/material';
import dude from 'assets/banners/dude.png';
import { StyledSpan, StyledTitle } from '../common/styled-span';
import { styled as muiStyled } from '@mui/material/styles';
import mail from '../../assets/mail.svg';
import status from '../../assets/status.svg';
import './support-banner.scss';

const SupportBanner = () => {
  const regionSelected = JSON.parse(localStorage.getItem('region-id'));
  const state = {
    ['1']: 'https://estado.fixupweb.com/estado',
    ['9']: 'https://estado.cl.fixupweb.com/',
    ['8']: 'https://estado.uy.fixupweb.com/'
  };
  return (
    <StyledSection>
      <Grid container className="contact-section-container">
        <Grid container item xs={12} sm={12} md={6} lg={6} justifyContent="center">
          <div className="image-contact-container">
            <img src={dude} alt="dude" className="image-contact" loading="lazy" />
          </div>
        </Grid>
        <Grid container item xs={12} sm={12} md={6} lg={6} justifyContent="center">
          <BannerTextContainer>
            <StyledTitle>¿No conseguiste una respuesta? Hablemos</StyledTitle>

            <Stack flexDirection={'row'} alignItems={'center'} gap={'1rem'}>
              <img src={status} alt="Status logo" width={26} loading="lazy" />
              <StyledSpan fontSize={14} fontWeight={400} color={'#11216C'}>
                Revisa el estado de tu reparación haciendo{' '}
                <a className="withoutTextDecoration" href={state[regionSelected]} target="blank">
                  <span className="span">clic acá.</span>
                </a>
              </StyledSpan>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'} gap={'1rem'}>
              <img src={mail} alt="Mail logo" width={24} loading="lazy" />
              <StyledSpan fontSize={14} fontWeight={400} color={'#11216C'}>
                Envianos un mail a{' '}
                <a className="withoutTextDecoration" href="mailto:contacto@fixupweb.com">
                  <span className="span">hola@fixupweb.com</span>
                </a>
              </StyledSpan>
            </Stack>
          </BannerTextContainer>
        </Grid>
      </Grid>
    </StyledSection>
  );
};

export default SupportBanner;

const BannerTextContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '30px'
  };
});
