import StyledSection from 'components/common/styled-section';
import { styled as muiStyled } from '@mui/material/styles';
import transform from 'assets/banners/transform.png';
import TextContainer from '../../../../components/common/styled-text-section';
import { StyledDescription, StyledTitle } from '../../../../components/common/styled-span';

const TransformBanner = () => {
  return (
    <StyledSection>
      <ComponentContainer>
        <ImageContainer>
          <Image src={transform} alt="transform" />
        </ImageContainer>
        <TextContainer>
          <StyledTitle>
            Transformar la experiencia de reparar cualquier dispositivo tecnológico.
          </StyledTitle>
          <StyledDescription>
            La tecnología avanza a pasos agigantados y es cada vez más importante para nuestra vida
            diara. ¿Pero qué sucede cuando falla o se daña? En FixUP! nos propusimos brindar la
            mejor experiencia posible en cada punto del proceso: desde la cotización hasta la
            devolución del equipo reparado como nuevo.
          </StyledDescription>
        </TextContainer>
      </ComponentContainer>
    </StyledSection>
  );
};

export default TransformBanner;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      padding: '32px 32px 0 32px'
    }
  };
});

const Image = muiStyled('img')(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  };
});

const ImageContainer = muiStyled('div')(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      height: '400px',
      position: 'relative',
      top: '-110px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px',
      margin: 'auto'
    }
  };
});
