import { Button, Grid } from '@mui/material';
import { StyledDescription, StyledSpan, StyledTitle } from 'components/common/styled-span';
import './fix-up-in-media-section.scss';
import StyledSection from 'components/common/styled-section';
import TextContainer from 'components/common/styled-text-section';
import MediaCard from '../media-card/media-card';
import { openInNewTab } from '../../../utilities/utils';

const FixUpInMediaSection = ({ media }) => {
  return (
    <StyledSection>
      <div className="section-container">
        <TextContainer>
          <div style={{ display: 'flex', gap: '5px' }}>
            <StyledTitle>Menciones en los medios</StyledTitle>
          </div>
          <div className="section-subheader">
            <StyledDescription>
              Mirá qué dicen algunos de los principales medios sobre FixUP!
            </StyledDescription>
          </div>
        </TextContainer>
        {media && (
          <Grid container spacing={3} justifyContent={'center'}>
            {media.map((media) => (
              <Grid key={media.id} item xs="auto">
                <MediaCard media={media} />
              </Grid>
            ))}
          </Grid>
        )}
        <div className="button-container">
          <Button
            variant="outlined"
            sx={{ borderRadius: '20px', textTransform: 'none' }}
            onClick={() => openInNewTab('https://blog.fixupweb.com/category/prensa/ ')}>
            <StyledSpan fontWeight="bold" fontSize={12}>
              Ver más notas
            </StyledSpan>
          </Button>
        </div>
      </div>
    </StyledSection>
  );
};

export default FixUpInMediaSection;
