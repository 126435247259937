export const REPAIR_ITEMS = [
  {
    id: 1,
    title: 'SMARTPHONE',
    path: '/reparar'
  },
  {
    id: 2,
    title: 'TABLET',
    path: '/reparar'
  },
  { id: 3, title: 'COMPUTADORA', path: '/reparar' },
  { id: 4, title: 'IMPRESORA', path: '/reparar' },
  { id: 5, title: 'CONSOLA', path: '/reparar' },
  { id: 6, title: 'SMART TV', path: '/reparar' },
  { id: 7, title: 'SMARTWATCH', path: '/reparar' }
];
