import { SearchSection, StyledStepContainer } from '../../../../components/styled-overlay';
import { StyledDescription, StyledTitle } from 'components/common/styled-span';
import { Button, Grid, Stack } from '@mui/material';
import SucursalCard from 'components/sucursal-card/sucursal-card';
import pricingActions from '../../../../../../redux/actions/pricing.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import LoaderComponent from 'components/loader/loader-component';

const ChooseSucursal = ({ setActiveServiceStep }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const selectSucursal = (sucursal) => {
    dispatch(pricingActions.service.chooseSucursal(sucursal));
    setActiveServiceStep((prevState) => prevState + 1);
  };
  const derivacionId = useSelector((state) => state.pricing.derivacion_id);
  const sucursales = useSelector((state) => state.pricing.sucursalesYoLoLlevo);
  const [showAll, setShowAll] = useState(sucursales?.length > 3 ? false : true);
  const displayedSucursales = showAll ? sucursales : sucursales.slice(0, 6);
  useEffect(() => {
    dispatch(pricingActions.getSucursalesYoLoLlevo.request(derivacionId));
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  if (!sucursales) return <LoaderComponent />;
  return (
    <StyledStepContainer>
      <SearchSection>
        <StyledTitle>¿A qué sucursal preferis llevarlo?</StyledTitle>
        <StyledDescription>
          Al finalizar, verás la dirección exacta del lugar elegido.
        </StyledDescription>
      </SearchSection>
      {!isLoading ? (
        <>
          <Grid container spacing={2}>
            {displayedSucursales.map((sucursal) => (
              <Grid
                key={sucursal.id}
                item
                xs={12}
                sm={6}
                md={4}
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={() => selectSucursal(sucursal)}>
                <SucursalCard sucursal={sucursal} />
              </Grid>
            ))}
          </Grid>
          {!showAll && (
            <Stack alignItems={'center'}>
              <Button
                variant="outlined"
                sx={{
                  width: '180px',
                  textTransform: 'none',
                  borderRadius: '16px'
                }}
                onClick={() => setShowAll(true)}>
                Ver más sucursales
              </Button>
            </Stack>
          )}
        </>
      ) : (
        <LoaderComponent />
      )}
    </StyledStepContainer>
  );
};

export default ChooseSucursal;
