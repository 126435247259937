import React, { useState, useEffect } from 'react';
import './SelectSearch.scss'; // Importa tu archivo CSS de estilos
import chile from 'assets/countries/icons/flag-chile.svg';
import argentina from 'assets/countries/icons/flag-argentina.svg';
import mexico from 'assets/countries/icons/flag-mexico.svg';
import colombia from 'assets/countries/icons/flag-colombia.svg';
import uruguay from 'assets/countries/icons/flag-uruguay.svg';
import theme from 'styles/theme';
import CustomInputMask from 'components/input-mask/CustomInputMask';

export default function SelectSearch({
  validPhoneNumber,
  setValidPhoneNumber,
  clientData,
  setClientData
}) {
  const [country, setCountry] = useState({});

  const countries = [
    {
      name: 'Argentina',
      min: 'ar',
      areaCode: '+549',
      mask: '000 000-0000',
      flag: argentina,
      locationCode: 1
    },
    {
      name: 'Chile',
      min: 'cl',
      areaCode: '+56',
      mask: '0 0000-0000',
      flag: chile,
      locationCode: 9
    },
    {
      name: 'Colombia',
      min: 'co',
      areaCode: '+57',
      mask: '000 000-0000',
      flag: colombia
    },
    {
      name: 'México',
      min: 'mx',
      areaCode: '+521',
      mask: '00 0000-0000',
      flag: mexico
    },
    {
      name: 'Uruguay',
      min: 'uy',
      areaCode: '+598',
      mask: '00 000-000',
      flag: uruguay,
      locationCode: 8
    }
  ];
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [inputValue, setInputValue] = useState();
  const isValidPhoneNumber = (value) => {
    if (country.mask.match(/\d/g).length === value.match(/\d/g).length || value?.length === 0) {
      setValidPhoneNumber(true);
      setClientData({ ...clientData, numeroTelefono: value });
    } else {
      setValidPhoneNumber(false);
    }
  };
  const inputClassName = `valid-phone-input ${!validPhoneNumber ? 'invalid-phone-input' : ''}`;

  const handleOptionClick = (option) => {
    const found = countries.find((country) => country.name === option.name);
    setCountry(found);
    setIsOptionsVisible(false);
    setClientData({ ...clientData, codigoPais: country.areaCode });
  };

  useEffect(() => {
    const currentRegion = JSON.parse(localStorage.getItem('region-id'));
    const found = countries.find((country) => country.locationCode === currentRegion);
    if (found) {
      setCountry(found);
      setClientData({ ...clientData, codigoPais: found.areaCode });
    } else {
      setCountry(countries[0]);
    }
  }, []);

  return (
    <div
      className="custom-select-container"
      style={{
        fontSize: 14,
        color: theme.palette.primary.main,
        fontFamily: 'Inter, sans-serif'
      }}>
      <div
        className={`custom-select ${isOptionsVisible ? 'open' : ''}`}
        onClick={() => setIsOptionsVisible(!isOptionsVisible)}>
        <img
          src={country.flag}
          style={{ width: '20px', height: '20px', marginRight: '1rem' }}
          loading="lazy"
        />
        {`${country.name} ${country.areaCode}`}
      </div>
      {isOptionsVisible && (
        <div className="custom-options">
          {countries.map((option) => (
            <div
              key={option.min}
              className="custom-option"
              onClick={() => handleOptionClick(option)}>
              <img
                src={option?.flag}
                style={{ width: '20px', height: '20px', marginRight: '1rem' }}
                loading="lazy"
              />
              {`${option.name} ${option.areaCode}`}
            </div>
          ))}
        </div>
      )}
      <CustomInputMask
        mask={country.mask}
        value={inputValue}
        onAccept={(value) => {
          setInputValue(value);
          isValidPhoneNumber(value);
        }}
        placeholder={country.mask}
        className={inputClassName}
      />
    </div>
  );
}
