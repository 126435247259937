import { Grid } from '@mui/material';
import Theme from 'styles/theme';
import image from 'assets/banners/phone1.png';
import './bottom-banner.scss';
import StyledSection from '../../../../components/common/styled-section';

const BottomBanner = () => {
  return (
    <div style={{ backgroundColor: Theme.palette.primary.main }}>
      <StyledSection>
        <Grid container>
          <Grid container item xs={12} sm={12} md={8} lg={8}>
            <div className="bottom-banner-text-container">
              <span className="banner-title">
                En FixUP! podes hablar desde con nuestro BOT hasta con nuestro CEO
              </span>
              <span className="banner-description">
                Sabemos lo importante que es tu equipo para vos y sabemos lo frustrante que puede
                ser necesitar hablar con alguien y no poder hacerlo.
              </span>
              <span className="banner-description">
                Si necesitas contactar a nuestro CEO, podrás hacerlo escribiendo a ceo@fixupweb.com
              </span>
            </div>
          </Grid>
          <Grid container item xs={12} sm={12} md={4} lg={4} justifyContent="center">
            <div className="image-bottom-banner-container">
              <img
                src={image}
                alt="dude using a phone"
                className="image-bottom-banner"
                loading="lazy"
              />
            </div>
          </Grid>
        </Grid>
      </StyledSection>
    </div>
  );
};

export default BottomBanner;
