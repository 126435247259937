import StyledButton from '../../../common/styled-button';
import { PRIMARY_TABS, SECONDARY_TABS } from '../../items';
import { StyledMenuItem } from '../../../common/styled-menu-item';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Divider } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

const PrimaryMenu = ({ setStep }) => (
  <>
    <StyledButton color="secondary" variant="contained" onClick={() => setStep(1)}>
      COTIZAR
    </StyledButton>
    <MenuSection>
      {SECONDARY_TABS.map((tab) => (
        <>
          <AccountTab>
            <MenuTab href={tab.path}>
              <tab.icon />
              <StyledMenuItem fontSize={16} fontWeight={400}>
                {tab.title}
              </StyledMenuItem>
            </MenuTab>
            <ArrowForwardIosIcon fontSize="10px" />
          </AccountTab>
          <Divider />
        </>
      ))}
    </MenuSection>
    <MenuSection>
      {PRIMARY_TABS.map((tab) => (
        <>
          <MenuTab href={tab.path}>
            <tab.icon />
            <StyledMenuItem fontSize={16} fontWeight={400}>
              {tab.title}
            </StyledMenuItem>
          </MenuTab>
          <Divider />
        </>
      ))}
    </MenuSection>
  </>
);

export default PrimaryMenu;

const AccountTab = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    alignItems: 'center'
  };
});

const MenuTab = muiStyled('a')(({ theme }) => {
  return {
    color: theme.palette.primary.main,
    display: 'flex',
    gap: '20px',
    textDecoration: 'none'
  };
});

const MenuSection = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    borderRadius: 10,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: '15px',
    backgroundColor: 'white'
  };
});
