import { useEffect, useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { Modal } from '@mui/material';
import FixUpLogo from 'assets/FIXUP-LOGO-WHITE.svg';
import { StyledSpan } from '../common/styled-span';
import { useSelector } from 'react-redux';
import { fullImageURL } from '../../utilities/utils';
import BenefitsComponent from '../benefits/benefits-component';
import StyledButton from '../common/styled-button';
import joystick from '../../assets/devices/joystick.png';

const Onboarding = () => {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'inherit';
    document.body.style.maxHeight = open ? '100vh' : 'unset';
    return () => {
      document.body.style.overflow = 'inherit';
      document.body.style.maxHeight = 'unset';
    };
  }, [open]);

  const closeModal = () => {
    setOpen(false);
    localStorage.setItem('show-onboarding', false);
    window.location.reload();
  };

  const deviceType = useSelector((state) => state.pricing.device.type);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ModalContainer>
        <img src={FixUpLogo} alt="fixup" height={25} loading="lazy" />
        <TextContainer>
          <StyledSpan color="white" fontSize={24} fontWeight={700}>
            ¡Volvé a tener tu
          </StyledSpan>
          <StyledSpan color="white" fontSize={32} fontWeight={700}>
            {deviceType?.nombre || 'Consola de Juegos'}
          </StyledSpan>
          <StyledSpan color="white" fontSize={24} fontWeight={700}>
            como nueva!
          </StyledSpan>
        </TextContainer>
        <img
          src={deviceType?.icono ? fullImageURL(deviceType.icono) : joystick}
          alt="device"
          height={250}
          loading="lazy"
        />
        <BenefitsContainer>
          <StyledSpan color="white" fontSize={16} fontWeight={700}>
            Y aprovechá estos beneficios hoy:
          </StyledSpan>
          <BenefitsComponent />
        </BenefitsContainer>
        <StyledButton color="secondary" variant="contained" onClick={closeModal}>
          Continuar
        </StyledButton>
      </ModalContainer>
    </Modal>
  );
};

export default Onboarding;

const ModalContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 20,
    width: '100vw',
    height: '100vh',
    background: 'linear-gradient(180deg, rgba(14, 25, 79, 0) 0%, rgba(17, 33, 108, 1) 100%)',
    backgroundColor: 'rgba(17, 33, 108, 0.8)'
  };
});

const TextContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'center'
  };
});

const BenefitsContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'center'
  };
});
