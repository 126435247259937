import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import CircleIcon from '@mui/icons-material/Circle';
import './review-card.scss';
import { StyledSpan } from '../../common/styled-span';
import Theme from '../../../styles/theme';
import { useState } from 'react';

const ReviewCard = ({ review }) => {
  const [unlimited, setUnlimited] = useState(false);
  return (
    <Card
      elevation={3}
      sx={{ borderRadius: '10px' }}
      className={`review-card-container ${unlimited ? 'unlimited' : ''}`}
      onMouseEnter={() => setUnlimited(true)}
      onMouseLeave={() => setUnlimited(false)}>
      <div className="top-review-card">
        <Rating size="small" value={review.puntuacion} readOnly />
        <div className="review-verified-container">
          <StyledSpan color={Theme.palette.success.main} fontWeight="bold" fontSize={8}>
            VERIFICADO
          </StyledSpan>
          <CircleIcon sx={{ height: '10px' }} color="success" />
        </div>
      </div>
      <div className="name-review-card">
        <StyledSpan color={Theme.palette.primary.main} fontWeight="bold" fontSize={14}>
          {review.nombre}
        </StyledSpan>
      </div>
      <StyledSpan color={Theme.palette.primary.main} fontWeight={300} fontSize={14}>
        {review.subtitulo}
      </StyledSpan>
      <div className={`review-description ${unlimited ? 'unlimited' : ''}`}>
        <StyledSpan
          color={Theme.palette.primary.main}
          fontWeight={400}
          fontSize={10}
          lineHeight={1.4}>
          {review.descripcion}
        </StyledSpan>
      </div>
      {review.descripcion.length > 135 && (
        <StyledSpan fontWeight={700} fontSize={10} color={Theme.palette.lightblue.main}>
          {!unlimited && 'Leer más'}
        </StyledSpan>
      )}
    </Card>
  );
};

export default ReviewCard;
