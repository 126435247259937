import StyledSection from 'components/common/styled-section';
import ReviewsSection from './sections/reviews-section/reviews-section';
import IntroBanner from './sections/intro-banner/intro-banner';
import PartnersSection from './sections/partners-section/partners-section';
import RepairedThisWeekSection from './sections/repaired-this-week-section/repaired-this-week-section';
import StoriesSection from './sections/stories-section/stories-section';
import FixUpInMediaSection from 'components/media/fix-up-in-media-section/fix-up-in-media-section';
import InitialBanner from './sections/initial-banner/initial-banner';
import BottomBanner from './sections/bottom-banner/bottom-banner';
import ContactBanner from 'components/contact-banner/contact-banner';
import { PageContainer } from 'components/common/styled-page-container';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import commonActions from '../../redux/actions/common.actions';

const ReviewsPage = ({ mobileView }) => {
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.common.partners);
  const media = useSelector((state) => state.common.fixUpInMedia);
  useEffect(() => {
    dispatch(commonActions.getPartners.request());
    dispatch(commonActions.getFixUpInMedia.request());
  }, [dispatch]);
  return (
    <PageContainer>
      <StyledSection>
        <InitialBanner />
      </StyledSection>
      <StyledSection>
        <ReviewsSection mobileView={mobileView} />
      </StyledSection>
      <IntroBanner />
      <StyledSection>
        <StoriesSection />
      </StyledSection>
      <PartnersSection partners={partners} />
      <StyledSection>
        <RepairedThisWeekSection />
      </StyledSection>
      <BottomBanner />
      <FixUpInMediaSection media={media} />
      <ContactBanner />
    </PageContainer>
  );
};

export default ReviewsPage;
