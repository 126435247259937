import { styled as muiStyled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const PricingButton = muiStyled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  fontFamily: 'Inter, sans-serif',
  fontWeight: 'bold',
  borderRadius: 28,
  textTransform: 'none',
  cursor: 'pointer'
}));
