import { Stack, Typography } from '@mui/material';
import fixupWhiteLogo from '../../assets/FIXUP-LOGO-WHITE.svg';
import styled from 'styled-components';
import memoji from '../../assets/memoji.svg';
import { useNavigate } from 'react-router-dom';
import StyledButton from 'components/common/styled-button';

const Error = () => {
  const navigate = useNavigate();

  return (
    <Stack width="100vw" minHeight="100vh" backgroundColor="#36373C" alignItems={'center'}>
      <Stack maxWidth={1200} padding={'0 1rem'}>
        <img
          src={fixupWhiteLogo}
          alt="Fixup"
          width={90}
          style={{ marginTop: '2rem' }}
          loading="lazy"
        />
        <ResponsiveStack>
          <Stack gap={'2rem'}>
            <StyledText>Ups! Algo salió mal.</StyledText>
            <StyledText fontSize="24px !important" fontWeight="500 !important">
              Lo sentimos. Esta página no existe más o ha surgido un error.
            </StyledText>
            <Stack
              flexDirection={'row'}
              gap={'1rem'}
              flexWrap={'wrap'}
              justifyContent={'center'}
              margin={'1rem 0'}>
              <CustomButton
                width="256px"
                sx={{ backgroundColor: '#116FFF !important' }}
                onClick={() => navigate(`/`)}>
                <StyledText fontSize="18px !important">Ir a página principal</StyledText>
              </CustomButton>
              <CustomButton width="210px" sx={{ backgroundColor: '#E1292A !important' }}>
                <StyledText fontSize="18px !important">Solicitar ayuda</StyledText>
              </CustomButton>
            </Stack>
          </Stack>
          <ResponsiveImage
            src={memoji}
            alt="Hombre asustado"
            width={356}
            style={{
              maxWidth: '100%', // Ensure the image is responsive
              height: 'auto',
              margin: '0 auto' // Center the image horizontally
            }}
          />
        </ResponsiveStack>
      </Stack>
    </Stack>
  );
};

const StyledText = styled(Typography)(() => {
  return {
    color: '#FAFBFE',
    fontFamily: 'Inter',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'
  };
});

const CustomButton = styled(StyledButton)(() => {
  return {
    '&:hover': {
      transition: 'opacity 200ms !important',
      opacity: '0.7 !important'
    },
    borderRadius: '100px !important',
    height: '60px',
    color: 'secondary !important',
    variant: 'contained !important'
  };
});

const ResponsiveStack = styled(Stack)({
  maxWidth: 1200,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '1rem',
  '@media (max-width: 1095px)': {
    flexDirection: 'column-reverse'
  }
});

const ResponsiveImage = styled('img')({
  '@media (max-width: 1095px)': {
    // Additional styles for screens less than or equal to 1095px
    width: 256 // Adjust as needed
  }
});

export default Error;
