import StyledCard from '../common/styled-card';
import { styled as muiStyled } from '@mui/material/styles';
import theme from '../../styles/theme';
import { StyledSpan } from '../common/styled-span';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { fullImageURL } from 'utilities/utils';

const SucursalCard = ({ sucursal }) => {
  const { nombre, direccion_aproximada, horarios_atencion, direccion_mapa_zonal_imagen } = sucursal;

  const sucursalType = () => {
    if (sucursal?.sucursal_fixup_point) {
      return {
        label: 'FixUP! Point',
        color: theme.palette.secondary.main
      };
    }

    if (sucursal?.sucursal_inteligente) {
      return {
        label: 'Sucursal Inteligente',
        color: theme.palette.success.main
      };
    }

    if (sucursal?.centro_exclusivo) {
      return {
        label: 'Centro Autorizado',
        color: theme.palette.blue.main
      };
    }

    return '';
  };
  return (
    <SucursalCardContainer scale={1}>
      <ImageContainer>
        <img
          src={fullImageURL(direccion_mapa_zonal_imagen)}
          height={150}
          width="100%"
          alt="sucursal location"
          loading="lazy"
        />
        <ServiceTypeLabelContainer>
          <ServiceTypeLabel color={sucursalType()?.color}>
            <StyledSpan fontSize={10} fontWeight={700} color="white">
              {sucursalType()?.label}
            </StyledSpan>
          </ServiceTypeLabel>
        </ServiceTypeLabelContainer>
      </ImageContainer>
      <DescriptionContainer>
        <StyledSpan fontWeight={700} fontSize={14}>
          {nombre}
        </StyledSpan>
        <StyledSpan fontWeight={400} fontSize={12}>
          {direccion_aproximada}
        </StyledSpan>
      </DescriptionContainer>
      <hr style={{ width: '100%' }} />
      <AdditionalInfoContainer>
        <AccessTimeIcon style={{ color: '#019334' }} />
        <StyledSpan color={'#019334'} fontWeight={700} fontSize={14}>
          {horarios_atencion}
        </StyledSpan>
      </AdditionalInfoContainer>
    </SucursalCardContainer>
  );
};

export default SucursalCard;

const ImageContainer = muiStyled('div')(() => {
  return {
    height: '160px',
    width: '100%'
  };
});

const ServiceTypeLabel = muiStyled('div')(({ ...props }) => {
  return {
    marginRight: 10,
    backgroundColor: props.color,
    width: 'max-content',
    borderRadius: 10,
    padding: '5px'
  };
});

const ServiceTypeLabelContainer = muiStyled('div')(() => {
  return {
    position: 'relative',
    bottom: '145px',
    marginLeft: 'auto',
    width: 'max-content'
  };
});

const DescriptionContainer = muiStyled('div')(() => {
  return {
    padding: '0 20px 0 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center'
  };
});

const AdditionalInfoContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
    gap: '10px',
    padding: '0 0.8rem',
    height: '40px'
  };
});

const SucursalCardContainer = muiStyled(StyledCard)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '300px',
    border: '2px solid transparent',
    height: '300px'
  };
});
