import { styled as muiStyled } from '@mui/material/styles';

export const BottomBar = muiStyled('div')(({ theme }) => {
  return {
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      backgroundColor: 'white',
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: 10,
      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    }
  };
});

export const HolderBottomBar = muiStyled('div')(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '20px'
    }
  };
});
