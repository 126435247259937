import { styled as muiStyled } from '@mui/material/styles';

export const ServicePaddingContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '100px',
      gap: '30px'
    }
  };
});

export const ServiceContainer = muiStyled('section')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    scrollMarginTop: '140px',
    [theme.breakpoints.up('md')]: {
      scrollMarginTop: '180px'
    }
  };
});

export const ButtonContainer = muiStyled('div')(({ theme }) => {
  return {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  };
});
