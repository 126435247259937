import { SearchSection, StyledStepContainer } from '../../components/styled-overlay';
import { StyledDescription, StyledTitle } from 'components/common/styled-span';
import FlawCheckbox from 'components/flaw-checkbox/flaw-checkbox';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { BottomBar } from '../../components/bottom-bar';
import { ContinueButton } from '../../components/buttons';
import { useEffect, useState } from 'react';
import QuestionsStep from './questions-step';
import QuestionsModal from './questions-modal';
import pricingActions from '../../../../redux/actions/pricing.actions';
import STATUS from '../../../../constants/status';
import LoaderComponent from '../../../../components/loader/loader-component';
import { ButtonContainer } from 'pages/servicios/components/overlay/styled';
import StyledButton from 'components/common/styled-button';

const FlawStep = ({ setActiveStep, setFile }) => {
  const [questionsStep, setQuestionsStep] = useState(false);
  const [questionsModal, setQuestionsModal] = useState(false);
  const dispatch = useDispatch();
  const deviceType = useSelector((state) => state.pricing.device.type);
  const flaws = useSelector((state) => state.pricing.flaws);
  const flawsStatus = useSelector((state) => state.pricing.getFlawsStatus);
  const questions = useSelector((state) => state.pricing.questions);
  const questionsStatus = useSelector((state) => state.pricing.getQuestionsStatus);
  const selectedFlaws = useSelector((state) => state.pricing.device.flaws);
  const deviceBrand = useSelector((state) => state.pricing.device.brand);
  const flawsToString = selectedFlaws.map((flaw) => flaw.id).join(',');
  const [viewAll, setViewAll] = useState(false);
  const flawsToShow = !viewAll ? flaws?.slice(0, 10) : flaws;
  const handleShowQuestionsButton = () => {
    document.documentElement.style.overflow = 'auto';
    setQuestionsStep(true);
    setQuestionsModal(false);
  };
  const handleSkipButton = () => {
    setActiveStep((prev) => prev + 1);
    window.scrollTo(0, 0);
    document.documentElement.style.overflow = 'auto';
  };
  const handleContinueButton = () => {
    dispatch(pricingActions.getQuestionsByFlaws.request(flawsToString));
    dispatch(pricingActions.device.updateAnswers('CLEAR'));
    setQuestionsModal(true);
  };

  const showMore = () => {
    setViewAll(true);
  };

  useEffect(() => {
    dispatch(pricingActions.getFlawsByDevice.request(deviceType.id));
  }, [deviceType]);

  useEffect(() => {
    if (questionsStatus === STATUS.success && questions.length === 0 && questionsModal) {
      setActiveStep((prev) => prev + 1);
    }
  }, [questionsStatus, questions]);

  useEffect(() => {
    if (questionsModal && questions.length) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [questionsModal]);

  return questionsStep ? (
    <QuestionsStep setActiveStep={setActiveStep} setFile={setFile} />
  ) : flawsStatus === STATUS.loading || questionsStatus === STATUS.loading ? (
    <LoaderComponent />
  ) : (
    <StyledStepContainer>
      <SearchSection>
        <StyledTitle>¿Qué falla tiene tu {deviceBrand.marca_nombre}?</StyledTitle>
        <StyledDescription>Podés seleccionar varias fallas.</StyledDescription>
        <Grid container spacing={3}>
          {flawsToShow.map((flaw) => (
            <Grid item xs={12} md={6} key={flaw.id}>
              <FlawCheckbox
                flaw={flaw}
                selected={selectedFlaws.filter((selected) => selected.id === flaw.id).length > 0}
                defaultImage={deviceType.icono}
              />
            </Grid>
          ))}
        </Grid>
        {!viewAll && flaws?.length > 10 && (
          <ButtonContainer>
            <StyledButton
              variant="contained"
              color="white"
              sx={{ marginTop: '2rem' }}
              onClick={showMore}>
              Ver más
            </StyledButton>
          </ButtonContainer>
        )}
      </SearchSection>
      {questions.length > 0 && (
        <QuestionsModal
          handleClose={() => setQuestionsModal(false)}
          open={questionsModal}
          handleContinueButton={handleShowQuestionsButton}
          handlePassButton={handleSkipButton}
        />
      )}
      <BottomBar>
        <ContinueButton
          disabled={selectedFlaws.length === 0}
          handleClick={() => handleContinueButton()}
        />
      </BottomBar>
    </StyledStepContainer>
  );
};

export default FlawStep;
