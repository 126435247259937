import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from 'components/common/styled-span';
import { HashLink } from 'react-router-hash-link';
import { useEffect, useState } from 'react';
import { isMobile } from '../../../utilities/utils';

const FixedBar = () => {
  return (
    <ComponentContainer>
      <ComponentContent>
        <HashLink
          to="#i-take-it"
          smooth
          style={{ textDecoration: 'none', height: '30px', display: 'flex', alignItems: 'center' }}>
          <ServiceText>Yo lo llevo</ServiceText>
        </HashLink>
        <HashLink
          to="#at-home"
          smooth
          style={{ textDecoration: 'none', height: '30px', display: 'flex', alignItems: 'center' }}>
          <ServiceText>Retiro a domicilio</ServiceText>
        </HashLink>
        <HashLink
          to="#remote"
          smooth
          style={{ textDecoration: 'none', height: '30px', display: 'flex', alignItems: 'center' }}>
          <ServiceText>
            <ServiceText>Servicio remoto</ServiceText>
          </ServiceText>
        </HashLink>
        <HashLink
          to="#do-it-yourself"
          smooth
          style={{ textDecoration: 'none', height: '30px', display: 'flex', alignItems: 'center' }}>
          <ServiceText>Hágalo usted mismo (DIY)</ServiceText>
        </HashLink>
      </ComponentContent>
    </ComponentContainer>
  );
};

export default FixedBar;

const ComponentContainer = muiStyled('div')(() => {
  const fullHeaderHeight = isMobile() ? 55 : 100;
  const headerHeight = isMobile() ? 55 : 65;
  const [scroll, setScroll] = useState(fullHeaderHeight);
  const isScrolled = () => {
    setScroll(Math.max(fullHeaderHeight - window.scrollY, headerHeight));
  };
  useEffect(() => {
    window.addEventListener('scroll', isScrolled);
    return () => {
      window.removeEventListener('scroll', isScrolled);
    };
  });
  return {
    backgroundColor: '#F3F4F8',
    position: 'fixed',
    zIndex: '20',
    padding: '15px',
    top: scroll,
    width: '100vw',
    overflow: 'auto'
  };
});

const ComponentContent = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      width: 'max-content'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  };
});

const ServiceText = muiStyled(StyledSpan)(() => {
  return {
    fontWeight: 700,
    fontSize: 14,
    whiteSpace: 'nowrap'
  };
});
