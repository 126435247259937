import { styled as muiStyled } from '@mui/material/styles';
import { StyledTitle } from 'components/common/styled-span';
import StepComponent from './step-component';

const HowItWorks = ({ steps }) => {
  return (
    <ComponentContainer>
      <StyledTitle>¿Cómo funciona?</StyledTitle>
      <ComponentContent>
        {steps.map((step) => (
          <StepComponent key={step.id} step={step} />
        ))}
      </ComponentContent>
    </ComponentContainer>
  );
};

export default HowItWorks;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {}
  };
});

const ComponentContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px'
  };
});
