import { styled as muiStyled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import theme from '../../styles/theme';

const CustomTextField = ({ label, value, onChange, placeholder, type, error }) => {
  return (
    <StyledTextField
      variant="outlined"
      label={label}
      onChange={onChange}
      value={value}
      inputProps={{
        style: { fontSize: 18, color: theme.palette.primary.main, fontFamily: 'Inter, sans-serif' },
        autoComplete: 'new-password'
      }}
      InputLabelProps={{
        style: { fontSize: 14, fontWeight: 700, fontFamily: 'Inter, sans-serif' }
      }}
      placeholder={placeholder}
      type={type}
      error={error}
      color="lightblue"
    />
  );
};

export default CustomTextField;

export const StyledTextField = muiStyled(TextField)(() => {
  return {
    backgroundColor: 'white',
    borderRadius: 12,
    // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    width: '100%'
  };
});
