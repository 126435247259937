import argentina from 'assets/countries/argentina.png';
import chile from 'assets/countries/chile.png';
import colombia from 'assets/countries/colombia.png';
import uruguay from 'assets/countries/uruguay.png';
import mexico from 'assets/countries/mexico.png';

const COUNTRIES = [
  {
    name: 'Argentina',
    image: argentina,
    id: 1,
    areaCode: '54',
    available: true
  },
  {
    name: 'Uruguay',
    image: uruguay,
    id: 8,
    areaCode: '598',
    available: true
  },
  {
    name: 'Chile',
    image: chile,
    id: 9,
    areaCode: '56',
    available: true
  },
  {
    name: 'Colombia',
    image: colombia,
    id: 4,
    available: false
  },
  {
    name: 'México',
    image: mexico,
    id: 5,
    available: false
  }
];

export default COUNTRIES;
