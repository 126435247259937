import { FormControlLabel, Radio } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import StyledCard from 'components/common/styled-card';
import theme from 'styles/theme';

const SelectRadio = ({ value, label, checked, withDatePicker }) => {
  return (
    <ComponentContainer checked={checked} scale={1}>
      <StyledFormControlLabel
        checked={checked}
        sx={{
          '.MuiFormControlLabel-label': {
            fontSize: 12,
            fontWeight: 700,
            color: theme.palette.primary.main,
            fontFamily: 'Inter, sans-serif'
          },
          height: withDatePicker && 33
        }}
        labelPlacement="start"
        value={value}
        control={
          <Radio
            sx={{
              '&.Mui-checked': {
                color: theme.palette.success.main
              }
            }}
          />
        }
        label={label}
      />
    </ComponentContainer>
  );
};

export default SelectRadio;

const ComponentContainer = muiStyled(StyledCard)(({ ...props }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '10px 20px 10px 20px',
    border: props.checked ? '2px solid #6CE194' : '2px solid transparent'
  };
});

const StyledFormControlLabel = muiStyled(FormControlLabel)(() => {
  return {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0
  };
});
