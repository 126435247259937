import { PageContainer } from '../../components/common/styled-page-container';
import StyledSection from '../../components/common/styled-section';
import { styled as muiStyled } from '@mui/material/styles';
import './TerminosYCondicionesPage.scss';

const TerminosYCondicionesPage = () => {
  return (
    <PageContainer>
      <StyledSection>
        <PageContent>
          <div className="section">
            <h2 className="headng1">
              Términos y condiciones de uso <br />
              para usuarios del sitio
            </h2>
            <div className="text-block-27">
              Este documento describe los términos y condiciones de uso del sitio web
              https://fixupweb.com (el “Sitio” o la “Plataforma”).Se deja constancia que cualquier
              persona que no acepte estos términos y condiciones, que son de carácter obligatorio y
              vinculante, deberá abstenerse de utilizar el Sitio y/o los servicios.Nos reservamos el
              derecho de actualizar y/o modificar este Acuerdo en cualquier momento sin previo
              aviso. Por esta razón, le sugerimos revisar el Acuerdo cada vez que utilice la
              Plataforma
            </div>
            <div className="div-block-67">
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  FIX UP!
                  <br />‍
                </strong>
                <br />
                Este sitio web https://fixupweb.com es operado por Wabrit S.A.S, CUIT 30-71662061-8,
                con domicilio en Av. Cabildo 759, Oficina 5, Capital Federal, Argentina, CP 1426 (en
                adelante “FixUP!”).<strong>¿Qué es FixUP!?</strong>
                Es un servicio tecnológico accesible a través del sitio web y otros canales
                digitales, que consta de una plataforma de difusión e intermediación de contratación
                de servicios entre empresas prestadoras de servicios de reparación de dispositivos
                tecnológicos, tales como: celulares, computadoras, impresoras, consolas de video
                juego, entre otros; en adelante los “Proveedores de servicio” con personas, que como
                usted, desean contratar servicios de reparación de algún dispositivo de tecnología
              </div>
              <div className="text-privacidad">
                FixUP! pone a disposición el Sitio, sea o no sea un usuario registrado, una
                herramienta tecnológica de búsqueda de soluciones para sus dispositivos tecnológicos
                que le permitirá a Ud. acceder de una forma fácil y sencilla a una variedad de
                servicios ya sea con precio estandarizado o personalizados, confeccionados por
                FixUP! y/o los proveedores de servicio.
                <br />
                <br />
                USTED ENTIENDE Y RECONOCE QUE FIXUP! POR SI, NO PRESTA SERVICIOS DE REPARACIÓN DE
                EQUIPOS DE TECNOLOGÍA, COMO CELULARES, COMPUTADORAS, IMPRESORAS, CONSOLAS DE VIDEO
                JUEGO Y OTROS, SINO QUE LOS SERVICIOS SON PRESTADOS POR TERCEROS ESPECIALISTAS, QUE
                NO ESTÁN EMPLEADOS POR FIXUP!.
                <br />
                <br />
                Al visitar el Sitio y utilizar el Servicio, usted acepta cumplir los términos y
                condiciones de este Acuerdo y acepta estar legalmente vinculado a ellos, sea o no un
                usuario registrado en la base de datos de FixUP. Este Acuerdo rige el acceso y uso
                de la Plataforma y constituyen un contrato vinculante entre usted y FixUP. Lea
                también con detenimiento nuestra Política de privacidad, disponible en{' '}
                <a href="/privacidad">privacidad.</a>
                Si Usted no está conforme con este Acuerdo, no debe utilizar la Plataforma ya que
                los presentes términos y condiciones tienen carácter obligatorio y vinculante.
                <br />
                <br />
                En consecuencia, LA PLATAFORMA CONSTITUYE UNA HERRAMIENTA TECNOLOGICA DE SERVICIOS
                DE DIFUSIÓN E INTERMEDIACIÓN DE CONTRATACIÓN DE SERVICIOS A TRAVÉS DE LA CUAL USTED
                PUEDE OBTENER INFORMACIÓN SOBRE PRESUPUESTOS CON PRECIO ESTANDARIZADO O PRESUPUESTOS
                PERSONALIZADOS PARA LA CONTRATACIÓN DE SERVICIOS DE REPARACIÓN DE DISPOSITIVOS
                TENCOLÓGICOS.
                <br />
                <br />
                USTED ACEPTA Y CONVIENE QUE FIXUP! NO ES PARTE DE NINGÚN CONTRATO FIRMADO ENTRE LOS
                PROVEEDORES DE SERVICIO Y UD. NI TAMPOCO ES UNA EMPRESA QUE PROVEE LOS SERVICIOS QUE
                SE PUBLICAN EN LA PLATAFORMA. FIXUP! NO TIENE NINGÚN CONTROL SOBRE LA CONDUCTA NI LA
                DILIGENCIA DE LOS PROVEEDORES DE SERVICIO Y USUARIOS Y RECHAZA TODA RESPONSABILIDAD
                A ESTE RESPECTO.
                <br />
                <br />
                FIXUP! SE LIMITA A PONER A DISPOSICIÓN UNA HERRAMIENTA TECNOLÓGICA DE COMUNICACIÓN Y
                PROMUEVE UN ENTORNO DE CONTRATACIÓN SEGURA ENTRE USUARIOS Y EMPRESAS DE SERVICIO
                BRINDANDO A AMBOS EL SERVICIO DE INTERMEDIACIÓN DE CONTRATACIÓN DE SERVICIOS.
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  USUARIOS-CAPACIDAD
                  <br />‍<br />‍
                </strong>
                Los Servicios de FixUP! (según se definen más adelante) están dirigidos a personas
                físicas o jurídicas con capacidad legal para contratar. No podrán utilizar los
                Servicios las personas que no tengan esa capacidad, los menores de edad o los
                Usuarios que hayan sido suspendidos del Sitio. Al contratar los servicios de FixUP!
                usted declara con carácter de declaración jurada que: i) es una persona física mayor
                de 18 años de edad, con capacidad jurídica para contratar; o que ii) es el
                representante de una persona jurídica y que tiene poder suficiente para contratar en
                nombre de dicha persona jurídica.Se considerará Usuario a toda persona física o
                jurídica que contrate alguno de los servicios ofrecidos en el Sitio.
              </div>
              <div>
                <strong className="subheading-terms">
                  <br />
                  EL SERVICIO
                  <br />‍
                </strong>
                <br />A través del Sitio, FixUP! ofrece a sus Usuarios principalmente servicios de
                reparación de equipos de tecnología, tales como celulares, computadoras, impresoras,
                entre otros dispositivos tecnológicos.
                <br />
                <br />
                Desde el sitio, los usuarios podrán indicar, a través de un formulario de varios
                pasos, las características del equipo que desean reparar.
                <br />
                <br />
                Dependiendo de las características del equipo indicadas por el usuario, el sitio
                mostrará de forma automática diversas opciones de servicio disponibles para su
                contratación ajustados a su selección, siendo los servicios principales:
                <br />
                <br />
                <strong>Servicio “Yo lo llevo”:</strong> El usuario recibirá los datos de contacto
                del proveedor de servicios más cercano al domicilio especializado en el tipo de
                equipo indicado por el usuario a través de la plataforma, para que lleve su equipo
                para revisión y/o reparación.
                <br />
                <br />
                <strong>Servicio “Envío por correo”</strong>: El usuario podrá enviar su equipo para
                su revisión y/o reparación a través de un operador logístico independiente. El
                usuario deberá acercar su equipo a la sucursal de dicho operador logístico más
                cercana para que éste realice el envío del equipo al centro de reparaciones
                determinado por FixUP de acuerdo a las características del equipo. El usuario deberá
                acondicionar y embalar el equipo en forma adecuada para protegerlo durante el envío.
                A estos efectos, el Usuario deberá cumplir los siguientes requisitos: el envío
                deberá estar cerrado y en condiciones que aseguren la protección del contenido,
                siendo responsable de cualquier daño o perjuicio que pueda sufrir el proveedor de
                servicio y/o la Empresa de logística. Los Usuarios reconocen y aceptan que la
                actividad de FIXUP!, se limita a las actividades descriptas en la cláusula 1. de
                estos Términos y Condiciones. En consecuencia, los Usuarios entienden y reconocen
                que FIXUP! no transporta los equipos de los usuarios en ninguna forma, razón por la
                cual comprenden y aceptan que FIXUP! no puede ser responsabilizada por el Servicio
                de las Empresas de logística independientes, ni por los bienes entregados y/o
                contenidos en el interior de los paquetes entregados a éstas, en tanto que no es
                quien brinda el Servicio de las Empresas de logística y tampoco es quien despacha
                los productos involucrados. De esta forma, los Usuarios reconocen y aceptan al
                utilizar este servicio, la responsabilidad por la existencia, estado, calidad y
                cantidad de los bienes enviados, en conformidad con la información declarada en la
                plataforma. El Usuario reconoce y acepta que FIXUP! no es responsable por ninguna de
                las actividades de las Empresas de logística independientes, o los hechos de sus
                dependientes, así como tampoco por los hechos de fuerza mayor o caso fortuito que
                impidan o demoren el Servicio de correo.
                <br />
                <br />
                <strong>Servicio “Retiro a domicilio”</strong>: Un proveedor logístico independiente
                retirará del domicilio indicado por el usuario el equipo para su traslado al
                proveedor de servicios más cercano para su revisión y/o reparación. Para la
                contratación de éste servicio, al ser prestado por un proveedor logístico
                independiente, rigen las mismas condiciones detalladas en el punto anterior.
                <br />
                <br />
                <strong>Servicio “Hágalo usted mismo”</strong>: El usuario podrá adquirir el kit
                requerido para la reparación de su equipo y recibirlo a través de un operador
                logístico externo. El kit consiste en el repuesto a reemplazar y las herramientas
                básicas para su colocación. El usuario asume en forma expresa la responsabilidad
                sobre los daños que puedan producirse ante el incorrecto uso de las mismas.
                <br />
                <br />
                <strong>Servicio “Remoto”</strong>: El usuario podrá contratar un servicio de
                revisión de forma remota, que consiste en la revisión del equipo por parte de un
                técnico especializado del proveedor de servicios a través de una herramienta de
                acceso remoto por internet.
                <br />
                <br />
                Dependiendo de la selección realizada por el usuario de las características del
                equipo y los servicios, el Sitio podrá mostrar al usuario un precio de referencia de
                la reparación y en algunos casos ofrecerá la posibilidad de realizar el pago online
                del servicio de forma anticipada.
                <br />
                <br />
                Luego de completar los datos del equipo y haber seleccionado el tipo de servicio, el
                usuario recibirá a través de correo electrónico la información sobre los pasos a
                seguir para completar su reparación, dependiendo de las características de su equipo
                y del servicio seleccionado.
                <br />
                <br />
                FixUP! mantendrá la comunicación con el usuario durante todo el proceso de
                reparación de su equipo, pudiendo enviar a través de correo electrónico información
                acerca de los avances de su reparación, el presupuesto de reparación y cualquier
                otra información que sea relevante para el servicio contratado.
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  GARANTIA
                  <br />‍
                </strong>
                <br />
                Todos los servicios contratados exclusivamente a través de la plataforma y abonados
                a través de los medios de pago informados por FixUP, contarán con una garantía de 90
                días contados desde la fecha de la reparación realizada, salvo aclaración expresa
                por parte de FixUP en el presupuesto de reparación.
                <br />
                <br />
                La garantía por sobre los trabajos realizados es brindada por el proveedor de
                servicios, conforme lo establece la normativa vigente. Usted contrata proveedores de
                servicio, que no se encuentran sujetos a relación de dependencia o de cualquier otro
                tipo con FixUP. Usted consiente y expresamente presta su conformidad con lo
                expuesto, entendiendo que cualquier reclamo vinculante de su relación con el
                Proveedor de servicios, debe dirigirla al mismo, como así también, exigir la
                garantía que el proveedor de servicios presta por sus servicios.
                <br />
                <br />
                Al tratarse de un contrato entre privados, Proveedor – Usuario, las relaciones de
                consumo se limitan al Usuario y el Proveedor, no pudiendo hacerse extensiva la misma
                a FixUP ni a ninguno de sus dependientes o sociedades vinculadas o controladas.
                Usted consiente lo expuesto y presta expresa conformidad con los términos y alcances
                de la garantía.
                <br />
                <br />
                La garantía de 90 días FixUP informa en este punto a los Usuarios de su Plataforma,
                importa un acto unilateral y no vinculante con las obligaciones emergentes del
                contrato principal, celebrado entre el Usuario y el Proveedor. Usted presta expresa
                conformidad a lo expuesto y entiende que el Servicio de Garantía, no vincula a FixUP
                bajo ningún supuesto, con el eventual acuerdo que el Usuario celebre con el
                Proveedor de servicios.
                <br />
                <br />
                La garantía que le ofrece FixUP a usted se limita exclusivamente sobre la reparación
                efectuada por el proveedor de servicios que haya sido debidamente contratada por el
                usuario a través de la plataforma. La garantía que ofrece FixUP por el término de 90
                (días) meses posteriores a la reparación de su equipo consta de:
                <br />
                <br />
                <span className="text-span-8">
                  La revisión y diagnóstico gratuito del equipo, exclusivamente sobre la falla
                  relacionada directamente a la reparación anteriormente efectuada y objeto de la
                  garantía.
                </span>
                <br />
                <br />
                <span className="text-span-9">
                  En caso de que efectivamente el equipo presente la misma falla que la reparada en
                  la intervención original y que dicho desperfecto no se deba a mal uso o a
                  cualquiera de &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;los ítems detallados en
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;este documento bajo el título de “¿Qué no está
                  cubierto por esta Garantía”, FixUP optará a su exclusivo criterio, por la
                  reparación del equipo o la &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;devolución del dinero
                  abonado por el Usuario en &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;la intervención que
                  origina la garantía.
                  <br />
                  <br />
                  En caso de que el equipo no presente fallas o las mismas no estén amparadas por
                  esta garantía, los costos de transporte / entrega / seguro incurridos para la
                  revisión y &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;devolución del equipo,
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;serán a exclusivo cargo del usuario.
                  <br />
                  <br />
                  La garantía se ofrece exclusivamente en los mismos términos de contratación del
                  servicio original, respetando el Tipo de servicio contratado originalmente por el
                  Usuario.
                  <br />
                  <br />
                  El único y exclusivo recurso del Usuario será su reparación o devolución de dinero
                  de la reparación, a exclusivo criterio de FixUP!. En ningún caso FixUP!, sus
                  afiliadas o &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;proveedores serán
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;responsables ante el Usuario ni ante terceros
                  por daño alguno en exceso del precio de compra del Producto. Esta limitación
                  corresponde a los daños de &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cualquier tipo,
                  incluyendo (i) daños a &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;registros, programas,
                  datos o medios de almacenamiento extraíbles del Usuario o la pérdida de los
                  mismos; o (ii) cualesquiera daños &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;directos o
                  indirectos, ganancias perdidas, ahorros perdidos
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;u otros daños especiales, incidentales,
                  ejemplares o punitivos, ya sea por incumplimiento de la Garantía,
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;contractual, extracontractual o de otro tipo,
                  ya sea que surjan de la utilización o
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;incapacidad de utilización del Producto luego
                  de la reparación.
                  <br />
                  <br />
                  <strong className="text-privacidad sub-privacidad">
                    ¿Qué no está cubierto por esta garantía?
                    <br />
                    <br />
                  </strong>
                </span>
                <strong className="text-privacidad">
                  El reemplazo de piezas faltantes o el mantenimiento preventivo
                  <br />‍<br />
                  El servicio que se requiera debido a accidente, uso indebido, maltrato,
                  negligencia, instalación inadecuada o mantenimiento incorrecto.
                  <br />‍<br />
                  La instalación, remoción, o reconversión de accesorios, equipo periférico o
                  sistemas de computación de los cuales el Producto formara parte.
                  <br />‍<br />
                  El reemplazo o los arreglos de software que sean ajenos a la reparación efectuada.
                  <br />‍<br />
                  La reparación o el reemplazo de cubiertas, plásticos o piezas cosméticas como
                  molduras o acabados interiores o exteriores.
                  <br />‍<br />
                  El servicio que se requiera por fallas ocasionadas por cualquier causa externa,
                  incluido fuego, hurto, fuerza mayor, alteración, problemas que surjan de software
                  o hardware &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;que no haya sido
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;instalados por FIXUP al momento de la reparación.
                  <br />‍<br />
                  El servicio que se requiera debido a fallas o alteraciones en la alimentación
                  eléctrica, rayos, golpes o accidentes de cualquier naturaleza, o reparaciones
                  realizadas que no &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;hayan sido contratadas
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a través de la plataforma de FixUP.
                  <br />‍<br />
                  El servicio sobre un Equipo cuya faja de garantía, se haya dañado, alterado o
                  retirado.
                  <br />‍<br />
                  El daño ocasionado por usar el equipo fuera de los parámetros de uso o
                  almacenamiento establecidos en la Guía o el Manual del usuario del Producto.
                  <br />‍<br />
                  Las modificaciones que se realicen al equipo luego de su reparación ni las fallas
                  ocasionadas por tales modificaciones.
                  <br />‍<br />
                  Las pantallas que tengan hasta cinco (5) píxeles quemados o muertos.
                  <br />‍<br />
                  Se excluyen las partes o piezas que, bajo un uso normal, sufran desgaste y/o vida
                  limitada menor que el plazo de cobertura de la presente garantía (Ej. Cartuchos de
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;impresión, Baterías).
                  <br />
                  <br />
                </strong>
                <span className="text-span-10">
                  <strong className="text-privacidad sub-privacidad">
                    <br />
                    Protección de datos almacenados
                    <br />‍<br />
                  </strong>
                </span>
                <strong className="text-privacidad">
                  FixUP firmemente recomienda, como medida de precaución para protegerse contra
                  posibles fallas, alteraciones o pérdida de datos, que el Usuario haga copias de
                  respaldo con regularidad de todos los datos importantes del Usuario almacenados en
                  el disco duro u otros dispositivos de almacenamiento del Producto, sobre todo
                  antes de ingresarlo a su reparación. SI LOS DATOS DEL USUARIO SE ALTERAN O SE
                  PIERDEN DEBIDO A CUALQUIER PROBLEMA, FALLA O FUNCIONAMIENTO INDEBIDO DE LA UNIDAD
                  DE ALMACENAMIENTO, DISCO DURO U OTROS DISPOSITIVOS DE ALMACENAMIENTO DURANTE SU
                  REPARACIÓN O DURANTE EL PERÍODO DE GARANTÍA DE LA REPARACIÓN Y LOS DATOS NO
                  PUDIERAN RECUPERARSE, FIXUP NO SERÁ RESPONSABLE POR NINGÚN DAÑO O PÉRDIDA DE DATOS
                  NI POR CUALQUIER OTRO DAÑO QUE SURGIERA DE DICHO HECHO. CUANDO COPIE O TRANSFIERA
                  DATOS, POR FAVOR ASEGÚRESE DE CONFIRMAR QUE LOS DATOS SE HAYAN COPIADO O
                  TRANSFERIDO SATISFACTORIAMENTE. FIXUP RECHAZA TODA RESPONSABILIDAD QUE SURJA DEL
                  COPIADO ERRÓNEO O DE LA TRANSFERENCIA INCORRECTA DE LOS DATOS. ANTES DE DEVOLVER
                  CUALQUIER PRODUCTO PARA SERVICIO, ASEGÚRESE DE RESPALDAR SUS DATOS Y DE RETIRAR
                  TODA INFORMACIÓN CONFIDENCIAL, DE SU PROPIEDAD O PERSONAL. FIXUP NO SE HACE
                  RESPONSABLE POR (i) DAÑOS A CUALESQUIERA PROGRAMAS, DATOS O MEDIOS DE
                  ALMACENAMIENTO EXTRAÍBLES O PÉRDIDA DE LOS MISMOS; POR (ii) LA RESTAURACIÓN O
                  REINSTALACIÓN DE CUALESQUIERA PROGRAMAS O DATOS QUE NO SEA EL SOFTWARE INSTALADO
                  POR FIXUP EN EL MOMENTO DE LA REPARACIÓN DEL EQUIPO; NI POR (iii) LA DIVULGACIÓN
                  DE TALES DATOS.
                </strong>
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  PROCESO DE PAGO
                  <br />‍
                </strong>
                <br />
                <span className="text-span-11">
                  Los Usuarios podrán abonar la reparación de su equipo en una o varias instancias
                  de las detalladas a continuación:
                  <br />
                </span>
                <br />
                <span className="text-span-12">
                  Al momento de contratar el servicio: Dependiendo las características del equipo,
                  la falla y el tipo de servicios declarados por el usuario al momento de la carga
                  de datos en &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;la plataforma,&nbsp;de
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;forma automática el Sitio podrá ofrecer la
                  posibilidad del pago anticipado
                  <br />
                </span>
                <br />
                <span className="text-span-13">
                  Al aceptar el presupuesto: En caso de que no haya sido posible indicar un precio
                  de forma anticipada a la revisión, el Usuario recibirá posteriormente al
                  diagnóstico de su &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;equipo un&nbsp;presupuesto
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;por la reparación. El mismo posee un botón de pago
                  para que el Usuario pueda realizar el pago de forma Online.
                  <br />
                </span>
                <br />
                <span className="text-span-14">
                  Al retirar el equipo: El usuario podrá abonar la reparación de su equipo al
                  momento de retirarlo, en caso de que haya optado por un servicio de reparación en
                  un centro de &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;servicios.
                  <br />
                  <br />
                </span>
                Todos los pagos realizados por el Usuario a través de la plataforma de FixUP y los
                pagos efectuados en los centros de reparación de los proveedores de servicio a
                través de tarjeta de crédito / débito procesados a través de Mercado Pago Point o
                código QR, se realizan a través de una integración con el servicio de pagos de{' '}
                <a href="https://www.mercadopago.com.ar/">
                  MERCADOPAGO.
                  <br />
                  <br />‍
                </a>
                FixUP! no realiza cobros de forma directa ni indirectamente. Las cuentas de{' '}
                <a href="https://www.mercadopago.com.ar/">MERCADOPAGO.</a> asociadas al cobro de las
                reparaciones corresponden a los diferentes proveedores de servicio y están asociadas
                a la plataforma de FixUP! exclusivamente con la finalidad de facilitar el proceso de
                cobro a los Usuarios.
                <br />
                <br />
                La factura por los servicios contratados por el usuario será emitida exclusivamente
                por el proveedor de servicios correspondiente, pudiendo ser enviada al usuario por
                FixUP o directamente por el proveedor de servicios.{' '}
                <a href="https://www.mercadopago.com.ar/">
                  <br />‍
                </a>
                ‍
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">PRECIOS</strong> <br />
                <br />
                Todos los precios informados en el Sitio se encuentran expresados en pesos
                argentinos e incluyen IVA, salvo que se indique expresamente lo contrario.
                <br />
                <br />
                Los precios publicados en el sitio son a modo informativo y sólo podrán considerarse
                como válidos los valores que se indiquen en los presupuestos emitidos por FixUP o
                los expresados al momento del pago online a través de la plataforma.
                <br />
              </div>
              <div className="text-privacidad">
                Cuando por errores de carácter tecnológico o bien en caso de que el Usuario consigne
                de manera incorrecta la marca y/o modelo y/o la falla y – en consecuencia – se
                exhiban precios erróneos de los servicios en la Plataforma, que evidentemente
                resultan desproporcionados, comparativamente con el precio del servicio en el
                mercado, FixUP! podrá cancelar la contratación realizada por el usuario, a su libre
                discreción y con plena autonomía, reintegrando al usuario todos los montos que haya
                abonado, en caso de que corresponda.
                <br />
                <br />
                FixUP! se reserva el derecho de fijar precios diferentes a los ofrecidos en otros
                canales de venta, propios y/o de sus proveedores de servicio
                <br />
                <br />
                FixUP! se reserva el derecho de actualizar, modificar y/o descontinuar los servicios
                exhibidos en La Plataforma así como también modificar sus precios sin previo aviso.
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  MODIFICACION DE LOS TERMINOS Y CONDICIONES
                  <br />‍
                </strong>
                <br />
                FixUP! podrá en cualquier momento actualizar el presente Acuerdo y publicará la
                nueva versión del mismo en la Plataforma, sin previo aviso. En caso de que Usted no
                esté de acuerdo con la actualización de los términos y condiciones del Acuerdo, le
                solicitamos no utilice más la Plataforma. Usted podrá ejercer el derecho del art. 6
                de la Ley 25.326 inc. e) y solicitar a FixUP! la cancelación de sus datos de
                registro como Usuario del Sitio. A tal fin consulte el procedimiento para solicitar
                la cancelación de sus datos escribiendo a{' '}
                <a href="mailto:soporte@fixupweb.com">
                  soporte@fixupweb.com
                  <br />
                  <br />‍
                </a>
                El uso del sitio y/o sus servicios implica la aceptación de estos Términos y
                Condiciones.
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  PROPIEDAD INTELECTUAL. MARCAS. ENLACES
                  <br />‍
                </strong>
                <br />
                Usted reconoce que la Plataforma de FixUP!, bases de datos, redes, archivos que le
                permiten acceder y usar el Sitio, son de propiedad exclusiva de FixUP! y están
                protegidas por las leyes y los tratados internacionales de derecho de autor, marcas,
                patentes, modelos y diseños industriales. El uso indebido y la reproducción total o
                parcial de dichos contenidos, marcas y logos quedan prohibidos, salvo autorización
                expresa y por escrito de FixUP!.
                <br />
                <br />
                La Plataforma puede contener enlaces a otros sitios web lo cual no indica que sean
                propiedad u operados por FixUP!. En virtud de que FixUP! no tiene control sobre
                tales sitios, NO será responsable por los contenidos, materiales, acciones y/o
                servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la
                utilización de los mismos, sean causadas directa o indirectamente. La presencia de
                enlaces a otros sitios web y/o la visualización de marcas y/o logos, no implica una
                sociedad, relación, aprobación, respaldo de FixUP! a dichos sitios y sus contenidos,
                o viceversa.
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  INDEMNIZACION
                  <br />‍
                </strong>
                <br />
                Usted se compromete a mantener indemne a FixUP!, sus filiales, empresas controladas
                y/o controlantes, directivos, administradores, representantes y empleados, por
                cualquier reclamo o demanda de otros Usuarios o terceros por sus actividades en el
                Sitio o por su incumplimiento los Términos y Condiciones del presente Acuerdo y
                demás Políticas que se entienden incorporadas al presente o por la violación de
                cualesquiera leyes o derechos de terceros, incluyendo los honorarios de abogados en
                una cantidad razonable.
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  DATOS PERSONALES
                  <br />‍
                </strong>
                <br />
                En el marco de la Política de Privacidad, FixUP! mantendrá en absoluta
                confidencialidad la totalidad de los Datos Personales suministrados por los Usuarios
                del Sitio, salvo en los casos en los que la Ley de Protección de Datos Personales
                permite su divulgación.
                <br />
                <br />
                Los Datos Personales proporcionados por los Usuarios serán procesados por FixUP! e
                incorporados a la correspondiente base automatizada de datos de la que FixUP! será
                titular y responsable (la Base de Datos). A través del uso del Sitio, Usted
                consiente dicho tratamiento que será utilizado para proporcionarle un mejor servicio
                y para realizar acciones de marketing directo, salvo que Usted solicite lo
                contrario, siempre en cumplimiento de la Ley de Protección de Datos Personales N°
                25.326.
                <br />
                <br />
                Para ejercer sus derechos de acceso, rectificación y supresión de sus Datos
                Personales en la Base de Datos, por favor comuníquese con{' '}
                <a href="http://malito:contacto@fixupweb.com">
                  contacto@fixupweb.com
                  <br />
                  <br />‍
                </a>
                FixUP! ha adoptado los niveles de seguridad de protección de los Datos Personales
                legalmente requeridos, y ha instalado todos los medios y medidas técnicas a su
                alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de
                los Datos Personales.{' '}
                <a href="http://malito:contacto@fixupweb.com">
                  <br />
                </a>
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  LIMITACIÓN DE RESPONSABILIDAD
                  <br />‍
                </strong>
                <br />
                FixUP! no es responsable por el efectivo cumplimiento de las obligaciones asumidas
                por los Usuarios en el perfeccionamiento de la operación. Cada Usuario reconoce que
                al realizar operaciones con otros Usuarios lo hace bajo su propio riesgo.
                <br />
                <br />
                El Usuario comprende y acepta las características y los riesgos propios de la
                conexión y navegación por Internet. Por lo tanto, FixUP! no será responsable por
                fallas, interrupciones y/o caídas de la red y/o de los servicios de conexión y/o
                transmisión de datos, y tampoco garantiza el acceso al Sitio, en forma
                ininterrumpida, precisa o sin errores (siempre que ello no se derive de causas que
                le resulten imputables) y, por lo tanto, FixUP! no responderá por los Daños que
                eventualmente el Usuario pueda llegar a sufrir por dichas circunstancias.
                <br />
                <br />
                FixUP! usa antivirus y protección contra virus, bugs, troyanos, malware, spyware y
                otros riesgos en Internet (en adelante, los “Riesgos”). Sin embargo, ello no podría
                ser suficiente y no releva al Usuario de la responsabilidad de contar con sus
                propios métodos de protección para una navegación, acceso y uso seguro de sitios de
                Internet. Por lo tanto, FixUP! no se responsabiliza por Daños ocasionados por los
                Riesgos y/o todo otro daño derivado del acceso y/o uso del Sitio. FixUP! tampoco
                asume responsabilidad alguna por el acceso o uso no autorizado de los servidores
                utilizados para el alojamiento del Sitio y/o los datos de los Usuarios por parte de
                terceros.{' '}
                <a href="http://malito:contacto@fixupweb.com">
                  <br />
                  <br />‍
                </a>
                FixUP! ha adoptado los niveles de seguridad de protección de los Datos Personales
                legalmente requeridos, y ha instalado todos los medios y medidas técnicas a su
                alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de
                los Datos Personales.
                <br />
                <br />
                FixUP! utiliza el sistema de seguridad cifrado por SSL, capa de conexión segura,
                para encriptar los datos de los Usuarios cargados en el Sitio.
                <br />
                <br />
                Sin embargo, resulta importante señalar que no se puede garantizar la seguridad de
                la información ni de los pagos que se transmiten por Internet o por correo
                electrónico, no siendo FixUP! y/o sus licenciantes y/o sociedades vinculadas en
                ningún caso, responsables de los daños que eventualmente deriven del uso de medios
                de comunicación electrónicos, incluyendo, pero sin limitarse a ellos, los Daños que
                se deriven de un fallo o retraso en el envío de comunicaciones electrónicas,
                intercepción o manipulación de comunicaciones electrónicas por parte de terceros o
                mediante programas informáticos utilizados para la transmisión de comunicaciones
                electrónicas o de virus.
                <br />
                <br />
                Asimismo, FixUP! no será responsable frente al Usuario ni frente ningún tercero por
                los daños, infracciones, delitos, contravenciones o cualquier otra clase de ilícitos
                que pudieran cometerse, ya se trate de derechos de propiedad intelectual, al honor y
                reputación de las personas, sus datos personales, crediticios, derechos del
                consumidor, ni cualquier otro derecho que pudiera ser vulnerado como consecuencias
                del mal uso del Sitio por parte de los Usuarios
                <br />
                <br />
                Las limitaciones a la responsabilidad de FixUP! establecidas en el presente apartado
                alcanzan también a los directores, administradores, representantes, funcionarios,
                empleados y/o asesores de FixUP!, los que también quedan liberados de cualquier
                responsabilidad en relación con el uso y/o acceso al Sitio que realicen los Usuarios
                y terceros por los que no deben responder.{' '}
                <a href="http://malito:contacto@fixupweb.com">
                  <br />
                </a>
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  DOMICILIO Y CONTACTO
                  <br />‍
                </strong>
                <br />
                Se fija como domicilio de Wabrit S.A.S la avenida Cabildo 759 Oficina 5, CP: 1426,
                CABA, Argentina.
                <br />
                <br />
                Si tienes alguna duda sobre los Términos y Condiciones Generales o demás políticas y
                principios que rigen FixUP! escríbanos a{' '}
                <a href="mailto:contacto@fixupweb.com">contacto@fixupweb.com</a>{' '}
                <a href="http://malito:contacto@fixupweb.com">
                  <br />
                </a>
              </div>
              <div className="text-privacidad">
                <strong className="subheading-terms">
                  LEY APLICABLE. JURISDICCIÓN
                  <br />‍
                </strong>
                <br />
                Los Términos y Condiciones así como la relación entre el Usuario y FixUP! serán
                interpretados y estarán regidos por las leyes de la República Argentina. Ante
                cualquier reclamo o controversia que pudiera suscitarse derivado del presente
                acuerdo, serán competentes los Tribunales Ordinarios en lo Comercial con asiento en
                la Ciudad de Buenos Aires.{' '}
                <a href="http://malito:contacto@fixupweb.com">
                  <br />
                </a>
              </div>
            </div>
          </div>
        </PageContent>
      </StyledSection>
    </PageContainer>
  );
};

export default TerminosYCondicionesPage;

export const PageContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 25
  };
});
