import FixedBar from './components/fixed-bar';
import AtHome from './components/sections/at-home';
import ITakeIt from './components/sections/i-take-it';
import Remote from './components/sections/remote';
import DoItYourself from './components/sections/do-it-yourself';
import StyledSection from '../../components/common/styled-section';
import { styled as muiStyled } from '@mui/material/styles';
import { PageContainer } from '../../components/common/styled-page-container';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from 'redux/actions/common.actions';

const ServiciosPage = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.FAQPath);

  useEffect(() => {
    dispatch(commonActions.getFAQPath.request('/home'));
  }, [dispatch]);

  const handleClick = () => {
    navigate('/reparar');
  };

  useEffect(() => {
    if (location.hash) document.querySelector(location.hash).scrollIntoView();
  }, [location]);
  return (
    <>
      <FixedBar />
      <PageContainerOverlay>
        <PageContainer>
          <CustomSection>
            <ITakeIt handleClick={handleClick} data={data} />
            <AtHome handleClick={handleClick} data={data} />
            <Remote handleClick={handleClick} data={data} />
            <DoItYourself handleClick={handleClick} data={data} />
          </CustomSection>
        </PageContainer>
      </PageContainerOverlay>
    </>
  );
};

export default ServiciosPage;

const PageContainerOverlay = muiStyled('div')(() => {
  return {
    marginTop: '100px'
  };
});

const CustomSection = muiStyled(StyledSection)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 60,
    [theme.breakpoints.up('md')]: {
      gap: 100
    }
  };
});
