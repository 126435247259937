import { StyledDescription, StyledTitle } from 'components/common/styled-span';
import { useDispatch, useSelector } from 'react-redux';
import BrandCard from 'components/brands/brand-card/brand-card';
import { Grid } from '@mui/material';
import pricingActions, { RESET_STATE } from 'redux/actions/pricing.actions';
import { SearchSection, StyledStepContainer } from '../../components/styled-overlay';
import { useState, useEffect } from 'react';
import CustomTextField from 'components/text-field/text-field';
import DEVICES from '../../../../utilities/devices';
import smartphone from '../../../../assets/devices/smartphone.png';
import StyledButton from '../../../../components/common/styled-button';
import { styled as muiStyled } from '@mui/material/styles';
import { fullImageURL } from '../../../../utilities/utils';
import LoaderComponent from '../../../../components/loader/loader-component';
import STATUS from '../../../../constants/status';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const BrandStep = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const [searchWord, setSearchWord] = useState('');
  const [quantityToShow, setQuantityToShow] = useState(11);
  const brands = useSelector((state) => state.pricing.brands);
  const brandsStatus = useSelector((state) => state.pricing.getBrandsStatus);
  const deviceType = useSelector((state) => state.pricing.device.type);
  const brandByName = useSelector((state) => state.pricing.brandByName);
  const getBrandByNameStatus = useSelector((state) => state.pricing.getBrandByNameStatus);
  const selectedBrand = useSelector((state) => state.pricing.device.brand);
  const navigate = useNavigate();
  const location = useLocation();
  const showMore = () => {
    setQuantityToShow(quantityToShow + 5);
  };
  const results = brands.filter((brand) =>
    brand.marca_nombre.toUpperCase().includes(searchWord.toUpperCase())
  ).length;
  let { brand } = useParams();
  useEffect(() => {
    dispatch({ type: RESET_STATE });
  }, []);
  useEffect(() => {
    if (brand && getBrandByNameStatus === STATUS.idle) {
      dispatch(pricingActions.getBrandByName.request(brand));
    }
  }, [brand]);
  useEffect(() => {
    if (getBrandByNameStatus === STATUS.success && !selectedBrand) {
      dispatch(pricingActions.device.update('brand', brandByName));
      setActiveStep((prevCount) => prevCount + 1);
    }
  }, [getBrandByNameStatus, brandByName]);
  useEffect(() => {
    dispatch(pricingActions.getBrandsByDevice.request(deviceType?.id));
  }, [deviceType, dispatch]);
  const defaultImage =
    (deviceType?.name && DEVICES.filter((device) => device?.name === deviceType?.name)[0].image) ??
    smartphone;
  const selectBrand = (brand) => {
    dispatch(pricingActions.device.update('brand', brand));
    setActiveStep((prevCount) => prevCount + 1);
    window.scrollTo(0, 0);
    navigate(`${location.pathname}/${brand?.marca_url_amigable}`);
  };
  const selectCustomBrand = () => {
    dispatch(
      pricingActions.device.update('brand', {
        id: 999,
        marca_nombre: searchWord
      })
    );
    setActiveStep((prevCount) => prevCount + 1);
    window.scrollTo(0, 0);
  };

  if (brandsStatus === STATUS.loading) return <LoaderComponent />;

  return (
    <StyledStepContainer>
      <SearchSection>
        <StyledTitle>¿Qué marca es tu {deviceType?.nombre}?</StyledTitle>
        <StyledDescription>Escribelo a continuación o seleccioná la imagen</StyledDescription>
        <CustomTextField
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder="Ej. Samsung"
          value={searchWord}
        />
      </SearchSection>
      <Grid container spacing={2} justifyContent="center">
        {brands
          .filter((brand) => brand.marca_nombre.toUpperCase().includes(searchWord.toUpperCase()))
          .sort(function (a, b) {
            return a.indice - b.indice;
          })
          .slice(0, quantityToShow)
          .map((brand) => (
            <Grid key={brand.id} item xs="auto" onClick={() => selectBrand(brand)}>
              <BrandCard brandImage={fullImageURL(brand.marca_logo)} />
            </Grid>
          ))}
      </Grid>
      {results === 0 && (
        <Grid container alignItems="center" spacing={3}>
          <Grid item md={2} sm={4} xs={4} onClick={selectCustomBrand}>
            <BrandCard brandImage={defaultImage} text={searchWord} />
          </Grid>
        </Grid>
      )}
      {quantityToShow <
        brands.filter((brand) =>
          brand.marca_nombre.toUpperCase().includes(searchWord.toUpperCase())
        ).length && (
        <ButtonContainer>
          <StyledButton variant="contained" color="white" onClick={showMore}>
            Ver más
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledStepContainer>
  );
};

export default BrandStep;

const ButtonContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  };
});
