import { styled as muiStyled } from '@mui/material/styles';

export const StyledStepContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    marginBottom: 40
  };
});

export const SearchSection = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px'
  };
});
