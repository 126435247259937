import { Grid } from '@mui/material';
import { StyledSpan } from 'components/common/styled-span';
import Theme from 'styles/theme';
import image from 'assets/banners/object fly 1.png';
import './intro-banner.scss';
import StyledSection from '../../../../components/common/styled-section';
import { isMobile } from '../../../../utilities/utils';

const IntroBanner = () => {
  return (
    <div
      style={{ backgroundColor: Theme.palette.primary.main, marginTop: isMobile() ? '100px' : 0 }}
      className="intro-banner-container">
      <StyledSection>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={12} md={4} lg={4} justifyContent="center">
            <div className="image-banner-container">
              <img src={image} alt="banner" className="image-banner" loading="lazy" />
            </div>
          </Grid>
          <Grid container item xs={0} sm={0} md={2} lg={2} />
          <Grid container item xs={12} sm={12} md={6} lg={6} justifyContent="center">
            <div className="banner-text-container">
              <StyledSpan color="white" fontSize={40} fontWeight={700}>
                ¡Chau comprar, hola reparar!
              </StyledSpan>
              <StyledSpan color="white" fontSize={20} fontWeight={400}>
                Además de ser bueno para tu bolsillo, lo es para el planeta.
              </StyledSpan>
              <StyledSpan color="white" fontSize={20} fontWeight={400}>
                En FixUP! tenemos la convicción de que los equipos electrónicos pueden tener una
                segunda vida ¡o hasta tres!
              </StyledSpan>
              <StyledSpan color="white" fontSize={20} fontWeight={400}>
                Aunque no sabemos si siete vidas como tu gato, pero podríamos intentarlo.
              </StyledSpan>
            </div>
          </Grid>
        </Grid>
      </StyledSection>
    </div>
  );
};

export default IntroBanner;
