import { StyledSpan } from 'components/common/styled-span';
import { styled as muiStyled } from '@mui/material/styles';

const StepComponent = ({ step }) => {
  const { image, title, description } = step;
  return (
    <ComponentContainer>
      <ImageContainer>
        <img src={image} alt="step" height="100%" loading="lazy" />
      </ImageContainer>
      <TextContainer>
        <StyledSpan fontWeight="bold" fontSize={18}>
          {title}
        </StyledSpan>
        <StyledSpan fontWeight={400} fontSize={14}>
          {description}
        </StyledSpan>
      </TextContainer>
    </ComponentContainer>
  );
};

export default StepComponent;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'center'
  };
});

const ImageContainer = muiStyled('div')(() => {
  return {
    height: '60px',
    width: '60px',
    padding: '5px',
    backgroundColor: 'white',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

const TextContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'flex-start',
    maxWidth: '400px'
  };
});
