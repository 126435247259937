import { StyledStepContainer } from '../../../../components/styled-overlay';
import { StyledSpan, StyledTitle } from 'components/common/styled-span';
import { FormControl, Grid, RadioGroup } from '@mui/material';
import pricingActions from 'redux/actions/pricing.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import CustomTextField from '../../../../../../components/text-field/text-field';
import CustomDatePicker from '../../../../../../components/date-picker/date-picker';
import theme from '../../../../../../styles/theme';
import { styled as muiStyled } from '@mui/material/styles';
import SelectRadio from '../../../../../../components/select-radio/select-radio';
import { BottomBar, HolderBottomBar } from '../../../../components/bottom-bar';
import { ContinueButton } from '../../../../components/buttons';
import { isNullUndefEmptyStr } from '../../../../../../utilities/utils';
import { useNavigate } from 'react-router-dom';
import TextFieldAutocomplete from 'components/text-field-autocomplete/text-field-autocomplete';

const ChooseAddress = () => {
  const dispatch = useDispatch();
  const PROVINCIAS = useSelector((state) => state.pricing.provincias);
  const BARRIOS = useSelector((state) => state.pricing.barrios);
  const clientData = useSelector((state) => state.pricing.client);
  const derivacionId = useSelector((state) => state.pricing.derivacion_id);
  const supportAvailableDatesSucursal = useSelector((state) => state.pricing.serviciosDisponibles);
  const selectedService = useSelector((state) => state.pricing.service.type);
  const navigate = useNavigate();
  const [addressData, setAddressData] = useState({
    provincia: clientData.provincia,
    barrio: clientData.barrio,
    calle: null,
    numero: null,
    piso: null,
    observaciones: null,
    dia: null,
    hora: ''
  });

  // eslint-disable-next-line no-unused-vars
  const availableDates = supportAvailableDatesSucursal
    .find((element) => element.id == 7 || element.id == 2 || element.id == 71)
    ?.horarios_sucursal?.map((element) => {
      if (element?.franja_horaria) {
        return element.fecha;
      }
    });

  useEffect(() => {
    dispatch(pricingActions.getProvincias.request());
  }, [dispatch]);
  useEffect(() => {
    if (addressData.provincia) {
      dispatch(pricingActions.getBarrios.request(addressData.provincia));
    }
  }, [addressData.provincia, dispatch]);
  const handleButton = () => {
    dispatch(
      pricingActions.postEndDerivacion.request({
        derivacion_id: derivacionId,
        company_id: JSON.parse(localStorage.getItem('region-id')),
        servicio_id: selectedService,
        provincia_id: addressData.provincia,
        localidad_id: addressData.barrio,
        calle: addressData.calle,
        nro: addressData.numero,
        piso: addressData.piso,
        observaciones: addressData.observaciones || 'null',
        fecha: new Date(addressData.dia).toISOString().split('T')[0],
        franja_horaria_id: parseInt(addressData.hora),
        hora: addressData.hora
      })
    );
    navigate('/confirmacion');
  };
  return (
    <StyledStepContainer>
      <StyledTitle>¿Por dónde pasamos a buscar tu equipo?</StyledTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextFieldAutocomplete
            value={addressData.provincia}
            label="Provincia"
            labelField="nombre"
            valueField="id"
            options={PROVINCIAS}
            onChange={(event) => setAddressData({ ...addressData, provincia: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldAutocomplete
            value={addressData.barrio}
            label="Barrio"
            labelField="nombre"
            valueField="id"
            options={BARRIOS ?? []}
            onChange={(event) => setAddressData({ ...addressData, barrio: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Calle'}
            onChange={(event) => setAddressData({ ...addressData, calle: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Numero'}
            onChange={(event) => setAddressData({ ...addressData, numero: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Piso o departamento'}
            onChange={(event) => setAddressData({ ...addressData, piso: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Observaciones'}
            onChange={(event) =>
              setAddressData({ ...addressData, observaciones: event.target.value })
            }
          />
        </Grid>
      </Grid>
      <DateContainer>
        <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
          ¿A partir de qué día podemos buscar tu equipo?
        </StyledSpan>
        <StyledSpan fontSize={16} fontWeight={400} color={theme.palette.lightblue.main}>
          Normalmente el retiro será en el día que elijas o al día posterior.
        </StyledSpan>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <CustomDatePicker
              value={addressData.dia}
              onChange={(value) => setAddressData({ ...addressData, dia: value })}
              availableDates={availableDates}
            />
          </Grid>
        </Grid>
      </DateContainer>
      {addressData.dia && (
        <DateContainer>
          <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
            ¿Cuál es tu franja horaria de preferencia?
          </StyledSpan>
          <StyledSpan fontSize={16} fontWeight={400} color={theme.palette.lightblue.main}>
            El horario es sólo informativo.
          </StyledSpan>
          <FormControl>
            <RadioGroup
              onChange={(event) => setAddressData({ ...addressData, hora: event.target.value })}>
              <Grid container spacing={2}>
                {supportAvailableDatesSucursal
                  .find((element) => element.id == 7 || element.id == 2 || element.id == 71)
                  ?.horarios_sucursal?.filter(
                    (date) => date.fecha === new Date(addressData.dia).toISOString().split('T')[0]
                  )[0]
                  ?.franja_horaria?.sort((a, b) => a.id - b.id)
                  .map((hora) => (
                    <Grid item xs={12} md={6} lg={6} key={hora.id}>
                      <SelectRadio value={hora.id} label={hora.label} />
                    </Grid>
                  ))}
                {/* <Grid item xs={12} md={6} lg={6}>
                <SelectRadio value={8} label={'8hrs - 13hrs'} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <SelectRadio value={14} label={'14hrs - 19hrs'} />
              </Grid> */}
              </Grid>
            </RadioGroup>
          </FormControl>
        </DateContainer>
      )}
      <HolderBottomBar />
      <BottomBar>
        <ContinueButton
          handleClick={handleButton}
          disabled={isNullUndefEmptyStr(addressData) || addressData?.hora?.length === 0}>
          Continuar
        </ContinueButton>
      </BottomBar>
    </StyledStepContainer>
  );
};

export default ChooseAddress;

const DateContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  };
});
