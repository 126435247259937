import smartphone from 'assets/devices/smartphone.png';
import tablet from 'assets/devices/tablet.png';
import computer from 'assets/devices/laptop.png';
import printer from 'assets/devices/printer.png';
import smartwatch from 'assets/devices/smartWatch.png';
import controller from 'assets/devices/joystick.png';
import smartTV from 'assets/devices/smartTV.png';

import smartphoneMobile from 'assets/devices/smartphone-mobile.png';
import tabletMobile from 'assets/devices/tablet-mobile.png';
import computerMobile from 'assets/devices/laptop-mobile.png';
import printerMobile from 'assets/devices/printer-mobile.png';
import smartwatchMobile from 'assets/devices/smartWatch-mobile.png';
import controllerMobile from 'assets/devices/joystick-mobile.png';
import smartTVMobile from 'assets/devices/smartTV-mobile.png';

const DEVICES = [
  {
    name: 'Smartphone',
    image: smartphone,
    imageMobile: smartphoneMobile,
    id: 1
  },
  {
    name: 'Computadora',
    image: computer,
    imageMobile: computerMobile,
    id: 2
  },
  {
    name: 'Impresora',
    image: printer,
    imageMobile: printerMobile,
    id: 3
  },
  {
    name: 'Consola',
    image: controller,
    imageMobile: controllerMobile,
    id: 4
  },
  {
    name: 'Smart TV',
    image: smartTV,
    imageMobile: smartTVMobile,
    id: 5
  },
  {
    name: 'Tablet',
    image: tablet,
    imageMobile: tabletMobile,
    id: 6
  },
  {
    name: 'Smartwatch',
    image: smartwatch,
    imageMobile: smartwatchMobile,
    id: 7
  }
];

export default DEVICES;
