import { get, post } from 'utilities/http';

const id_region = () => {
  return localStorage.getItem('region-id');
};

export const getProvincias = () => {
  return get(`/fixup/provincias/${id_region()}`);
};

export const getProvinciaByName = (provincia_name) => {
  return get(`/fixup/provinciaNombre/${id_region()}/${provincia_name}`);
};

export const getBarrios = (id_provincia) => {
  return get(`/fixup/localidadesProvincia/${id_region()}/${id_provincia}`);
};

export const getBrandsByDevice = (id_device) => {
  return get(`/fixup/marcasGrupoEquipo/${id_region()}/${id_device}`);
};

export const getDeviceById = (id_device) => {
  return get(`/fixup/grupoEquipo/${id_region()}/${id_device}`);
};

export const getDevices = () => {
  return get(`/fixup/grupoEquipos/${id_region()}`);
};

export const getModelsByBrandAndDevice = (device_id, brand_id) => {
  return get(`/fixup/modelosGrupoEquipoMarca/${id_region()}/${device_id}/${brand_id}`);
};

export const getColorByModel = (model_id) => {
  return get(`/fixup/coloresModelo/${id_region()}/${model_id}`);
};

export const getFlawsByDevice = (device_id) => {
  return get(`/fixup/fallasGrupoEquipo/${id_region()}/${device_id}`);
};

export const getQuestionsByFlaws = (flaws) => {
  return get(`/fixup/preguntasAdicionalesFallasGrupoEquiposSeleccionadas/${id_region()}/${flaws}`);
};

export const getDeviceByName = (device_name) => {
  return get(`/fixup/grupoEquiposUrl/${id_region()}/${device_name}`);
};

export const getBrandByName = (brand_name) => {
  return get(`/fixup/marcaUrl/${id_region()}/${brand_name}`);
};

export const getGeolocation = (altitude, longitude) => {
  return get(`/fixup/geolocalizacionCoordenadasGoogle/${id_region()}/${altitude}/${longitude}`);
};

export const postClientData = (data) => {
  const formData = new FormData();
  const file = data.file;
  formData.append('company_id', id_region());
  formData.append('nombre', data.clientData.nombre);
  formData.append('primer_nombre', data.clientData.nombre);
  formData.append('apellido', data.clientData.apellido);
  formData.append('email', data.clientData.email);
  formData.append('telefono', `${data.clientData.codigoPais} ${data.clientData.numeroTelefono}`);
  formData.append('localidad', data.clientData.barrio.toString());
  formData.append('provincia', data.clientData.provincia.toString());
  formData.append('grupo_equipo_id', data.deviceData.type.id || 0);
  formData.append('marca_id', data.deviceData.brand?.marca_id || data.deviceData.brand?.id || 0);
  formData.append('marca_input', data.deviceData.brand.marca_nombre || '');
  formData.append('modelo_id', data.deviceData.model || 1);
  formData.append('modelo_input', data.deviceData?.model_input || '');
  formData.append('color_id', data.deviceData.color_id ? 2 : 0);
  formData.append('color_input', data.deviceData.color?.color_nombre || '');
  formData.append('fallas_grupos_equipos', data.deviceData.flaws.map((flaw) => flaw.id) || []);
  formData.append('falla_input', null);
  formData.append('discountCode', data?.clientData?.code);
  file && formData.append('respuestas_adicionales_file', file);
  formData.append(
    'fallas_preguntas_adicionales_derivaciones',
    JSON.stringify(
      data.deviceData.answers.map((answer) => {
        return {
          id_pregunta: answer?.pregunta_adicional_id || 4,
          respuesta: {
            id_respuesta:
              answer.tipo_pregunta_id == 1
                ? answer?.answer?.respuesta_adicional_id
                : answer?.respuestas_adicionales[0]?.respuesta_adicional_id,
            input:
              typeof answer?.answer === 'string' && answer?.length
                ? answer?.answer
                : 'Texto de respuesta',
            file: answer?.campo_pregunta_id === 4 ? answer?.answer?.name : undefined
          }
        };
      })
    )
  );
  return post(
    '/fixup/insertDerivacion',
    formData,
    // {
    //   company_id: id_region(),
    //   nombre: data.clientData.nombre,
    //   primer_nombre: data.clientData.nombre,
    //   apellido: data.clientData.apellido,
    //   email: data.clientData.email,
    //   telefono: data.clientData.numeroTelefono,
    //   localidad: data.clientData.barrio.toString(),
    //   provincia: data.clientData.provincia.toString(),
    //   grupo_equipo_id: data.deviceData.type.id,
    //   marca_id: data.deviceData.brand.id,
    //   marca_input: data.deviceData.brand.marca_nombre,
    //   modelo_id: data.deviceData.model,
    //   modelo_input: data.deviceData?.model_input,
    //   color_id: data.deviceData.color?.id,
    //   color_input: data.deviceData.color?.color_nombre,
    //   fallas_grupos_equipos: data.deviceData.flaws.map((flaw) => flaw.id),
    //   falla_input: '',
    //   respuestas_adicionales_file: file,
    //   fallas_preguntas_adicionales_derivaciones: JSON.stringify(
    //     data.deviceData.answers.map((answer) => {
    //       return {
    //         id_pregunta: answer.id,
    //         respuesta: answer.answer?.respuesta_adicional_id,
    //         input: answer.answer?.respuesta,
    //         file: answer.tipo_pregunta_id === 0 ? answer.answer : undefined
    //       };
    //     })
    //   )
    // },
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const getSolucion = (id) => {
  return get(`/fixup/solucionFixup/${id_region()}/${id}`); //162575
};

export const getServiciosDisponibles = (id) => {
  return get(`/fixup/serviciosDisponiblesFixup/${id_region()}/${id}`);
};

export const getSucursalesYoLoLlevo = (id) => {
  return get(`/fixup/sucursalesYoLoLlevoDerivacionLocalizacion/${id_region()}/${id}`);
};

export const postUpdateDerivacion = (data) => {
  return post(`/fixup/updateDerivacion`, data);
};

export const postEndDerivacion = (data) => {
  return post(`/fixup/endDerivacion`, data);
};

export const getDiscount = (code) => {
  return get(`/fixup/descuento/${id_region()}/${code}`);
};
