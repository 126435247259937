import MobileHeader from './mobile-header/mobile-header';
import DesktopHeader from './desktop-header/desktop-header';
import { PageIncludedInList } from '../../utilities/utils';

const PagesWithoutHeader = ['/region'];

const Header = ({ mobileView }) => {
  return (
    !PageIncludedInList(window.location.pathname, PagesWithoutHeader) &&
    (mobileView ? <MobileHeader /> : <DesktopHeader />)
  );
};

export default Header;
