import loaderLottie from '../../lotties/loader-lottie.json';
import Lottie from 'react-lottie';

const LoaderComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={50} width={50} />;
};

export default LoaderComponent;
