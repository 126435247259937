import { Grid } from '@mui/material';
import { StyledSpan, StyledTitle } from 'components/common/styled-span';
import Theme from 'styles/theme';
import stories from './data';
import './stories-section.scss';
import { isMobile } from '../../../../utilities/utils';

const StoriesSection = () => {
  return (
    <div>
      <StyledTitle>Historias reales que parecen ficción</StyledTitle>
      <Grid container>
        {stories.map((story) => (
          <Grid container spacing={2} sx={{ marginTop: '8px', padding: '10px' }} key={story.id}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <iframe
                src={story.video}
                width="100%"
                height="300px"
                frameBorder="0"
                title="Nicol&amp;aacute;s.mp4"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div>
                <StyledSpan fontWeight="regular" fontSize={20} color={Theme.palette.primary.main}>
                  {story.title}
                </StyledSpan>
              </div>
              <div>
                <StyledSpan fontWeight="bold" fontSize={12} color={Theme.palette.primary.main}>
                  {story.description}
                </StyledSpan>
              </div>
              {!isMobile() && (
                <div style={{ whiteSpace: 'pre-line', marginTop: '20px' }}>
                  <StyledSpan
                    fontWeight={400}
                    fontSize={12}
                    color={Theme.palette.primary.main}
                    style={{ whiteSpace: 'pre-line' }}>
                    {story.text}
                  </StyledSpan>
                </div>
              )}
            </Grid>
            <hr style={{ width: '100%', margin: '16px', color: Theme.palette.primary.main }} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StoriesSection;
