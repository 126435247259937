import { Autocomplete, TextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import theme from '../../styles/theme';

const CustomAutoComplete = ({
  options,
  onChange,
  value,
  // labelField,
  // valueField,
  label,
  setSelectedModel,
  // setSearchWord,
  // setInput,
  ...props
}) => {
  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter((option) =>
      option.nombre.toLowerCase().includes(inputValue.toLowerCase())
    );

    const isExisting = options.some((option) => inputValue === option.nombre);
    const isInputValueNotEmpty = inputValue.trim() !== '';
    if (isInputValueNotEmpty && !isExisting) {
      // Agrega la nueva opción creada al principio del array
      filtered.push({
        inputValue,
        nombre: `${inputValue}`
      });
    }

    return filtered;
  };

  return (
    <StyledAutocomplete
      {...props}
      options={options || []}
      value={value}
      ListboxProps={{
        sx: {
          fontSize: 17,
          color: theme.palette.primary.main,
          fontWeight: 400,
          fontFamily: 'Inter, sans-serif',
          backgroundColor: '#ffffff',
          height: 'max-content',
          padding: 0
        }
      }}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setSelectedModel(newValue);
          const selectedOption = options.find((option) => option.nombre === newValue);
          if (selectedOption) {
            // Realiza acciones específicas para la opción existente
            // Puedes agregar más lógica aquí según tus necesidades
            onChange(newValue);
          }
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setSelectedModel(newValue);
          onChange(newValue);
        } else {
          setSelectedModel(newValue);
          onChange(newValue);
        }
      }}
      filterOptions={filterOptions}
      selectOnFocus
      handleHomeEndKeys
      clearOnBlur
      id="free-solo-with-text-demo"
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option?.nombre;
      }}
      freeSolo
      renderOption={(props, option) => <li {...props}>{option?.nombre}</li>}
      renderInput={(params) => (
        <StyledTextField color="lightblue" value={value ?? ''} {...params} placeholder={label} />
      )}
    />
  );
};

export default CustomAutoComplete;

export const StyledTextField = muiStyled(TextField)(() => {
  return {
    backgroundColor: 'white',
    borderRadius: 12,
    width: '100%'
  };
});

const StyledAutocomplete = muiStyled(Autocomplete)(() => {
  return {
    borderRadius: 28,
    width: '100%',
    border: 'none',
    display: 'flex',
    flexGrow: 1
  };
});
