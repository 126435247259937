const stories = [
  {
    id: 1,
    title: 'Nicolás nos cuenta su historia',
    description: 'Reparó su iPhone X',
    text: `A Nicolás le pasó lo que todos tememos: se le cayó su celular como tantas otras veces, pero al momento de levantarlo, no respondía la pantalla.\n Cotizó su reparación en FixUP! y solicitó el retiro a domicilio. En tiempo record, volvió a tener su equipo como nuevo y sin siquiera moverse de su casa`,
    video: 'https://player.vimeo.com/video/767901395'
  },
  {
    id: 2,
    title: 'The “notebook” is under the table',
    description: 'Alejandra reparó su Notebook',
    text: `Alejandra es profe de Ingles y se conecta con sus alumnos a través de internet. Pero lo que dejó de conectar es su computadora. De repente dejó de funcionar la carga y ya no pudo usarla.\n La alegría de los alumnos que no recibieron tarea esa semana duró poco. Muy rápidamente contrató el servicio de FixUP! y tuvo su equipo como nuevo en menos de una semana.`,
    video: 'https://player.vimeo.com/video/767902646'
  },
  {
    id: 3,
    title: '“El celular” VS “El piso”',
    description: 'Natasha reparó su Smartphone',
    text: `Natasha se contactó a través del programa de referidos, luego de que su celular estalló contra el piso.\n Contrató online el servicio de cambio de pantalla y lo acercó a un punto FixUP! a unas cuadras de su domicilio.\n En 2 días volvió a tener su equipo como nuevo y con 100 días de garantía.`,
    video: 'https://player.vimeo.com/video/767902268'
  }
];

export default stories;
