import {
  GET_FIX_UP_IN_MEDIA_REQUEST,
  GET_INVESTORS_REQUEST,
  GET_OFFICIAL_BRANDS_REQUEST,
  GET_PARTNERS_REQUEST,
  GET_PROMOTIONAL_BANNER_REQUEST,
  GET_REVIEWS_REQUEST,
  GET_SEARCH_MODELS_REQUEST,
  GET_SUCURSALES_CERCANAS_REQUEST,
  GET_TOP_BANNER_REQUEST,
  GET_FAQ_REQUEST,
  GET_FAQ_PATH_REQUEST
} from 'redux/actions/common.actions';
import commonActions from 'redux/actions/common.actions';
import {
  getFixUpInMedia,
  getInvestors,
  getModelsByText,
  getOfficialBrands,
  getPartners,
  getPromotionalBanner,
  getReviews,
  getSucursalesCercanas,
  getTopBanner,
  getFAQ,
  getFAQPath
} from 'services/common.service';

const CommonMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case GET_REVIEWS_REQUEST:
        getReviews()
          .then((res) => {
            dispatch(commonActions.getReviews.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getReviews.error(err));
          });
        break;
      case GET_OFFICIAL_BRANDS_REQUEST:
        getOfficialBrands()
          .then((res) => {
            dispatch(commonActions.getOfficialBrands.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getOfficialBrands.error(err));
          });
        break;
      case GET_FIX_UP_IN_MEDIA_REQUEST:
        getFixUpInMedia()
          .then((res) => {
            dispatch(commonActions.getFixUpInMedia.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getFixUpInMedia.error(err));
          });
        break;
      case GET_SUCURSALES_CERCANAS_REQUEST:
        getSucursalesCercanas()
          .then((res) => {
            dispatch(commonActions.getSucursalesCercanas.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getSucursalesCercanas.error(err));
          });
        break;
      case GET_SEARCH_MODELS_REQUEST:
        getModelsByText(action.text)
          .then((res) => {
            dispatch(commonActions.getSearchModels.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getSearchModels.error(err));
          });
        break;
      case GET_TOP_BANNER_REQUEST:
        getTopBanner(action.path)
          .then((res) => {
            dispatch(commonActions.getTopBanner.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getTopBanner.error(err));
          });
        break;
      case GET_PARTNERS_REQUEST:
        getPartners()
          .then((res) => {
            dispatch(commonActions.getPartners.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getPartners.error(err));
          });
        break;
      case GET_INVESTORS_REQUEST:
        getInvestors()
          .then((res) => {
            dispatch(commonActions.getInvestors.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getInvestors.error(err));
          });
        break;
      case GET_PROMOTIONAL_BANNER_REQUEST:
        getPromotionalBanner(action.path)
          .then((res) => {
            dispatch(commonActions.getPromotionalBanner.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getPromotionalBanner.error(err));
          });
        break;
      case GET_FAQ_REQUEST:
        getFAQ(action.path)
          .then((res) => {
            dispatch(commonActions.getFAQ.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getFAQ.error(err));
          });
        break;
      case GET_FAQ_PATH_REQUEST:
        getFAQPath(action.path)
          .then((res) => {
            dispatch(commonActions.getFAQPath.response(res.data));
          })
          .catch((err) => {
            dispatch(commonActions.getFAQPath.error(err));
          });
        break;
      default:
        break;
    }
  };

export default CommonMiddleware;
