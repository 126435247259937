import DeviceCard from '../../../device-card/device-card';
import { Grid } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import pricingActions from 'redux/actions/pricing.actions';

const PricingMenuDropdown = ({ closeMenuPricing }) => {
  const devices = useSelector((state) => state.pricing.devices);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const selectDevice = (device) => {
    navigate(`/reparar/${device?.url_amigable}`, { state: { deviceState: device } });
    closeMenuPricing();
  };

  useEffect(() => {
    if (!devices?.length) {
      dispatch(pricingActions.getDevices.request());
    }
  }, []);
  return (
    <MenuContainer onMouseLeave={closeMenuPricing}>
      <Grid container spacing={1}>
        {devices.map((device) => (
          <Grid key={device.id} item md="auto" onClick={() => selectDevice(device)}>
            <DeviceCard device={device} />
          </Grid>
        ))}
      </Grid>
    </MenuContainer>
  );
};

export default PricingMenuDropdown;

const MenuContainer = muiStyled('div')(() => ({
  width: '550px',
  padding: '20px'
}));
