import React, { useState } from 'react';

function CustomInputMask({ mask, onAccept, placeholder, value, className }) {
  const [formattedValue, setFormattedValue] = useState(value || '');

  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ''); // Elimina cualquier carácter no numérico
    let formattedInput = '';

    let inputIndex = 0;
    if (inputValue) {
      for (let maskIndex = 0; maskIndex < mask?.length; maskIndex++) {
        if (/\d/.test(mask[maskIndex])) {
          if (inputValue[inputIndex] === undefined) {
            break; // Detectamos que hemos llegado al final de la entrada, por lo que no añadimos más caracteres.
          }
          formattedInput += inputValue[inputIndex];
          inputIndex++;
        } else {
          if (inputValue[inputIndex] === undefined) {
            break; // Detectamos que hemos llegado al final de la entrada, por lo que no añadimos más caracteres de la máscara.
          }
          formattedInput += mask[maskIndex];
        }
      }
    } else {
      setFormattedValue(formattedInput);
      return;
    }

    setFormattedValue(formattedInput);
    onAccept(formattedInput);
  };

  return (
    <input
      type="text"
      inputMode="numeric"
      value={formattedValue}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
    />
  );
}

export default CustomInputMask;
