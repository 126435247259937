import { styled as muiStyled } from '@mui/material/styles';
import { useEffect } from 'react';

export const PageContainer = muiStyled('div')(({ theme, ...props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    display: 'flex',
    gap: '30px',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 620px)',
    [theme.breakpoints.down('sm')]: {
      margin: !props.disableMarginTop ? '5px 0 15px 0' : '0px 0 15px 0'
    },
    [theme.breakpoints.up('sm')]: {
      margin: !props.disableMarginTop ? '40px 0 40px 0' : '0px 0px 40px 0px'
    },
    [theme.breakpoints.up('md')]: {
      gap: '105px',
      marginTop: props.marginTop ?? (!props.disableMarginTop && '100px')
    }
  };
});
