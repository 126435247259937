import { Accordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../common/styled-span';
import { useState } from 'react';

const AccordionQuestions = ({ FAQ }) => {
  const [expanded, setExpanded] = useState(false);
  const { referencia, repuesta } = FAQ;
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const response = { __html: repuesta };
  const reference = { __html: referencia };

  return (
    <StyledAccordion disableGutters expanded={expanded} onChange={handleChange(!expanded)}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledSpan fontSize={14} fontWeight={expanded ? 'bold' : 400}>
          <div dangerouslySetInnerHTML={reference}></div>
        </StyledSpan>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <div dangerouslySetInnerHTML={response}></div>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default AccordionQuestions;

const StyledAccordion = muiStyled(Accordion)(() => {
  return {
    // maxWidth: '600px'
  };
});

const StyledAccordionDetails = muiStyled(AccordionDetails)(() => {
  return {
    backgroundColor: 'rgba(107,107,107,0.06)',
    borderTop: '1px solid gray',
    padding: '20px'
  };
});

const StyledAccordionSummary = muiStyled(AccordionSummary)(() => {
  return {
    padding: '10px 20px 10px 20px'
  };
});
