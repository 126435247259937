import { PageContainer } from '../../components/common/styled-page-container';
import { styled as muiStyled } from '@mui/material/styles';
import './PrivacidadPage.scss';

const PrivacidadPage = () => {
  return (
    <PageContainer>
      <div className="section">
        <h2 className="headng1">Política de privacidad</h2>
        <div className="div-block-67">
          <div className="text-privacidad">
            <strong className="subheading">POLITICAS DE PRIVACIDAD</strong>
            <br />
            <br />
            Wabrit S.A.S (en adelante FixUP!) informa a los usuarios y a los profesionales del Sitio
            acerca de su política de protección de datos de carácter personal (en adelante, los
            Datos Personales) para que éstos determinen libre y voluntariamente si desean facilitar
            a FixUP! los Datos Personales que se les puedan requerir o que se puedan obtener de los
            Usuarios con ocasión del registro o realización de los servicios.FixUP! se reserva el
            derecho a modificar la presente política para adaptarla a novedades legislativas o
            jurisprudenciales, así como a las mejores prácticas de la industria. En dichos
            supuestos, FixUP! anunciará en esta página las futuras modificaciones.La presente
            Política de Privacidad se integra con los Términos y Condiciones disponibles en{' '}
            <a href="/terminos-condiciones">terminos-condiciones</a> y se aplicará a los Usuarios
            del Sitio, estén o no debidamente registrados. Las definiciones utilizadas en los
            Términos y Condiciones subsistirán y serán aplicables en las presente Políticas de
            Privacidad.
          </div>
          <div className="text-privacidad">
            <strong className="subheading">
              RECOLECCIÓN DE INFORMACIÓN DE LOS USUARIOS
              <br />
              <br />‍
            </strong>
            El Usuario reconoce y acepta que FixUP! pueda recolectar información acerca de los
            Usuarios al momento de utilizar el Sitio, tales como su nombre, apellido, teléfono,
            email, entre otros (en adelante los “Datos”). Los Datos serán almacenados en una base de
            datos de propiedad de FixUP! y será tratada en forma confidencial y con las debidas
            medidas de seguridad que se detallarán más adelante
          </div>
          <div className="text-privacidad">
            <strong className="subheading">FINALIDAD DE LOS DATOS</strong>
            <br />
            <br />
            Los Datos suministrados por el Usuario y recolectados de conformidad con lo establecido
            en las presentes Políticas de Privacidad serán utilizados con la siguiente finalidad:el
            posible proceso de contratación On-Line de los servicios;ofrecer a los Usuarios,
            servicios, Productos y promociones adaptadas a sus preferencias personales (mediante
            previa aprobación del Usuario);permitir el envío de e-mails directamente relacionados a
            los intereses (mediante previa aprobación del Usuario);cumplir con disposiciones
            legales;cumplir algún procedimiento legal, inclusive orden judicial de cualquier otro
            órgano regulatorio competente;proteger el derecho de FixUP!; ocuando haya sido
            expresamente autorizado el uso de los datos personales por el mismo
            Usuario.Adicionalmente, la información que provea el Usuario y que FixUP! recolecte
            directamente en nombre de dicho Usuario, podrá ser utilizada por FixUP! para el
            intercambio de datos entre FixUP! y sus proveedores de servicio a fin de de brindar los
            Servicios con una mayor eficacia.FixUP! utilizará los Datos provistos por el Usuario, y
            la recolectada por FixUP! conforme a lo establecido en las Políticas de Privacidad, y no
            la divulgará salvo que sea solicitada por tribunales, u organismos estatales nacionales
            o internacionales que así lo requieran y lo soliciten en la forma correspondiente.FixUP!
            no estará obligado a retener la información durante ningún plazo establecido y dispondrá
            la eliminación de la misma cuando lo juzgue conveniente.FixUP! podrá contener enlaces a
            otros sitios de internet que no sean propiedad de FixUP!. En consecuencia FixUP! no será
            responsable por el actuar de dichos sitios de internet, a los cuales no se aplicará la
            presente Política de Privacidad. Recomendamos examinar la política de privacidad
            detallada en aquellos sitios de internet para entender los procedimientos de recolección
            de información que utilizan y como protegen sus datos personales.FixUP! es propietaria
            de todas las bases de datos y dispositivos de almacenamiento, así como también del
            software que desarrolla. Sin embargo, FixUP! no es dueña de la información que los
            Usuarios almacenan en su Sitio y/o que reciben como parte los servicios. Los Usuarios
            tienen todos los derechos sobre dicha información.
          </div>
          <div className="text-privacidad">
            <strong className="sub">MENORES DE EDAD</strong> <br />
            <br />
            El Sitio y/o los Servicios están permitidos sólo a quienes tengan edad legal para
            contratar y no se encuentren inhibidos legalmente o de algún modo vedados de ejercer
            actos jurídicos, derechos y/u obligaciones. Habida cuenta de ello, los menores de 18
            años no tienen permitido el ingreso al Sitio y/o los Servicios ni el suministro de
            ningún dato personal u otro tipo de información.
          </div>
          <div className="text-privacidad">
            <strong className="subheading">CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACIÓN</strong>
            <br />
            <br />
            FixUP! ha adoptado medidas de seguridad razonables para proteger la información de los
            Usuarios, e impedir el acceso no autorizado a sus datos o cualquier modificación,
            divulgación o destrucción no autorizada de los mismos. La información recolectada por
            FixUP! será mantenida de manera estrictamente confidencial. El acceso a los datos
            personales está restringido a aquellos empleados, contratistas y representantes de
            FixUP! que necesitan conocer tales datos para desempeñar sus funciones y desarrollar o
            mejorar nuestros servicios. La Empresa exige a sus proveedores los mismos estándares de
            confidencialidad. La Empresa no permite el acceso a esta información a terceros ajenos a
            FixUP!, a excepción de un pedido expreso del Usuario.La seguridad de los datos de los
            Usuarios es importante para FixUP!. FixUP! utiliza software específico para proteger los
            datos brindados por los Usuarios y la información recabada por FixUP!. Tanto el sistema
            operativo del servidor como el software de almacenamiento de la base de datos se
            actualizan periódicamente con los parches de seguridad asociados.Asimismo, todas las
            conexiones al Sitio son encriptadas (HTTPS).
          </div>
          <div className="text-privacidad">
            <strong className="subheading">CESION DE LOS DATOS</strong>
            <br />
            <br />
            FixUP! no venderá, alquilará ni compartirá los Datos de los Usuarios salvo aquellos
            casos expresamente previstos en las Políticas de Privacidad. No obstante ello, el
            Usuario presta su expresa conformidad para que FixUP! transfiera total o parcialmente
            los datos del Usuario a cualquiera de sus sociedades controladas, controlantes y/o
            vinculadas con FixUP!, como así también a los Proveedores de servicio.Los proveedores de
            servicio no tendrán acceso a la base de datos. No obstante ello, a fin de poder brindar
            los Servicios, tendrán acceso a los datos de los Usuarios que hubieran llevado a cabo el
            proceso de contratación de sus servicios de conformidad con lo previsto en los Términos
            y Condiciones
          </div>
          <div className="text-privacidad">
            <strong className="sub">COOKIES</strong> <br />
            <br />
            El Usuario reconoce y acepta que el Sitio podrá utilizar cookies para brindar a los
            Usuarios un servicio más completo, recordando sus preferencias. La información que
            recopile FixUP! podrá incluir el comportamiento de navegación, dirección IP, logs, y
            otros tipos de información. Sin embargo, FixUP! no recolectará información personal
            identificable de manera directa de ningún Usuario usando cookies o tags. Un cookie es un
            pequeño archivo de texto mediante el cual se puede identificar en forma exclusiva un
            navegador de internet, y que suele ser instalado, enviado o escrito en el ordenador de
            los Usuarios de internet, cuando los mismos ingresan a determinados sitios de internet,
            a efectos de obtener automáticamente información acerca de la conducta de los Usuarios
            cuando navegan por dichos sitios de internet. Un archivo cookie generalmente contendrá
            el nombre del dominio desde donde la cookie ha sido transferida, la fecha de creación, y
            un código de identificación. La única información personal que un cookie puede contener
            es información que el Usuario mismo suministra con su navegación por internet, tales
            como la dirección de IP, las páginas visitadas y en qué orden, el tiempo de permanencia
            en cada página, la fecha y la hora, etc. (la “Información de Actividad”). Un cookie no
            puede leer datos del disco duro del Usuario ni leer los archivos cookie creados por
            otros sitios de internetLa mayoría de los navegadores están configurados para aceptar
            cookies, pero los Usuarios podrán reconfigurar su navegador de internet para rechazar
            todas las cookies o para que el sistema le indique en qué momento se envía una. Sin
            embargo, si las cookies están inhabilitadas, es posible que algunas características y
            servicios de los sitios de internet no funcionen de manera adecuada.
          </div>
          <div className="text-privacidad">
            <strong className="subheading">
              DERECHO AL ACCESO, CANCELACION Y RECTIFICACION DE LOS DATOS
            </strong>
            <br />
            <br />
            De acuerdo a los prescripto por la Ley nº 25.326, los usuarios tendrán derecho a hacer
            uso responsable de su cuenta en el Sitio. En cualquier momento, el Usuario podrá
            solicitar la baja como Usuario y la eliminación de su información de la base de datos de
            FixUP!, como así también acceder y actualizar a sus datos personales. El titular de los
            datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma
            gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés
            legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº
            25.326 de la República Argentina. La autoridad de aplicación (en Argentina, la Dirección
            Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326),
            tiene la atribución de atender las denuncias y reclamos que se interpongan con relación
            al incumplimiento de las Normas sobre protección de datos personales.A dichos efectos,
            el Usuario deberá enviar su Solicitud mediante el envío de un correo electrónico con el
            asunto “Acceso a Datos Personales” a contacto@fixupweb.com. La Empresa podrá requerir a
            dicho Usuario que se identifique, lo que podrá ser verificado por FixUP!, así como que
            precise los datos personales a los cuales desean acceder, rectificar o remover.
          </div>
          <div className="text-privacidad">
            <strong className="subheading">CONTACTO</strong> <br />
            <br />
            En caso que el Usuario tenga alguna duda acerca de la Política de Privacidad, o sobre la
            aplicación de la misma, deberá ponerse en contacto con FixUP!, en cualquier momento,{' '}
            <br />
            vía correo electrónico a{' '}
            <a href="mailto:contacto@fixupweb.com">contacto@fixupweb.com</a> Wabrit S.A.S, CUIT
            30-71662061-8, CON DOMICILIO EN AV. CABILDO 759 OF. 5, CIUDAD AUTÓNOMA DE BUENOS AIRES,
            ARGENTINA.
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default PrivacidadPage;

export const PageContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 25
  };
});
