import { StyledTitle } from '../common/styled-span';
import PartnerCard from '../partners/partner-card/partner-card';
import { styled as muiStyled } from '@mui/material/styles';
import StyledSection from '../common/styled-section';
import { fullImageURL } from '../../utilities/utils';
import { Grid } from '@mui/material';

const InvestorsSection = ({ investors }) => {
  return (
    <StyledSection>
      <ComponentContainer>
        <StyledTitle>Main investors</StyledTitle>
        {investors && (
          <Grid container spacing={3} justifyContent={'center'}>
            {investors.map((investor) => (
              <Grid key={investor.id} item xs="auto">
                <PartnerCard image={fullImageURL(investor.imagen)} />
              </Grid>
            ))}
          </Grid>
        )}
      </ComponentContainer>
    </StyledSection>
  );
};

export default InvestorsSection;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  };
});
