import {
  CHOOSE_DATE,
  CHOOSE_HOUR,
  CHOOSE_SERVICE,
  CHOOSE_SUCURSAL,
  GET_BARRIOS_ERROR,
  GET_BARRIOS_REQUEST,
  GET_BARRIOS_RESPONSE,
  GET_BRAND_BY_DEVICE_ERROR,
  GET_BRAND_BY_DEVICE_REQUEST,
  GET_BRAND_BY_DEVICE_RESPONSE,
  GET_BRAND_BY_NAME_ERROR,
  GET_BRAND_BY_NAME_REQUEST,
  GET_BRAND_BY_NAME_RESPONSE,
  GET_COLORS_BY_MODEL_ERROR,
  GET_COLORS_BY_MODEL_REQUEST,
  GET_COLORS_BY_MODEL_RESPONSE,
  GET_DEVICE_BY_NAME_ERROR,
  GET_DEVICE_BY_NAME_REQUEST,
  GET_DEVICE_BY_NAME_RESPONSE,
  GET_DEVICES_ERROR,
  GET_DEVICES_REQUEST,
  GET_DEVICES_RESPONSE,
  GET_FLAWS_BY_DEVICE_ERROR,
  GET_FLAWS_BY_DEVICE_REQUEST,
  GET_FLAWS_BY_DEVICE_RESPONSE,
  GET_MODELS_BY_BRAND_AND_DEVICE_ERROR,
  GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST,
  GET_MODELS_BY_BRAND_AND_DEVICE_RESPONSE,
  GET_PROVINCIAS_ERROR,
  GET_PROVINCIAS_REQUEST,
  GET_PROVINCIAS_RESPONSE,
  GET_QUESTIONS_BY_FLAWS_ERROR,
  GET_QUESTIONS_BY_FLAWS_REQUEST,
  GET_QUESTIONS_BY_FLAWS_RESPONSE,
  GET_SOLUCION_ERROR,
  GET_SOLUCION_REQUEST,
  GET_SOLUCION_RESPONSE,
  MODIFY_DEVICE,
  POST_CLIENT_ERROR,
  POST_CLIENT_REQUEST,
  POST_CLIENT_RESPONSE,
  UPDATE_ANSWERS,
  UPDATE_CLIENT,
  UPDATE_FLAW,
  GET_SERVICIOS_DISPONIBLES_ERROR,
  GET_SERVICIOS_DISPONIBLES_REQUEST,
  GET_SERVICIOS_DISPONIBLES_RESPONSE,
  CHOOSE_SOLUCION,
  GET_SUCURSALES_YO_LO_LLEVO_ERROR,
  GET_SUCURSALES_YO_LO_LLEVO_RESPONSE,
  GET_SUCURSALES_YO_LO_LLEVO_REQUEST,
  POST_UPDATE_DERIVACION_ERROR,
  POST_UPDATE_DERIVACION_REQUEST,
  POST_UPDATE_DERIVACION_RESPONSE,
  POST_END_DERIVACION_ERROR,
  POST_END_DERIVACION_REQUEST,
  POST_END_DERIVACION_RESPONSE,
  GET_DISCOUNT_RESPONSE,
  GET_DISCOUNT_REQUEST,
  GET_DISCOUNT_ERROR,
  RESET_STATE
} from '../actions/pricing.actions';
import STATUS from '../../constants/status';

const initialState = {
  device: {
    type: null,
    brand: null,
    color: null,
    flaws: [],
    model: null,
    answers: []
  },
  client: {
    nombre: '',
    apellido: '',
    email: '',
    codigoPais: `+${JSON.parse(localStorage.getItem('region-area-code'))} 9`,
    numeroTelefono: '',
    provincia: '',
    barrio: '',
    code: null
  },
  service: {
    type: null,
    sucursal: null,
    date: null,
    hour: null
  },
  selectedSolucion: null,
  provincias: [],
  barrios: [],
  devices: [],
  brands: [],
  models: [],
  colors: [],
  flaws: [],
  deviceByName: [],
  brandByName: [],
  questions: [],
  serviciosDisponibles: [],
  sucursalesYoLoLlevo: [],
  updateDerivacion: null,
  endDerivacion: null,
  solucion: null,
  getSolucionStatus: STATUS.idle,
  postClientDataStatus: STATUS.idle,
  getProvinciasStatus: STATUS.idle,
  getBarriosStatus: STATUS.idle,
  getDevicesStatus: STATUS.idle,
  getBrandsStatus: STATUS.idle,
  getModelsStatus: STATUS.idle,
  getColorsStatus: STATUS.idle,
  getFlawsStatus: STATUS.idle,
  getDeviceByNameStatus: STATUS.idle,
  getBrandByNameStatus: STATUS.idle,
  getQuestionsStatus: STATUS.idle,
  getServiciosDisponiblesStatus: STATUS.idle,
  getSucursalesYoLoLlevoStatus: STATUS.idle,
  postEndDerivacionStatus: STATUS.idle,
  postUpdateDerivacionStatus: STATUS.idle,
  getDiscountStatus: STATUS.idle
};

const pricingReducer = (state = initialState, action) => {
  let newDevice = state.device;
  let newService = state.service;
  switch (action.type) {
    case MODIFY_DEVICE:
      newDevice[action.key] = action.data;
      return {
        ...state,
        device: newDevice
      };
    case UPDATE_FLAW:
      if (state.device.flaws.some((flaw) => flaw.id === action.key.id)) {
        newDevice.flaws = state.device.flaws.filter((flaw) => flaw.id !== action.key.id);
      } else {
        newDevice.flaws = [...newDevice.flaws, action.key];
      }
      return {
        ...state,
        device: newDevice
      };
    case UPDATE_ANSWERS:
      if (action.answer === 'CLEAR') {
        newDevice.answers = [];
      } else {
        newDevice.answers = [...newDevice.answers, action.answer];
      }
      return {
        ...state,
        device: newDevice
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        client: action.data
      };
    case CHOOSE_SERVICE:
      newService.type = action.service;
      return {
        ...state,
        service: newService
      };
    case CHOOSE_SUCURSAL:
      newService.sucursal = action.sucursal;
      return {
        ...state,
        service: newService
      };
    case CHOOSE_DATE:
      newService.date = action.date;
      return {
        ...state,
        service: newService
      };
    case CHOOSE_HOUR:
      newService.hour = action.hour;
      return {
        ...state,
        service: newService
      };
    case GET_PROVINCIAS_REQUEST:
      return {
        ...state,
        getProvinciasStatus: STATUS.loading
      };
    case GET_PROVINCIAS_ERROR:
      return {
        ...state,
        getProvinciasStatus: STATUS.error
      };
    case GET_PROVINCIAS_RESPONSE:
      return {
        ...state,
        getProvinciasStatus: STATUS.success,
        provincias: action.res
      };
    case GET_BARRIOS_REQUEST:
      return {
        ...state,
        getBarriosStatus: STATUS.loading
      };
    case GET_BARRIOS_ERROR:
      return {
        ...state,
        getBarriosStatus: STATUS.error
      };
    case GET_BARRIOS_RESPONSE:
      return {
        ...state,
        getBarriosStatus: STATUS.success,
        barrios: action.res
      };
    case GET_DEVICES_REQUEST:
      return {
        ...state,
        getDevicesStatus: STATUS.loading
      };
    case GET_DEVICES_ERROR:
      return {
        ...state,
        getDevicesStatus: STATUS.error
      };
    case GET_DEVICES_RESPONSE:
      return {
        ...state,
        getDevicesStatus: STATUS.success,
        devices: action.res
      };
    case GET_BRAND_BY_DEVICE_REQUEST:
      return {
        ...state,
        getBrandsStatus: STATUS.loading
      };
    case GET_BRAND_BY_DEVICE_ERROR:
      return {
        ...state,
        getBrandsStatus: STATUS.error
      };
    case GET_BRAND_BY_DEVICE_RESPONSE:
      return {
        ...state,
        getBrandsStatus: STATUS.success,
        brands: action.res
      };
    case GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST:
      return {
        ...state,
        getModelsStatus: STATUS.loading
      };
    case GET_MODELS_BY_BRAND_AND_DEVICE_ERROR:
      return {
        ...state,
        getModelsStatus: STATUS.error
      };
    case GET_MODELS_BY_BRAND_AND_DEVICE_RESPONSE:
      return {
        ...state,
        getModelsStatus: STATUS.success,
        models: action.res
      };
    case GET_COLORS_BY_MODEL_REQUEST:
      return {
        ...state,
        getColorsStatus: STATUS.loading
      };
    case GET_COLORS_BY_MODEL_ERROR:
      return {
        ...state,
        getColorsStatus: STATUS.error
      };
    case GET_COLORS_BY_MODEL_RESPONSE:
      return {
        ...state,
        getColorsStatus: STATUS.success,
        colors: action.res
      };
    case GET_FLAWS_BY_DEVICE_REQUEST:
      return {
        ...state,
        getFlawsStatus: STATUS.loading
      };
    case GET_FLAWS_BY_DEVICE_ERROR:
      return {
        ...state,
        getFlawsStatus: STATUS.error
      };
    case GET_FLAWS_BY_DEVICE_RESPONSE:
      return {
        ...state,
        getFlawsStatus: STATUS.success,
        flaws: action.res
      };
    case GET_DEVICE_BY_NAME_REQUEST:
      return {
        ...state,
        getDeviceByNameStatus: STATUS.loading
      };
    case GET_DEVICE_BY_NAME_ERROR:
      return {
        ...state,
        getDeviceByNameStatus: STATUS.error
      };
    case GET_DEVICE_BY_NAME_RESPONSE:
      newDevice.type = action.res;
      return {
        ...state,
        getDeviceByNameStatus: STATUS.success,
        device: newDevice
      };
    case GET_BRAND_BY_NAME_REQUEST:
      return {
        ...state,
        getBrandByNameStatus: STATUS.loading
      };
    case GET_BRAND_BY_NAME_ERROR:
      return {
        ...state,
        getBrandByNameStatus: STATUS.error
      };
    case GET_BRAND_BY_NAME_RESPONSE:
      return {
        ...state,
        getBrandByNameStatus: STATUS.success,
        brandByName: action.res
      };
    case GET_QUESTIONS_BY_FLAWS_REQUEST:
      return {
        ...state,
        getQuestionsStatus: STATUS.loading
      };
    case GET_QUESTIONS_BY_FLAWS_ERROR:
      return {
        ...state,
        getQuestionsStatus: STATUS.error
      };
    case GET_QUESTIONS_BY_FLAWS_RESPONSE:
      return {
        ...state,
        getQuestionsStatus: STATUS.success,
        questions: action.res
      };
    case POST_CLIENT_REQUEST:
      return {
        ...state,
        postClientDataStatus: STATUS.loading
      };
    case POST_CLIENT_RESPONSE:
      return {
        ...state,
        postClientDataStatus: STATUS.success,
        derivacion_id: action.res.id
      };
    case POST_CLIENT_ERROR:
      return {
        ...state,
        postClientDataStatus: STATUS.error
      };
    case GET_SOLUCION_REQUEST: {
      return {
        ...state,
        getSolucionStatus: STATUS.loading,
        solucion: null
      };
    }
    case GET_SOLUCION_RESPONSE: {
      return {
        ...state,
        getSolucionStatus: STATUS.success,
        solucion: action.res
      };
    }
    case GET_SOLUCION_ERROR: {
      return {
        ...state,
        getSolucionStatus: STATUS.error
      };
    }
    case GET_SERVICIOS_DISPONIBLES_REQUEST: {
      return {
        ...state,
        getServiciosDisponiblesStatus: STATUS.loading,
        serviciosDisponibles: []
      };
    }
    case GET_SERVICIOS_DISPONIBLES_RESPONSE: {
      return {
        ...state,
        getServiciosDisponiblesStatus: STATUS.success,
        serviciosDisponibles: action.res
      };
    }
    case GET_SERVICIOS_DISPONIBLES_ERROR: {
      return {
        ...state,
        getServiciosDisponiblesStatus: STATUS.error
      };
    }
    case CHOOSE_SOLUCION:
      return {
        ...state,
        selectedSolucion: action.solucion
      };
    case GET_SUCURSALES_YO_LO_LLEVO_REQUEST: {
      return {
        ...state,
        getSucursalesYoLoLlevoStatus: STATUS.loading,
        sucursalesYoLoLlevo: []
      };
    }
    case GET_SUCURSALES_YO_LO_LLEVO_RESPONSE: {
      return {
        ...state,
        getSucursalesYoLoLlevoStatus: STATUS.success,
        sucursalesYoLoLlevo: action.res
      };
    }
    case GET_SUCURSALES_YO_LO_LLEVO_ERROR: {
      return {
        ...state,
        getSucursalesYoLoLlevoStatus: STATUS.error
      };
    }
    case POST_UPDATE_DERIVACION_REQUEST: {
      return {
        ...state,
        postUpdateDerivacionStatus: STATUS.loading,
        updateDerivacion: null
      };
    }
    case POST_UPDATE_DERIVACION_RESPONSE: {
      return {
        ...state,
        postUpdateDerivacionStatus: STATUS.success,
        updateDerivacion: action.res
      };
    }
    case POST_UPDATE_DERIVACION_ERROR: {
      return {
        ...state,
        postUpdateDerivacionStatus: STATUS.error
      };
    }
    case POST_END_DERIVACION_REQUEST: {
      return {
        ...state,
        postEndDerivacionStatus: STATUS.loading,
        endDerivacion: null
      };
    }
    case POST_END_DERIVACION_RESPONSE: {
      return {
        ...state,
        postEndDerivacionStatus: STATUS.success,
        endDerivacion: action.res
      };
    }
    case POST_END_DERIVACION_ERROR: {
      return {
        ...state,
        postEndDerivacionStatus: STATUS.error
      };
    }
    case GET_DISCOUNT_RESPONSE: {
      return {
        ...state,
        getDiscountStatus: STATUS.success
      };
    }
    case GET_DISCOUNT_ERROR: {
      return {
        ...state,
        getDiscountStatus: STATUS.error
      };
    }
    case GET_DISCOUNT_REQUEST: {
      return {
        ...state,
        getDiscountStatus: STATUS.loading
      };
    }
    case RESET_STATE:
      return {
        ...state,
        device: { ...state.device, flaws: [], answers: [] },
        client: {
          ...state.client,
          nombre: '',
          apellido: '',
          email: '',
          codigoPais: `+${JSON.parse(localStorage.getItem('region-area-code'))} 9`,
          numeroTelefono: '',
          code: null
        }
      };
    default:
      return state;
  }
};

export default pricingReducer;
