import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import 'dayjs/locale/es';

const CustomDatePicker = ({ onChange, value, availableDates, checked, minDate }) => {
  const isDateDisabled = (date) => {
    //ESTO SE PUEDE MEJORAR
    const fecha = new Date(date);
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`;
    return !availableDates?.some((availableDate) => availableDate === fechaFormateada);
  };
  return (
    <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
      <DatePicker
        disablePast
        value={value}
        minDate={minDate}
        onChange={onChange}
        shouldDisableDate={availableDates && isDateDisabled}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            sx={{
              border: checked && '1px solid #6CE194'
            }}
            placeholder="Elegir otra fecha"
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;

const StyledTextField = muiStyled(TextField)(() => {
  return {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    width: '100%'
  };
});
