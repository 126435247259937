import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from 'components/common/styled-span';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Benefit = ({ benefit, white }) => {
  const { primaryText, secondaryText } = benefit;
  return (
    <ComponentContainer>
      <CheckCircleIcon color={'primary'} />
      <TextContainer>
        <PrimaryText white={white}>{primaryText}</PrimaryText>
        <SecondaryText white={white}>{secondaryText}</SecondaryText>
      </TextContainer>
    </ComponentContainer>
  );
};

export default Benefit;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    margin: '0.4rem 0.6rem',
    width: '130px'
  };
});

const TextContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  };
});

const SecondaryText = muiStyled(StyledSpan)(({ theme, ...props }) => {
  return {
    fontWeight: 'bold',
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    textAlign: 'center',
    color: props.white && 'white'
  };
});

const PrimaryText = muiStyled(StyledSpan)(({ ...props }) => {
  return {
    fontWeight: '500',
    fontSize: 12,
    textAlign: 'center',
    color: props.white && 'white'
  };
});
