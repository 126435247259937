import { SearchSection, StyledStepContainer } from '../../components/styled-overlay';
import { StyledTitle } from 'components/common/styled-span';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ColorCard from 'components/color-card/color-card';
import { styled as muiStyled } from '@mui/material/styles';
import pricingActions from 'redux/actions/pricing.actions';
import { useDispatch, useSelector } from 'react-redux';
import { NotSureButton } from '../../components/buttons';
import { BottomBar } from '../../components/bottom-bar';
import STATUS from '../../../../constants/status';
import LoaderComponent from '../../../../components/loader/loader-component';
import CustomTextField from '../../../../components/text-field/text-field';

const ColorStep = ({ setActiveStep }) => {
  const [searchWord, setSearchWord] = useState('');
  const dispatch = useDispatch();
  const device = useSelector((state) => state.pricing.device.model);
  const colors = useSelector((state) => state.pricing.colors);
  const colorsStatus = useSelector((state) => state.pricing.getColorsStatus);
  useEffect(() => {
    dispatch(pricingActions.getColors.request(device));
  }, [device, dispatch]);
  useEffect(() => {
    if (colorsStatus === STATUS.success && colors.length === 0)
      setActiveStep((prevCount) => prevCount + 1);
  }, [colorsStatus, colors]);
  const selectColor = (color) => {
    dispatch(pricingActions.device.update('color_id', color.color_id));
    setActiveStep((prevCount) => prevCount + 1);
  };
  const notSure = () => {
    dispatch(pricingActions.device.update('color_id', null));
    setActiveStep((prevCount) => prevCount + 1);
  };
  if (colorsStatus === STATUS.loading) return <LoaderComponent />;
  return (
    <StyledStepContainer>
      <SearchSection>
        <StyledTitle>¿Y el color?</StyledTitle>
        <CustomTextField
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder="Ej. Rose Gold"
          value={searchWord}
        />
      </SearchSection>
      <ColorsContainer>
        <Grid container spacing={2} justifyContent="center">
          {colors
            .filter((color) => color.color_nombre.toUpperCase().includes(searchWord.toUpperCase()))
            .map((color) => (
              <Grid
                key={color.color_id}
                container
                item
                xs="auto"
                justifyContent="center"
                onClick={() => selectColor(color)}>
                <ColorCard color={color} />
              </Grid>
            ))}
          <Grid
            container
            item
            xs="auto"
            justifyContent="center"
            onClick={() =>
              selectColor({
                color_nombre: 'Otro',
                color_codigo: 'rgba(162, 169, 204, 1)'
              })
            }>
            <ColorCard
              color={{
                color_nombre: 'Otro',
                color_codigo: 'rgba(162, 169, 204, 1)'
              }}
            />
          </Grid>
        </Grid>
      </ColorsContainer>
      <BottomBar>
        <NotSureButton handleClick={notSure} />
      </BottomBar>
    </StyledStepContainer>
  );
};

export default ColorStep;

const ColorsContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20
  };
});
