import { styled as muiStyled } from '@mui/material/styles';

export const StyledSpan = muiStyled('span')(({ theme, ...props }) => {
  return {
    color: props.color ? props.color : theme.palette.primary.main,
    fontWeight: props.fontWeight,
    fontSize: props.fontSize,
    textOverflow: props.overflow,
    fontFamily: 'Inter, sans-serif',
    lineHeight: props.lineHeight ?? 1.2,
    textAlign: props.textalign,
    textDecoration: props.textDecoration,
    display: 'block'
  };
});

export const StyledTitle = muiStyled('span')(({ theme, ...props }) => {
  return {
    color: props.color ? props.color : theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: props.fontSize ?? '24px',
    fontFamily: 'Inter, sans-serif',
    lineHeight: 1.2,
    textAlign: props.textalign,
    display: 'block'
  };
});

export const StyledDescription = muiStyled('span')(({ theme, ...props }) => {
  return {
    color: props.color ? props.color : theme.palette.primary.main,
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    lineHeight: 1.2
  };
});
