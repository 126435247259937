import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import theme from '../../styles/theme';

const Search = ({ options, label, onChange, value, loading, field }) => {
  return (
    <StyledAutocomplete
      freeSolo
      disableClearable
      disablePortal
      loadingText={'Buscando coincidencias'}
      ListboxProps={{
        sx: {
          fontSize: 18,
          color: theme.palette.primary.main,
          fontWeight: 400,
          fontFamily: 'Inter, sans-serif'
        }
      }}
      componentsProps={{
        paper: {
          sx: {
            marginTop: '5px',
            borderRadius: 5
          }
        }
      }}
      value={value}
      loading={loading}
      InputProps={{ disableUnderline: true }}
      filterOptions={(x) => x}
      options={options && options.map((option) => option[field])}
      renderInput={(params) => (
        <StyledInput
          onChange={(e) => onChange(e.target.value)}
          variant="outlined"
          {...params}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      onChange={(newValue) => onChange(newValue.target.outerText)}
      isOptionEqualToValue={(option, value) =>
        value === undefined || value === '' || option === value
      }
    />
  );
};

export default Search;

const StyledInput = muiStyled(TextField)(({ theme }) => {
  return {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'transparent',
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.primary.main,
      padding: '5px 0px 5px 20px',
      fontFamily: 'Inter, sans-serif'
    },
    '& ::placeholder': {
      fontWeight: 400,
      fontSize: 22,
      transform: 'translateY(-2px)'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderRadius: 28,
      border: '1px solid #11216C'
    }
  };
});

const StyledAutocomplete = muiStyled(Autocomplete)(() => {
  return {
    borderRadius: 28,
    width: '100%',
    backgroundColor: 'white',
    border: 'none',
    display: 'flex',
    flexGrow: 1,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
  };
});
