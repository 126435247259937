import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../../../../../../components/common/styled-span';
import icon from './icon.png';

const AdditionalInfoLabel = (props) => {
  const { text } = props;
  return (
    <ComponentContainer>
      <img src={icon} alt={'test'} loading="lazy" />
      <div>
        <StyledSpan fontSize={12} fontWeight={700} color={'white'}>
          {text}
        </StyledSpan>
      </div>
    </ComponentContainer>
  );
};

export default AdditionalInfoLabel;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 40,
    padding: '8px 20px 8px 20px',
    gap: 10,
    background: 'linear-gradient(257.41deg, #116FFF -11.78%, #B1C9ED 101.06%)',
    width: 'max-content'
  };
});
