import { StyledStepContainer, SearchSection } from '../../components/styled-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDescription, StyledTitle } from 'components/common/styled-span';
import { Grid } from '@mui/material';
import ModelCard from 'components/model-card/model-card';
import pricingActions from 'redux/actions/pricing.actions';
import { useEffect, useMemo, useState } from 'react';
import ColorStep from './color-step';
import CustomTextField from '../../../../components/text-field/text-field';
import StyledButton from '../../../../components/common/styled-button';
import { styled as muiStyled } from '@mui/material/styles';
import LoaderComponent from '../../../../components/loader/loader-component';
import STATUS from '../../../../constants/status';
import { fullImageURL } from '../../../../utilities/utils';
import CustomAutoComplete from 'components/custom-text-field/CustomAutoComplete';
import { useParams } from 'react-router-dom';

const ModelStep = ({ setActiveStep }) => {
  const [colorStep, setColorStep] = useState(false);

  const SelectModel = () => {
    const [searchWord, setSearchWord] = useState('');
    const [quantityToShow, setQuantityToShow] = useState(6);
    const dispatch = useDispatch();
    const deviceBrand = useSelector((state) => state.pricing.device.brand);
    const modelsState = useSelector((state) => state.pricing.models);
    const models = useMemo(() => modelsState, [modelsState]);
    const getModelsStatus = useSelector((state) => state.pricing.getModelsStatus);
    const deviceType = useSelector((state) => state.pricing.device.type);
    const brandByName = useSelector((state) => state.pricing.brandByName);
    const getBrandByNameStatus = useSelector((state) => state.pricing.getBrandByNameStatus);
    const selectedBrand = useSelector((state) => state.pricing.device.brand);

    const noRepeat = useMemo(() => {
      return models
        .sort((a, b) => a?.indice - b?.indice)
        .reduce((acc, current) => {
          const x = acc.find((item) => item?.nombre === current?.nombre && !item?.habilitado_fixup);
          if (!x) {
            return acc.concat([current]);
          }
          return acc;
        }, []);
    }, [models]);

    const results = noRepeat.filter((model) =>
      model.nombre.toUpperCase().includes(searchWord.toUpperCase())
    ).length;
    const hasImage = noRepeat.find((model) => model?.imagen);
    const [selectedModel, setSelectedModel] = useState(null);

    const { brand } = useParams();
    useEffect(() => {
      if (brand && getBrandByNameStatus === STATUS.idle) {
        dispatch(pricingActions.getBrandByName.request(brand));
      }
    }, [brand]);
    useEffect(() => {
      if (getBrandByNameStatus === STATUS.success && !selectedBrand) {
        dispatch(pricingActions.device.update('brand', brandByName));
      }
    }, [getBrandByNameStatus, brandByName]);
    useEffect(() => {
      dispatch(pricingActions.getBrandsByDevice.request(deviceType?.id));
    }, [deviceType, dispatch]);
    useEffect(() => {
      dispatch(
        pricingActions.getModelsByBrandAndDevice.request(
          deviceBrand?.marca_id || deviceBrand?.id,
          deviceType?.id
        )
      );
    }, [deviceBrand, deviceType, dispatch]);
    const showMore = () => {
      setQuantityToShow(quantityToShow + 5);
    };
    const selectModel = (model) => {
      if (typeof model === 'number') {
        dispatch(pricingActions.device.update('model', model));
      } else {
        if (model?.inputValue) {
          const find = models.find((element) => element.nombre === 'Desconocido');
          if (!find) {
            selectCustomModel();
            return;
          } else {
            find.nombre = model?.inputValue;
            dispatch(pricingActions.device.update('model', find?.id));
          }
        } else {
          dispatch(pricingActions.device.update('model', model?.id));
        }
      }
      if (deviceType.nombre === 'Smartphone') {
        setColorStep(true);
      } else {
        setActiveStep((prevCount) => prevCount + 1);
        window.scrollTo(0, 0);
      }
    };
    const selectCustomModel = () => {
      dispatch(pricingActions.device.update('model', '99'));
      dispatch(pricingActions.device.update('custom_model', searchWord));
      setActiveStep((prevCount) => prevCount + 1);
      window.scrollTo(0, 0);
    };

    const ModelWithoutImage = () => {
      return (
        <StyledStepContainer>
          <SearchSection>
            <StyledTitle>¿Qué modelo es tu {deviceBrand?.marca_nombre}?</StyledTitle>
            <StyledDescription>Escribelo a continuación</StyledDescription>
            <CustomAutoComplete
              value={selectedModel}
              setSelectedModel={setSelectedModel}
              label="Modelo"
              labelField="nombre"
              valueField="id"
              options={noRepeat}
              freeSolo
              onChange={(value) => selectModel(value)}
            />
          </SearchSection>
        </StyledStepContainer>
      );
    };
    if (getModelsStatus === STATUS.loading) return <LoaderComponent />;
    return (
      <StyledStepContainer>
        {hasImage ? (
          <>
            <SearchSection>
              <StyledTitle>¿Qué modelo es tu {deviceBrand?.marca_nombre}?</StyledTitle>
              <StyledDescription>Escribelo a continuación o seleccioná la imagen</StyledDescription>
              <CustomTextField
                onChange={(e) => setSearchWord(e.target.value)}
                placeholder="Ej. Galaxy S3"
                value={searchWord}
              />
            </SearchSection>
            <Grid container spacing={2} justifyContent="center">
              {noRepeat
                .filter((model) => model.nombre.toUpperCase().includes(searchWord.toUpperCase()))
                .slice(0, quantityToShow)
                .map((model) => (
                  <Grid
                    key={model.id}
                    container
                    item
                    xs="auto"
                    onClick={() => selectModel(model)}
                    justifyContent="center">
                    <ModelCard model={model} defaultImage={fullImageURL(deviceType.icono)} />
                  </Grid>
                ))}
            </Grid>
            {results === 0 && searchWord && (
              <Grid container spacing={2} alignItems="center">
                <Grid
                  container
                  item
                  md={4}
                  sm={3}
                  xs={6}
                  justifyContent="center"
                  onClick={selectCustomModel}>
                  <ModelCard
                    model={{
                      nombre: searchWord ?? 'Otro',
                      imagen: deviceType?.icono
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {quantityToShow <
              noRepeat.filter((model) =>
                model.nombre.toUpperCase().includes(searchWord.toUpperCase())
              ).length &&
              hasImage && (
                <ButtonContainer>
                  <StyledButton variant="contained" color="white" onClick={showMore}>
                    {' '}
                    Ver más{' '}
                  </StyledButton>
                </ButtonContainer>
              )}
          </>
        ) : (
          <ModelWithoutImage />
        )}
      </StyledStepContainer>
    );
  };

  return colorStep ? <ColorStep setActiveStep={setActiveStep} /> : <SelectModel />;
};

export default ModelStep;

const ButtonContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  };
});
