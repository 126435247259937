import { Grid } from '@mui/material';
import Theme from 'styles/theme';
import dude from 'assets/banners/dude.png';
import './contact-banner.scss';
import StyledSection from '../common/styled-section';
import { PricingButton } from 'components/header/styled';
import { useNavigate } from 'react-router-dom';

const ContactBanner = () => {
  const navigate = useNavigate();
  return (
    <StyledSection>
      <Grid container className="contact-section-container">
        <Grid container item xs={12} sm={12} md={6} lg={6} justifyContent="center">
          <div className="image-contact-container">
            <img src={dude} alt="dude" className="image-contact" loading="lazy" />
          </div>
        </Grid>
        <Grid container item xs={12} sm={12} md={6} lg={6} justifyContent="center">
          <div className="text-container" style={{ color: Theme.palette.primary.main }}>
            <h1>¿Tenés dudas?</h1>
            <PricingButton variant="contained" onClick={() => navigate('/contacto')}>
              Hablemos
            </PricingButton>
          </div>
        </Grid>
      </Grid>
    </StyledSection>
  );
};

export default ContactBanner;
