import { combineReducers, configureStore } from '@reduxjs/toolkit';
import middlewares from 'redux/middlewares';
import CommonReducer from 'redux/reducers/common.reducer';
import pricingReducer from './redux/reducers/pricing.reducer';

const rootReducer = combineReducers({
  common: CommonReducer,
  pricing: pricingReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares
});

export default store;
