import { StyledStepContainer } from '../../components/styled-overlay';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { StyledSpan, StyledTitle } from 'components/common/styled-span';
import { ContinueButton } from '../../components/buttons';
import { BottomBar, HolderBottomBar } from '../../components/bottom-bar';
import { useDispatch, useSelector } from 'react-redux';
import pricingActions from 'redux/actions/pricing.actions';
import CustomTextField from 'components/text-field/text-field';
import { isNullUndefEmptyStr, isValidEmail } from 'utilities/utils';
// import PhoneNumberInput from '../../../../components/phone-number-input/phone-number-input';
import TextFieldAutocomplete from '../../../../components/text-field-autocomplete/text-field-autocomplete';
import theme from '../../../../styles/theme';
import SelectSearch from 'components/select-search/SelectSearch';
import { getDiscount } from 'services/pricing.service';
// import SelectWithSearch from 'components/select-search-global/SelectWithSearch';

const ClientStep = ({ setActiveStep, file }) => {
  const dispatch = useDispatch();
  const PROVINCIAS = useSelector((state) => state.pricing.provincias);
  const BARRIOS = useSelector((state) => state.pricing.barrios);
  const CLIENT_DATA = useSelector((state) => state.pricing.client);
  const [clientData, setClientData] = useState(CLIENT_DATA);
  const deviceData = useSelector((state) => state.pricing.device);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [discountError, setDiscountError] = useState();
  useEffect(() => {
    document.documentElement.style.overflow = 'auto';
    dispatch(pricingActions.getProvincias.request());
  }, [dispatch]);

  useEffect(() => {
    if (clientData.provincia) {
      dispatch(pricingActions.getBarrios.request(clientData.provincia));
    }
  }, [clientData.provincia, dispatch]);

  const dispatchData = () => {
    dispatch(pricingActions.client.update(clientData));
    dispatch(
      pricingActions.postClientData.request({
        clientData: clientData,
        deviceData: deviceData,
        file: file
      })
    );
    setActiveStep((currentStep) => currentStep + 1);
  };

  const handleClick = () => {
    if (!clientData?.code || !clientData?.code?.length) {
      dispatchData();
    } else {
      (async () => {
        try {
          const data = await getDiscount(clientData.code);
          if (data) {
            dispatchData();
          }
        } catch (error) {
          setDiscountError('Ups! El código ingresado no es válido.');
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
          });
          console.error(error);
        }
      })();

      dispatch(pricingActions.getDiscount.request(clientData.code));
    }
  };
  return (
    <StyledStepContainer>
      <StyledTitle>Por último, te pedimos tus datos, para compartirte la cotización.</StyledTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            value={clientData.nombre}
            label="Nombre"
            onChange={(event) => setClientData({ ...clientData, nombre: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            value={clientData.apellido}
            label="Apellido"
            onChange={(event) => setClientData({ ...clientData, apellido: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            value={clientData.email}
            label="Email"
            onChange={(event) => setClientData({ ...clientData, email: event.target.value })}
            type="email"
            error={!isValidEmail(clientData.email) && clientData.email.length !== 0}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <PhoneNumberInput
            value={clientData.numeroTelefono}
            label="Telefono"
            onChange={(value) => setClientData({ ...clientData, numeroTelefono: value })}
          /> */}
          {/*<SelectWithSearch
            setValidPhoneNumber={setValidPhoneNumber}
            clientData={clientData}
            setClientData={setClientData}
          />*/}
          <SelectSearch
            setValidPhoneNumber={setValidPhoneNumber}
            clientData={clientData}
            setClientData={setClientData}
            validPhoneNumber={validPhoneNumber}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldAutocomplete
            value={clientData.provincia}
            label="Provincia"
            labelField="nombre"
            valueField="id"
            options={PROVINCIAS}
            onChange={(value) => setClientData({ ...clientData, provincia: value, barrio: null })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {clientData.provincia && (
            <TextFieldAutocomplete
              value={clientData.barrio}
              label={clientData?.provincia === 2 ? 'Barrio' : 'Localidad'}
              labelField="nombre"
              valueField="id"
              options={BARRIOS}
              onChange={(value) => setClientData({ ...clientData, barrio: value })}
            />
          )}
        </Grid>
      </Grid>
      <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
        Si tenés un código de descuento, ingresalo acá:
      </StyledSpan>
      <div style={{ display: 'flex', width: 'max-content' }}>
        <CustomTextField
          value={clientData.code}
          label="Código de descuento"
          onChange={(event) => setClientData({ ...clientData, code: event.target.value })}
        />
      </div>
      {discountError && (
        <div style={{ display: 'flex', width: 'max-content' }}>
          <StyledSpan style={{ marginLeft: '0.2rem' }} color="red">
            {discountError}
          </StyledSpan>
        </div>
      )}

      <HolderBottomBar />
      <BottomBar>
        <ContinueButton
          disabled={
            !validPhoneNumber ||
            !clientData?.numeroTelefono ||
            isNullUndefEmptyStr(clientData) ||
            !isValidEmail(clientData.email)
          }
          handleClick={handleClick}
        />
      </BottomBar>
    </StyledStepContainer>
  );
};

export default ClientStep;
