import StyledButton from 'components/common/styled-button';

export const ContinueButton = ({ disabled, handleClick }) => {
  return (
    <StyledButton color="secondary" variant="contained" disabled={disabled} onClick={handleClick}>
      Continuar
    </StyledButton>
  );
};

export const NotSureButton = ({ handleClick }) => {
  return (
    <StyledButton color="white" variant="contained" onClick={handleClick}>
      No estoy seguro
    </StyledButton>
  );
};
