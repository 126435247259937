import AccordionQuestions from 'components/accordion-questions/accordion-questions';
import { StyledSpan } from '../../../components/common/styled-span';
import { styled as muiStyled } from '@mui/material/styles';

const FaqComponent = ({ FAQ }) => {
  return (
    <ComponentContainer>
      <StyledSpan fontSize={20} fontWeight={300}>
        Preguntas Frecuentes sobre este servicio.
      </StyledSpan>
      <div>
        {FAQ.map((faq) => (
          <AccordionQuestions key={faq.id} FAQ={faq} />
        ))}
      </div>
    </ComponentContainer>
  );
};

export default FaqComponent;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  };
});
