import pricingActions, {
  GET_BARRIOS_REQUEST,
  GET_BRAND_BY_DEVICE_REQUEST,
  GET_BRAND_BY_NAME_REQUEST,
  GET_COLORS_BY_MODEL_REQUEST,
  GET_DEVICE_BY_NAME_REQUEST,
  GET_DEVICES_REQUEST,
  GET_FLAWS_BY_DEVICE_REQUEST,
  GET_GEOLOCATION_REQUEST,
  GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST,
  GET_PROVINCIAS_REQUEST,
  GET_QUESTIONS_BY_FLAWS_REQUEST,
  GET_SOLUCION_REQUEST,
  POST_CLIENT_REQUEST,
  GET_SERVICIOS_DISPONIBLES_REQUEST,
  GET_SUCURSALES_YO_LO_LLEVO_REQUEST,
  POST_UPDATE_DERIVACION_REQUEST,
  POST_END_DERIVACION_REQUEST,
  GET_DISCOUNT_REQUEST
} from '../actions/pricing.actions';
import {
  getBarrios,
  getBrandByName,
  getBrandsByDevice,
  getColorByModel,
  getDeviceByName,
  getDevices,
  getFlawsByDevice,
  getGeolocation,
  getModelsByBrandAndDevice,
  getProvincias,
  getQuestionsByFlaws,
  getSolucion,
  postClientData,
  getServiciosDisponibles,
  getSucursalesYoLoLlevo,
  postUpdateDerivacion,
  postEndDerivacion,
  getDiscount
} from '../../services/pricing.service';

const pricingMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      case GET_PROVINCIAS_REQUEST:
        getProvincias()
          .then((res) => {
            dispatch(pricingActions.getProvincias.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getProvincias.error(err));
          });
        break;
      case GET_BARRIOS_REQUEST:
        getBarrios(action.id_provincia)
          .then((res) => {
            dispatch(pricingActions.getBarrios.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getBarrios.error(err));
          });
        break;
      case GET_DEVICES_REQUEST:
        getDevices()
          .then((res) => {
            dispatch(pricingActions.getDevices.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getDevices.error(err));
          });
        break;
      case GET_BRAND_BY_DEVICE_REQUEST:
        getBrandsByDevice(action.id_device)
          .then((res) => {
            dispatch(pricingActions.getBrandsByDevice.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getBrandsByDevice.error(err));
          });
        break;
      case GET_MODELS_BY_BRAND_AND_DEVICE_REQUEST:
        getModelsByBrandAndDevice(action.id_device, action.id_brand)
          .then((res) => {
            dispatch(pricingActions.getModelsByBrandAndDevice.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getModelsByBrandAndDevice.error(err));
          });
        break;
      case GET_COLORS_BY_MODEL_REQUEST:
        getColorByModel(action.id_model)
          .then((res) => {
            dispatch(pricingActions.getColors.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getColors.error(err));
          });
        break;
      case GET_FLAWS_BY_DEVICE_REQUEST:
        getFlawsByDevice(action.id_device)
          .then((res) => {
            dispatch(pricingActions.getFlawsByDevice.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getFlawsByDevice.error(err));
          });
        break;
      case GET_DEVICE_BY_NAME_REQUEST:
        getDeviceByName(action.device_name)
          .then((res) => {
            dispatch(pricingActions.getDeviceByName.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getDeviceByName.error(err));
          });
        break;
      case GET_BRAND_BY_NAME_REQUEST:
        getBrandByName(action.brand_name)
          .then((res) => {
            dispatch(pricingActions.getBrandByName.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getBrandByName.error(err));
          });
        break;
      case GET_QUESTIONS_BY_FLAWS_REQUEST:
        getQuestionsByFlaws(action.flaws)
          .then((res) => {
            dispatch(pricingActions.getQuestionsByFlaws.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getQuestionsByFlaws.error(err));
          });
        break;
      case GET_GEOLOCATION_REQUEST:
        getGeolocation(action.altitude, action.longitude)
          .then((res) => {
            getBarrios(res.data.provincia_id).then((resp) => {
              dispatch(
                pricingActions.client.update({
                  provincia: res.data.provincia_id,
                  barrio: resp.data.filter(
                    (barrio) => barrio.nombre === res.data.localidad_nombre
                  )[0].id,
                  nombre: '',
                  apellido: '',
                  email: '',
                  codigoPais: `+${JSON.parse(localStorage.getItem('region-area-code'))}`
                })
              );
            });
          })
          .catch((err) => {
            dispatch(pricingActions.getGeolocation.error(err));
          });
        break;
      case GET_SOLUCION_REQUEST:
        getSolucion(action.id)
          .then((res) => {
            dispatch(pricingActions.getSolucion.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getSolucion.error(err));
          });
        break;
      case POST_CLIENT_REQUEST:
        postClientData(action.data)
          .then((res) => {
            dispatch(pricingActions.postClientData.response(res.data));
            dispatch(pricingActions.getServiciosDisponibles.request(res.data.id));
          })
          .catch((err) => {
            dispatch(pricingActions.postClientData.error(err));
          });
        break;
      case GET_SERVICIOS_DISPONIBLES_REQUEST:
        getServiciosDisponibles(action.id)
          .then((res) => {
            dispatch(pricingActions.getServiciosDisponibles.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getServiciosDisponibles.error(err));
          });
        break;
      case GET_SUCURSALES_YO_LO_LLEVO_REQUEST:
        getSucursalesYoLoLlevo(action.id)
          .then((res) => {
            dispatch(pricingActions.getSucursalesYoLoLlevo.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getSucursalesYoLoLlevo.error(err));
          });
        break;
      case POST_UPDATE_DERIVACION_REQUEST:
        postUpdateDerivacion(action.data)
          .then((res) => {
            dispatch(pricingActions.postUpdateDerivacion.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.postUpdateDerivacion.error(err));
          });
        break;
      case POST_END_DERIVACION_REQUEST:
        postEndDerivacion(action.data)
          .then((res) => {
            dispatch(pricingActions.postEndDerivacion.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.postEndDerivacion.error(err));
          });
        break;
      case GET_DISCOUNT_REQUEST:
        getDiscount(action.code)
          .then((res) => {
            dispatch(pricingActions.getDiscount.response(res.data));
          })
          .catch((err) => {
            dispatch(pricingActions.getDiscount.error(err));
          });
        break;
      default:
        break;
    }
  };

export default pricingMiddleware;
