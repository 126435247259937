import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BusinessIcon from '@mui/icons-material/Business';
import ReviewsIcon from '@mui/icons-material/Reviews';
import HeadsetIcon from '@mui/icons-material/Headset';
import HelpIcon from '@mui/icons-material/Help';

export const PRIMARY_TABS = [
  {
    id: 1,
    title: 'Servicios',
    path: '/servicios',
    icon: HelpIcon
  },
  {
    id: 2,
    title: 'Servicios Corporativos',
    path: 'https://empresas.fixupweb.com',
    icon: BusinessIcon
  },
  {
    id: 3,
    title: 'Reseñas',
    path: '/reviews',
    icon: ReviewsIcon
  },
  {
    id: 4,
    title: 'Sobre FixUP!',
    path: '/sobre',
    icon: PriorityHighIcon
  },
  {
    id: 5,
    title: 'Contacto',
    path: '/contacto',
    icon: HeadsetIcon
  },
  {
    id: 6,
    title: 'Blog',
    path: 'https://blog.fixupweb.com/',
    icon: LightbulbIcon
  }
];

export const SECONDARY_TABS = [
  {
    id: 1,
    title: 'Estado de mi servicio',
    icon: CampaignOutlinedIcon,
    path: 'https://estado.fixupweb.com/estado'
  },
  {
    id: 2,
    title: 'Mi cuenta',
    icon: AccountCircleOutlinedIcon,
    path: '/mi-cuenta'
  },
  {
    id: 3,
    title: 'Argentina',
    icon: LanguageOutlinedIcon,
    path: '/region'
  }
];
