import { styled as muiStyled } from '@mui/material/styles';
import Benefit from './benefit';
import { BENEFITS } from './benefits';

const BenefitsComponent = ({ white }) => (
  <ComponentContainer>
    {BENEFITS.map((benefit) => (
      <Benefit key={benefit.id} benefit={benefit} white={white} />
    ))}
  </ComponentContainer>
);

export default BenefitsComponent;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    height: 'max-content',
    width: '100%',
    backgroundColor: 'white',
    padding: '15px 5px 15px 5px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      maxWidth: '800px',
      margin: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw',
      margin: 'auto',
      flexWrap: 'wrap'
    }
  };
});
