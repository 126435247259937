import digitals from 'assets/why-choose-fixup/digitals.png';
import close from 'assets/why-choose-fixup/close.png';
import devices from 'assets/why-choose-fixup/devices.png';
import best from 'assets/why-choose-fixup/best.png';
import warranty from 'assets/why-choose-fixup/warranty.png';
import labs from 'assets/why-choose-fixup/labs.png';

export const REASONS = [
  {
    id: 1,
    title: '100% digitales',
    description: 'Cotizá y seguí tu reparación 100% online.',
    image: digitals
  },
  { id: 2, title: 'Siempre cerca', description: 'Contamos con cobertura nacional.', image: close },
  {
    id: 3,
    title: 'Múltiples dispositivos',
    description: 'Reparamos cualquier dispositivo tecnológico, de cualquier marca y modelo. ',
    image: devices
  },
  {
    id: 4,
    title: 'Sólo lo mejor',
    description: 'Contamos con repuestos homologados y técnicos especializados en cada marca',
    image: best
  },
  {
    id: 5,
    title: 'Garantía Internacional',
    description: 'Ofrecemos una garantía internacional exclusiva para tu tranquilidad.',
    image: warranty
  },
  {
    id: 6,
    title: 'Laboratorios Certificados',
    description:
      'Las reparaciones son realizadas en laboratorios 100% certificados  y homologados por las marcas.',
    image: labs
  }
];
