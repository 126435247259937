import { StyledStepContainer } from '../../components/styled-overlay';
import { useDispatch, useSelector } from 'react-redux';
import pricingActions from 'redux/actions/pricing.actions';
import ServicePricingCard from './components/service-pricing-card/service-pricing-card';
import { StyledTitle } from '../../../../components/common/styled-span';
import DeliverTypeCard from './components/deliver-type-card/deliver-type-card';
import { Grid, Typography } from '@mui/material';
import AdditionalInfoLabel from './components/additional-info-label/additional-info-label';
import LoaderComponent from 'components/loader/loader-component';
import { useNavigate } from 'react-router-dom';
import getServiceName from 'utilities/services';
import { useEffect, useState } from 'react';
import { isMobile, scrollToSectionSmoothly } from 'utilities/utils';

// eslint-disable-next-line no-unused-vars
const ChooseServiceStep = ({ addSteps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const selectSolucion = () => {
    setIsSelectedSolucion(true);
    const id = solucion?.solucion_id
      ? { solucion: solucion.solucion_id }
      : { contrato: solucion.contrato_id };
    dispatch(pricingActions.solucion.chooseSolucion(id));
    dispatch(
      pricingActions.postUpdateDerivacion.request({
        derivacion_id: derivacionId,
        company_id: JSON.parse(localStorage.getItem('region-id')),
        contrato_id: selectedSolucion?.contrato
      })
    );
    // scroll
    scrollToSectionSmoothly('#servicios', 1000);
    // section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const selectService = (service) => {
    dispatch(pricingActions.service.chooseService(service.id));
    dispatch(
      pricingActions.postUpdateDerivacion.request({
        derivacion_id: derivacionId,
        company_id: JSON.parse(localStorage.getItem('region-id')),
        contrato_id: selectedSolucion.contrato
      })
    );

    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (service?.id === 6) {
      dispatch(
        pricingActions.postEndDerivacion.request({
          derivacion_id: derivacionId,
          company_id: JSON.parse(localStorage.getItem('region-id')),
          servicio_id: service.id,
          franja_horaria_id: null,
          franja_horaria: null,
          fecha: null
        })
      );
      navigate('/confirmacion');
    }
    const serviceName = getServiceName(service.id);
    addSteps(serviceName);
  };
  // eslint-disable-next-line no-unused-vars
  const derivacionId = useSelector((state) => state.pricing.derivacion_id);
  const solucion = useSelector((state) => state.pricing.solucion);
  const services = useSelector((state) => state.pricing.serviciosDisponibles);
  const selectedSolucion = useSelector((state) => state.pricing.selectedSolucion);
  const [isSolucionSelected, setIsSelectedSolucion] = useState(Boolean(selectedSolucion));

  const dispatchInformation = async () => {
    await dispatch(
      pricingActions.postUpdateDerivacion.request({
        derivacion_id: derivacionId,
        company_id: JSON.parse(localStorage.getItem('region-id'))
      })
    );
    await dispatch(
      pricingActions.postEndDerivacion.request({
        derivacion_id: derivacionId,
        company_id: JSON.parse(localStorage.getItem('region-id')),
        servicio_id: 99,
        franja_horaria_id: null,
        franja_horaria: null,
        fecha: null
      })
    );
  };

  useEffect(() => {
    if (derivacionId) {
      dispatch(pricingActions.getSolucion.request(derivacionId));
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [derivacionId]);

  if (!solucion || !services || loading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100% !important',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          flex: 1,
          marginTop: '20vh'
        }}>
        <LoaderComponent />
        <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>
          Buscando las mejores opciones...
        </Typography>
      </div>
    );
  }
  if (
    (solucion?.solucion === 'precio exacto' && !solucion?.contratos?.length) ||
    (typeof solucion !== 'object' && !solucion?.length)
  ) {
    dispatchInformation();
    navigate('/confirmacion');
  }

  return (
    <StyledStepContainer>
      <StyledTitle>¿Qué servicio preferís?</StyledTitle>
      <AdditionalInfoLabel text={'Recordá que no tenés que pagarlo ahora.'} icon={'Sa'} />
      {solucion?.contratos ? (
        solucion.contratos
          .sort((a, b) => a?.indice - b?.indice)
          .map((contrato, index) => (
            <>
              {index == 1 && (
                <Typography variant="h5" fontWeight={'bold'}>
                  Otras opciones
                </Typography>
              )}
              <ServicePricingCard
                selected={selectedSolucion === contrato.contrato_id}
                key={contrato.contrato_id}
                service={contrato}
                onClick={() => selectSolucion(contrato)}
              />
            </>
          ))
      ) : (
        <ServicePricingCard
          service={solucion}
          onClick={() => selectSolucion(solucion)}
          selected={selectedSolucion === solucion.id}
        />
      )}
      <div id="servicios">
        {isSolucionSelected && (
          <>
            <StyledTitle sx={{ margin: '1.2rem 0' }}>¿Cómo querés manejarlo?</StyledTitle>
            <Grid
              container
              spacing={2}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start'
              }}>
              {services.map((service) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={service.id}
                  style={{ flex: !isMobile() && '0 0 calc(33.333% - 16px)' }}
                  onClick={() => selectService(service)}>
                  <DeliverTypeCard props={service} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </StyledStepContainer>
  );
};

export default ChooseServiceStep;
