import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../common/styled-span';
import StyledCard from '../common/styled-card';
import { fullImageURL } from '../../utilities/utils';

const DeviceCard = ({ device }) => (
  <CardContainer elevation={1}>
    <DeviceCardContent>
      <img loading="lazy" src={fullImageURL(device?.icono)} alt="device" height="64px" />
      <StyledSpan textalign="center" fontWeight="bold" fontSize={12}>
        {device?.nombre}
      </StyledSpan>
    </DeviceCardContent>
  </CardContainer>
);

export default DeviceCard;

const CardContainer = muiStyled(StyledCard)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    height: '150px',
    width: '120px'
  };
});

const DeviceCardContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  };
});
