import { styled as muiStyled } from '@mui/material/styles';

const TextContainer = muiStyled('div')((props) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginBottom: '20px',
    marginTop: props.marginTop,
    zIndex: 100
  };
});

export default TextContainer;
