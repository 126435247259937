export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST';
export const GET_REVIEWS_RESPONSE = 'GET_REVIEWS_RESPONSE';
export const GET_REVIEWS_ERROR = 'GET_REVIEWS_ERROR';

export const GET_OFFICIAL_BRANDS_REQUEST = 'GET_OFFICIAL_BRANDS_REQUEST';
export const GET_OFFICIAL_BRANDS_RESPONSE = 'GET_OFFICIAL_BRANDS_RESPONSE';
export const GET_OFFICIAL_BRANDS_ERROR = 'GET_OFFICIAL_BRANDS_ERROR';

export const GET_FIX_UP_IN_MEDIA_REQUEST = 'GET_FIX_UP_IN_MEDIA_REQUEST';
export const GET_FIX_UP_IN_MEDIA_RESPONSE = 'GET_FIX_UP_IN_MEDIA_RESPONSE';
export const GET_FIX_UP_IN_MEDIA_ERROR = 'GET_FIX_UP_IN_MEDIA_ERROR';

export const GET_SUCURSALES_CERCANAS_REQUEST = 'GET_SUCURSALES_CERCANAS_REQUEST';
export const GET_SUCURSALES_CERCANAS_RESPONSE = 'GET_SUCURSALES_CERCANAS_RESPONSE';
export const GET_SUCURSALES_CERCANAS_ERROR = 'GET_SUCURSALES_CERCANAS_ERROR';

export const GET_SEARCH_MODELS_REQUEST = 'GET_SEARCH_MODELS_REQUEST';
export const GET_SEARCH_MODELS_RESPONSE = 'GET_SEARCH_MODELS_RESPONSE';
export const GET_SEARCH_MODELS_ERROR = 'GET_SEARCH_MODELS_ERROR';

export const GET_TOP_BANNER_REQUEST = 'GET_TOP_BANNER_REQUEST';
export const GET_TOP_BANNER_RESPONSE = 'GET_TOP_BANNER_RESPONSE';
export const GET_TOP_BANNER_ERROR = 'GET_TOP_BANNER_ERROR';

export const GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST';
export const GET_PARTNERS_RESPONSE = 'GET_PARTNERS_RESPONSE';
export const GET_PARTNERS_ERROR = 'GET_PARTNERS_ERROR';

export const GET_INVESTORS_REQUEST = 'GET_INVESTORS_REQUEST';
export const GET_INVESTORS_RESPONSE = 'GET_INVESTORS_RESPONSE';
export const GET_INVESTORS_ERROR = 'GET_INVESTORS_ERROR';

export const GET_PROMOTIONAL_BANNER_REQUEST = 'GET_PROMOTIONAL_BANNER_REQUEST';
export const GET_PROMOTIONAL_BANNER_RESPONSE = 'GET_PROMOTIONAL_BANNER_RESPONSE';
export const GET_PROMOTIONAL_BANNER_ERROR = 'GET_PROMOTIONAL_BANNER_ERROR';

export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const GET_FAQ_RESPONSE = 'GET_FAQ_RESPONSE';
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR';

export const GET_FAQ_PATH_REQUEST = 'GET_FAQ_PATH_REQUEST';
export const GET_FAQ_PATH_RESPONSE = 'GET_FAQ_PATH_RESPONSE';
export const GET_FAQ_PATH_ERROR = 'GET_FAQ_PATH_ERROR';

const commonActions = {
  getReviews: {
    request: () => ({ type: GET_REVIEWS_REQUEST }),
    response: (res) => ({ type: GET_REVIEWS_RESPONSE, res }),
    error: (err) => ({ type: GET_REVIEWS_ERROR, err })
  },
  getOfficialBrands: {
    request: () => ({ type: GET_OFFICIAL_BRANDS_REQUEST }),
    response: (res) => ({ type: GET_OFFICIAL_BRANDS_RESPONSE, res }),
    error: (err) => ({ type: GET_OFFICIAL_BRANDS_ERROR, err })
  },
  getSearchModels: {
    request: (text) => ({ type: GET_SEARCH_MODELS_REQUEST, text }),
    response: (res) => ({ type: GET_SEARCH_MODELS_RESPONSE, res }),
    error: (err) => ({ type: GET_SEARCH_MODELS_ERROR, err })
  },
  getFixUpInMedia: {
    request: () => ({ type: GET_FIX_UP_IN_MEDIA_REQUEST }),
    response: (res) => ({ type: GET_FIX_UP_IN_MEDIA_RESPONSE, res }),
    error: (err) => ({ type: GET_FIX_UP_IN_MEDIA_ERROR, err })
  },
  getSucursalesCercanas: {
    request: () => ({ type: GET_SUCURSALES_CERCANAS_REQUEST }),
    response: (res) => ({ type: GET_SUCURSALES_CERCANAS_RESPONSE, res }),
    error: (err) => ({ type: GET_SUCURSALES_CERCANAS_ERROR, err })
  },
  getTopBanner: {
    request: (path) => ({ type: GET_TOP_BANNER_REQUEST, path }),
    response: (res) => ({ type: GET_TOP_BANNER_RESPONSE, res }),
    error: (err) => ({ type: GET_TOP_BANNER_ERROR, err })
  },
  getPartners: {
    request: () => ({ type: GET_PARTNERS_REQUEST }),
    response: (res) => ({ type: GET_PARTNERS_RESPONSE, res }),
    error: (err) => ({ type: GET_PARTNERS_ERROR, err })
  },
  getInvestors: {
    request: () => ({ type: GET_INVESTORS_REQUEST }),
    response: (res) => ({ type: GET_INVESTORS_RESPONSE, res }),
    error: (err) => ({ type: GET_INVESTORS_ERROR, err })
  },
  getPromotionalBanner: {
    request: (path) => ({ type: GET_PROMOTIONAL_BANNER_REQUEST, path }),
    response: (res) => ({ type: GET_PROMOTIONAL_BANNER_RESPONSE, res }),
    error: (err) => ({ type: GET_PROMOTIONAL_BANNER_ERROR, err })
  },
  getFAQ: {
    request: () => ({ type: GET_FAQ_REQUEST }),
    response: (res) => ({ type: GET_FAQ_RESPONSE, res }),
    error: (err) => ({ type: GET_FAQ_ERROR, err })
  },
  getFAQPath: {
    request: (path) => ({ type: GET_FAQ_PATH_REQUEST, path }),
    response: (res) => ({ type: GET_FAQ_PATH_RESPONSE, res }),
    error: (err) => ({ type: GET_FAQ_PATH_ERROR, err })
  }
};

export default commonActions;
