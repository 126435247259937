import {
  BannerContainer,
  BannerContent,
  BannerImageContainer,
  KnowMoreContainer,
  StyledBannerSubtitle,
  StyledBannerTitle,
  StyledBottomText
} from './styled';
import TextContainer from 'components/common/styled-text-section';
import { HashLink } from 'react-router-hash-link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const TopBanner = () => {
  return (
    <BannerContainer>
      <BannerImageContainer>
        <BannerContent>
          <TextContainer>
            <StyledBannerTitle>Hacer un mundo mejor, una reparación a la vez.</StyledBannerTitle>
            <StyledBannerSubtitle>Ese es el propósito que nos mueve.</StyledBannerSubtitle>
          </TextContainer>
          <KnowMoreContainer>
            <StyledBottomText>Conocé más de nosotros</StyledBottomText>
            <HashLink to="#rocket-banner" smooth>
              <KeyboardArrowDownIcon color="white" />
            </HashLink>
          </KnowMoreContainer>
        </BannerContent>
      </BannerImageContainer>
    </BannerContainer>
  );
};

export default TopBanner;
