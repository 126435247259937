import { StyledStepContainer } from '../../components/styled-overlay';
import { useEffect, useState } from 'react';
import QuestionComponent from 'components/question/question-component';
import StyledButton from '../../../../components/common/styled-button';
import { useDispatch, useSelector } from 'react-redux';
import pricingActions from '../../../../redux/actions/pricing.actions';
import { BottomBar, HolderBottomBar } from '../../components/bottom-bar';
import { StyledTitle } from '../../../../components/common/styled-span';

const QuestionsStep = ({ setActiveStep, setFile }) => {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const questions = useSelector((state) => state.pricing.questions);
  const [answer, setAnswer] = useState('');
  const dispatch = useDispatch();
  const handleClick = () => {
    let question = questions[activeQuestion];
    question['answer'] = answer;
    dispatch(pricingActions.device.updateAnswers(question));
    if (questions.length === activeQuestion + 1) {
      setActiveStep((prevStep) => prevStep + 1);
      window.scrollTo(0, 0);
    } else {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const handleChange = (event, option, file) => {
    if (option) {
      setAnswer(option);
    } else if (file) {
      setAnswer(file);
    } else {
      setAnswer(event.target.value);
    }
  };

  useEffect(() => {
    setAnswer('');
  }, [activeQuestion]);
  return (
    <StyledStepContainer>
      <StyledTitle>Algunas preguntas adicionales</StyledTitle>
      <QuestionComponent
        question={questions[activeQuestion]}
        handleChange={handleChange}
        answer={answer}
        setFile={setFile}
      />
      <HolderBottomBar />
      <BottomBar>
        <StyledButton color="secondary" variant="contained" onClick={handleClick}>
          Continuar
        </StyledButton>
      </BottomBar>
    </StyledStepContainer>
  );
};

export default QuestionsStep;
