import AddressCard from './address-card';
import TextContainer from '../common/styled-text-section';
import { StyledDescription, StyledSpan, StyledTitle } from 'components/common/styled-span';
import { styled as muiStyled } from '@mui/material/styles';
import StyledSection from '../common/styled-section';
import { Grid } from '@mui/material';
import StyledButton from '../common/styled-button';
import flag from 'assets/banners/flag.png';
import theme from '../../styles/theme';

const AlwaysCloseComponent = ({ sucursales }) => {
  return (
    <ComponentContainer>
      <StyledSection margin="auto">
        <LeftSideContent>
          <TextContainer>
            <div style={{ display: 'flex', gap: '5px' }}>
              <StyledTitle color={theme.palette.secondary.main}>FixUP! </StyledTitle>
              <StyledTitle>siempre cerca</StyledTitle>
            </div>
            <StyledDescription>
              {' '}
              Estés donde estés, hay un centro FixUP! a 20 minutos de vos.
            </StyledDescription>
          </TextContainer>
          <Grid container spacing={2}>
            {sucursales.map((address) => (
              <Grid item xs={12} sm={4} md={4} lg={3} key={address.id}>
                <AddressCard address={address} />
              </Grid>
            ))}
          </Grid>
          <StyledSpan fontWeight="bold" fontSize={16}>
            Somos la red de servicios más grande del país.
          </StyledSpan>
          <StyledSpan fontWeight={400} fontSize={12}>
            Contamos con Centros de Servicio Autorizados estés donde estés.
          </StyledSpan>
          <ButtonContainer>
            <StyledButton color="secondary" variant="contained">
              Cotizar ahora
            </StyledButton>
          </ButtonContainer>
        </LeftSideContent>
        <ImageContainer>
          <Image src={flag} />
        </ImageContainer>
      </StyledSection>
    </ComponentContainer>
  );
};

export default AlwaysCloseComponent;

const Image = muiStyled('img')(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      height: '300px'
    }
  };
});

const ButtonContainer = muiStyled('div')(() => {
  return {
    margin: 'auto'
  };
});

const ImageContainer = muiStyled('div')(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      right: '-75%',
      top: '-475px',
      height: '50px'
    },
    height: '175px',
    position: 'relative',
    right: '-65%',
    top: '-60px'
  };
});

const ComponentContainer = muiStyled('div')(() => {
  return {
    width: '100%',
    backgroundColor: 'white',
    padding: '30px 0 30px'
  };
});

const LeftSideContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    height: '100%',
    maxWidth: '700px'
  };
});
