import map from 'assets/services/Maps.png';
import cage from 'assets/services/Package.png';
import conference from 'assets/services/Conference.png';
import microscope from 'assets/services/Microscope.png';

import search from 'assets/how-fixup-works/1.png';
import calendar from 'assets/how-fixup-works/2.png';
import gift from 'assets/how-fixup-works/5.png';

const SECTIONS_DATA = {
  iTakeIt: {
    image: map,
    title: 'Yo lo llevo',
    description:
      'Cotizá tu reparación y acercate a un punto FixUP! con turno para una atención exclusiva.',
    steps: [
      {
        id: 1,
        image: search,
        title: 'Cotizá tu reparación online',
        description:
          'Seleccioná tu tipo de equipo, marca, modelo y la falla que tiene y recibí una cotización en tiempo real.'
      },
      {
        id: 2,
        image: calendar,
        title: 'Llevalo hasta una sucursal',
        description:
          'Elegí la sucursal que prefieras dentro de más de 100 opciones y agendá\n' +
          'un turno para llevar tu equipo y recibir atención exclusiva. Si estás lejos de \n' +
          'nuetras sucursales, podrás elegir una sucursal de correo postal para dejar tu equipo.'
      },
      {
        id: 3,
        image: gift,
        title: 'Listo! tu equipo reparado como nuevo',
        description:
          'Una vez realizado el diagnóstico, recibirás la confirmación del presupuesto \n' +
          'para pagar de forma online y segura.\n' +
          'Una vez reparado, podrás retirarlo por donde lo dejaste.'
      }
    ],
    FAQ: [
      {
        id: 1,
        question: '¿Qué significa cuando la reparación está en revisión?',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees.'
      },
      {
        id: 2,
        question: '¿Qué significa cuando la reparación está en revisión? 2',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 2'
      },
      {
        id: 3,
        question: '¿Qué significa cuando la reparación está en revisión? 3',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 3'
      }
    ]
  },
  atHome: {
    image: cage,
    title: 'Retiro a domicilio',
    description:
      'Retiramos tu equipo estés donde estés y te lo llevamos \n' +
      'de vuelta reparado como nuevo.',
    steps: [
      {
        id: 1,
        image: search,
        title: 'Lo retiramos por tu domicilio',
        description:
          'Seleccioná tu tipo de equipo, marca, modelo y la falla que tiene y recibí una cotización en tiempo real.'
      },
      {
        id: 2,
        image: calendar,
        title: 'Llevalo hasta una sucursal',
        description:
          'Elegí cuándo querés que lo retiremos. Nuestra logística pasará por\n' +
          'el domicilio y lo llevará a nuestro laboratorio especializado.'
      },
      {
        id: 3,
        image: gift,
        title: 'Listo! tu equipo reparado como nuevo',
        description:
          'Una vez realizado el diagnóstico, recibirás la confirmación del presupuesto \n' +
          'para pagar de forma online y segura.\n' +
          'Una vez reparado, nuestra logística lo llevará de regreso. '
      }
    ],
    FAQ: [
      {
        id: 1,
        question: '¿Qué significa cuando la reparación está en revisión?',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees.'
      },
      {
        id: 2,
        question: '¿Qué significa cuando la reparación está en revisión? 2',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 2'
      },
      {
        id: 3,
        question: '¿Qué significa cuando la reparación está en revisión? 3',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 3'
      }
    ]
  },
  remote: {
    image: conference,
    title: 'Servicio Remoto',
    description: 'Resolvemos la falla sin que te muevas de tu casa.',
    steps: [
      {
        id: 1,
        image: search,
        title: 'Cotizá tu reparación online',
        description:
          'Seleccioná tu tipo de equipo, marca, modelo y la falla que tiene y recibí una cotización en tiempo real.'
      },
      {
        id: 2,
        image: calendar,
        title: 'Agendá una llamada',
        description:
          'Elegí cuándo querés que nos conectemos para resolver la falla. \n' +
          'Nuestros técnicos especialistas te ayudarán de forma remota.'
      },
      {
        id: 3,
        image: gift,
        title: 'Listo! tu equipo reparado como nuevo',
        description:
          'Resolveremos la falla sin que te muevas de tu casa. En caso de \n' +
          'requerir asistencia en laboratorio, te bonificaremos lo abonado por\n' +
          'el servicio remoto.'
      }
    ],
    FAQ: [
      {
        id: 1,
        question: '¿Qué significa cuando la reparación está en revisión?',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees.'
      },
      {
        id: 2,
        question: '¿Qué significa cuando la reparación está en revisión? 2',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 2'
      },
      {
        id: 3,
        question: '¿Qué significa cuando la reparación está en revisión? 3',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 3'
      }
    ]
  },
  doItYourself: {
    image: microscope,
    title: 'Hágalo usted mismo (DIY)',
    description: 'Recibí un KIT con todo lo necesario para resolverlo vos mismo.',
    steps: [
      {
        id: 1,
        image: search,
        title: 'Cotizá tu reparación online',
        description:
          'Seleccioná tu tipo de equipo, marca, modelo y la falla que tiene y recibí una cotización en tiempo real.'
      },
      {
        id: 2,
        image: calendar,
        title: 'Enviamos el KIT',
        description:
          'Elegí dónde recibir el KIT con repuesto y herramientas para reparar el\n' +
          'equipo vos mismo con nuestras instrucciones. '
      },
      {
        id: 3,
        image: gift,
        title: 'Listo! tu equipo reparado como nuevo',
        description:
          'Resolvé vos mismo la falla. En caso de requerir asistencia en laboratorio, \n' +
          'podrás coordinar el envío del equipo.'
      }
    ],
    FAQ: [
      {
        id: 1,
        question: '¿Qué significa cuando la reparación está en revisión?',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees.'
      },
      {
        id: 2,
        question: '¿Qué significa cuando la reparación está en revisión? 2',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 2'
      },
      {
        id: 3,
        question: '¿Qué significa cuando la reparación está en revisión? 3',
        answer:
          'Esto quiere decir que cuando el técnico revisó tu equipo, tenía más fallas de las que fueron descritas\n' +
          'y probablemente el diagnóstico cambie. No te preocupes, eso puede suceder más de lo que crees. 3'
      }
    ]
  }
};

export default SECTIONS_DATA;
