import AccordionQuestions from 'components/accordion-questions/accordion-questions';
import StyledSection from 'components/common/styled-section';
// import { TOPICS } from './data';
// import { Grid } from '@mui/material';
// import TopicCard from './components/topic-card';
// import TextContainer from 'components/common/styled-text-section';
// import { StyledTitle } from 'components/common/styled-span';
import TopBanner from './components/top-banner';
import SupportBanner from '../../components/support-banner/support-banner';
// import { styled as muiStyled } from '@mui/material/styles';

import { PageContainer } from '../../components/common/styled-page-container';
// import PricingSearch from '../../components/search/pricing-search';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from 'redux/actions/common.actions';
import { useEffect } from 'react';
import { scrollToSectionSmoothly } from 'utilities/utils';

const ContactoPage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.common.FAQPath);

  const handleClick = () => {
    scrollToSectionSmoothly('#questions', 1000, true);
  };

  useEffect(() => {
    dispatch(commonActions.getFAQPath.request('/contacto'));
  }, [dispatch]);
  return (
    <>
      <TopBanner handleClick={handleClick} />
      <PageContainer disableMarginTop>
        {/* <StyledSection>
          <SearchSection>
            <StyledTitle sx={{ marginTop: '0.8rem' }}>¿En qué podemos ayudarte hoy?</StyledTitle>
            <PricingSearch />
          </SearchSection>
        </StyledSection>
        <StyledSection>
          <TextContainer>
            <StyledTitle>También podés buscar por tema:</StyledTitle>
          </TextContainer>
          <Grid container spacing={3}>
            {TOPICS.map((topic) => (
              <Grid item xs={12} sm={6} md={4} key={topic.id}>
                <TopicCard topic={topic} />
              </Grid>
            ))}
          </Grid>
        </StyledSection> */}
        <StyledSection id="questions" style={{ marginTop: '2rem' }}>
          {data.map((question) => (
            <AccordionQuestions key={question.id} FAQ={question} />
          ))}
        </StyledSection>
        <SupportBanner />
      </PageContainer>
    </>
  );
};

export default ContactoPage;

// const SearchSection = muiStyled('div')(() => {
//   return {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: '15px'
//   };
// });
