import { styled as muiStyled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { StyledSpan } from '../common/styled-span';

const ServiceCard = ({ service }) => {
  const { title, description, image, path, isFeatured } = service;
  return (
    <CardContainer elevation={0}>
      <CardContent>
        {isFeatured && <FeaturedService>Destacado</FeaturedService>}
        <img src={image} alt="service" height="90px" loading="lazy" />
        <StyledSpan fontWeight={700} fontSize={12}>
          {title}
        </StyledSpan>
        <StyledSpan fontWeight={400} fontSize={12} textalign="center">
          {description}
        </StyledSpan>
        <LinkTo href={path}>Conocer más</LinkTo>
      </CardContent>
    </CardContainer>
  );
};

export default ServiceCard;

const CardContainer = muiStyled(Card)(() => {
  return {
    backgroundColor: 'white',
    borderRadius: '10px',
    height: '220px',
    width: '240px',
    padding: '5px'
  };
});

const CardContent = muiStyled('div')(() => {
  return {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  };
});

const LinkTo = muiStyled('a')(() => {
  return {
    fontSize: 12,
    fontWeight: 700
  };
});

const FeaturedService = muiStyled('div')(() => {
  return {
    backgroundColor: '#3872E6',
    color: 'white',
    width: '90px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 700,
    marginLeft: 'auto'
  };
});
