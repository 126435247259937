import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../common/styled-span';
import CustomTextField from '../text-field/text-field';
import StyledCard from '../common/styled-card';

const SelectOptions = ({ handleChange, options, answer }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      {options.map((option) => (
        <div key={option.id} onClick={(e) => handleChange(e, option)}>
          <SelectOption selected={answer === option} scale={1}>
            <StyledSpan fontSize={12} fontWeight={700}>
              {option.respuesta}
            </StyledSpan>
          </SelectOption>
        </div>
      ))}
    </div>
  );
};

const InputFile = ({ handleChange, setFile }) => {
  const addFile = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      handleChange(e, null, file);
      setFile(file);
    }
  };
  return (
    <div style={{ height: 150, width: '100%', borderRadius: 20, border: '1px solid black' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          width: '100%',
          height: '100%'
        }}>
        {/* <button style={{ width: 'max-content' }} onClick={handleChange}>
          Upload File 
          
        </button> */}
        <input type="file" onChange={(e) => addFile(e)} />
        <h3 style={{ margin: 0 }}>Adjuntar foto</h3>
        <span>Solo se permiten archivos .jpg o .png</span>
      </div>
    </div>
  );
};

const QuestionComponent = ({ question, handleChange, answer, setFile }) => {
  const { pregunta, campo_pregunta_id, respuestas_adicionales } = question;
  return (
    <QuestionContainer>
      <StyledSpan fontSize={20} fontWeight={400}>
        {pregunta}
      </StyledSpan>
      {(campo_pregunta_id === 1 || campo_pregunta_id === 2) && (
        <SelectOptions
          options={respuestas_adicionales}
          handleChange={handleChange}
          answer={answer}
        />
      )}
      {(campo_pregunta_id === 3 || campo_pregunta_id === 5 || campo_pregunta_id === 6) && (
        <CustomTextField onChange={handleChange} />
      )}
      {campo_pregunta_id === 4 && <InputFile handleChange={handleChange} setFile={setFile} />}
    </QuestionContainer>
  );
};

export default QuestionComponent;

const SelectOption = muiStyled(StyledCard)(({ ...props }) => {
  return {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: props.selected && '2px solid #6CE194'
  };
});

const QuestionContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px'
  };
});
