import TextContainer from 'components/common/styled-text-section';
import { styled as muiStyled } from '@mui/material/styles';
import contactBanner from 'assets/banners/contactBanner.png';
import { StyledSpan } from 'components/common/styled-span';
import StyledButton from '../../../components/common/styled-button';

const TopBanner = ({ handleClick }) => {
  return (
    <ComponentContainer>
      <BannerContainer>
        <BannerContent>
          <ImageContainer>
            <img src={contactBanner} height="100%" alt="contact" loading="lazy" />
          </ImageContainer>
          <TextContainer>
            <StyledBannerTitle>Estamos para vos.</StyledBannerTitle>
            <StyledBannerSubtitle>
              ¿Tenés dudas? ¿Necesitas ayuda con tu reparación?
            </StyledBannerSubtitle>
            <StyledBannerSubtitle>Podés contactarnos ahora.</StyledBannerSubtitle>
          </TextContainer>
          <StyledButton color="secondary" variant="contained" onClick={() => handleClick()}>
            Solicitar asistencia ahora
          </StyledButton>
        </BannerContent>
      </BannerContainer>
    </ComponentContainer>
  );
};

export default TopBanner;

const ImageContainer = muiStyled('div')(({ theme }) => {
  return {
    position: 'absolute',
    right: '10%',
    top: '25%',
    zIndex: 0,
    height: '400px',
    [theme.breakpoints.down('md')]: {
      right: '25%',
      top: '15%'
    },
    [theme.breakpoints.down('xs')]: {
      right: '10%',
      top: '30%',
      height: '300px'
    }
  };
});

const BannerContent = muiStyled('div')(({ theme }) => {
  return {
    maxWidth: '900px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '150px'
    }
  };
});

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  };
});

const BannerContainer = muiStyled('div')(() => {
  return {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100vh',
    backgroundColor: '#36373C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  };
});

const StyledBannerTitle = muiStyled(StyledSpan)(({ theme }) => {
  return {
    fontWeight: 'bold',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: 42
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 64
    }
  };
});

const StyledBannerSubtitle = muiStyled(StyledSpan)(({ theme }) => {
  return {
    fontWeight: 300,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24
    }
  };
});
