import { StyledStepContainer } from '../../../../components/styled-overlay';
import { StyledSpan, StyledTitle } from 'components/common/styled-span';
import { FormControl, Grid, RadioGroup } from '@mui/material';
import CustomSelect from 'components/select/select';
import pricingActions from 'redux/actions/pricing.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import CustomTextField from '../../../../../../components/text-field/text-field';
import CustomDatePicker from '../../../../../../components/date-picker/date-picker';
import theme from '../../../../../../styles/theme';
import { styled as muiStyled } from '@mui/material/styles';
import SelectRadio from '../../../../../../components/select-radio/select-radio';
import { BottomBar, HolderBottomBar } from '../../../../components/bottom-bar';
import { ContinueButton } from '../../../../components/buttons';
import { isNullUndefEmptyStr } from '../../../../../../utilities/utils';
import { useNavigate } from 'react-router-dom';

const YourselfChooseAddress = () => {
  const [addressData, setAddressData] = useState({
    provincia: null,
    barrio: null,
    calle: null,
    numero: null,
    piso: null,
    observaciones: null,
    dia: null,
    hora: ''
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PROVINCIAS = useSelector((state) => state.pricing.provincias);
  const BARRIOS = useSelector((state) => state.pricing.barrios);
  useEffect(() => {
    dispatch(pricingActions.getProvincias.request());
  }, [dispatch]);
  useEffect(() => {
    if (addressData.provincia) {
      dispatch(pricingActions.getBarrios.request(addressData.provincia));
    }
  }, [addressData.provincia, dispatch]);
  return (
    <StyledStepContainer>
      <StyledTitle>¿A dónde enviamos tu KIT?</StyledTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomSelect
            value={addressData.provincia}
            label="Provincia"
            labelField="nombre"
            valueField="id_georef_ar"
            options={PROVINCIAS}
            onChange={(event) => setAddressData({ ...addressData, provincia: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomSelect
            value={addressData.barrio}
            label="Barrio"
            labelField="nombre"
            valueField="id"
            options={BARRIOS ?? []}
            onChange={(event) => setAddressData({ ...addressData, barrio: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Calle'}
            onChange={(event) => setAddressData({ ...addressData, calle: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Numero'}
            onChange={(event) => setAddressData({ ...addressData, numero: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Piso o departamento'}
            onChange={(event) => setAddressData({ ...addressData, piso: event.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            label={'Observaciones'}
            onChange={(event) =>
              setAddressData({ ...addressData, observaciones: event.target.value })
            }
          />
        </Grid>
      </Grid>
      <DateContainer>
        <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
          ¿A partir de qué día puedes recibirlo?
        </StyledSpan>
        <StyledSpan fontSize={16} fontWeight={400} color={theme.palette.lightblue.main}>
          Normalmente el envío será en el día que elijas o al día posterior.
        </StyledSpan>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <CustomDatePicker
              value={addressData.dia}
              onChange={(value) => setAddressData({ ...addressData, dia: value })}
            />
          </Grid>
        </Grid>
      </DateContainer>
      <DateContainer>
        <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
          ¿Cuál es tu franja horaria de preferencia?
        </StyledSpan>
        <StyledSpan fontSize={16} fontWeight={400} color={theme.palette.lightblue.main}>
          El horario es sólo informativo.
        </StyledSpan>
        <FormControl>
          <RadioGroup
            onChange={(event) => setAddressData({ ...addressData, hora: event.target.value })}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <SelectRadio value={8} label={'8hrs - 13hrs'} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <SelectRadio value={14} label={'14hrs - 19hrs'} />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </DateContainer>
      <HolderBottomBar />
      <BottomBar>
        <ContinueButton
          handleClick={() => navigate('/confirmacion')}
          disabled={isNullUndefEmptyStr(addressData) || addressData?.hora?.length === 0}>
          Continuar
        </ContinueButton>
      </BottomBar>
    </StyledStepContainer>
  );
};

export default YourselfChooseAddress;

const DateContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  };
});
