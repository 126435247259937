import { StyledTitle } from 'components/common/styled-span';
import ReviewsGrid from 'components/reviews/reviews-grid/reviews-grid';
import { styled as muiStyled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const RepairedThisWeekSection = () => {
  const reviews = useSelector((state) => state.common.reviews).slice(0, 3);
  return (
    <ComponentContainer>
      <StyledTitle>Personas que repararon sus equipos esta semana</StyledTitle>
      <ReviewsGrid reviews={reviews} />
    </ComponentContainer>
  );
};

export default RepairedThisWeekSection;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px'
  };
});
