import { styled as muiStyled } from '@mui/material/styles';
import { StyledDescription, StyledTitle } from 'components/common/styled-span';

const SectionHeader = ({ image, title, description }) => {
  return (
    <ComponentContainer>
      <Image src={image} alt="service" />
      <TextContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </TextContainer>
    </ComponentContainer>
  );
};

export default SectionHeader;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  };
});

const Image = muiStyled('img')(({ theme }) => {
  return {
    [theme.breakpoints.down('xs')]: {
      width: '60px',
      marginBottom: '60px'
    },
    [theme.breakpoints.up('md')]: {
      height: '100px'
    }
  };
});

const TextContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  };
});
