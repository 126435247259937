import { styled as muiStyled } from '@mui/material/styles';
import StyledCard from 'components/common/styled-card';
import { StyledSpan } from '../../common/styled-span';
import theme from '../../../styles/theme';

const BrandCard = ({ brandImage, text }) => (
  <CardContainer>
    <Image src={brandImage} alt="brand" />
    <StyledSpan fontSize={14} fontWeight={700} color={theme.palette.lightblue.main}>
      {text}
    </StyledSpan>
  </CardContainer>
);

export default BrandCard;

const Image = muiStyled('img')(() => {
  return {
    maxWidth: '70px',
    maxHeight: '70px'
  };
});

const CardContainer = muiStyled(StyledCard)(() => {
  return {
    borderRadius: '10px',
    height: '90px',
    width: '90px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white'
  };
});
