import { styled as muiStyled } from '@mui/material/styles';

export const FooterDiv = muiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.main,
  minHeight: '438px',
  width: '100%',
  zIndex: 10,
  position: 'relative',
  bottom: 0
}));

export const FooterContent = muiStyled('div')(() => ({
  maxWidth: '1200px',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  gap: '10px',
  padding: '20px 0px 20px 0px'
}));
