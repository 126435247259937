const services = {
  ITakeIt: [4],
  AtHome: [2, 7, 71, 72, 73, 74],
  Remote: [3],
  DoItYourself: [5]
};

export default function getServiceName(id) {
  for (const serviceName in services) {
    if (services[serviceName].includes(id)) {
      return serviceName;
    }
  }
}
