import { styled as muiStyled } from '@mui/material/styles';

export const ComponentContainer = muiStyled('div')(() => {
  return {
    backgroundColor: '#FEFEFE',
    padding: '30px 0 30px'
  };
});

export const ComponentContent = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  };
});

export const ImageContainer = muiStyled('div')(({ theme }) => {
  return {
    margin: 'auto',
    height: '515px',
    [theme.breakpoints.down('md')]: {
      height: '250px',
      margin: 'auto'
    }
  };
});

export const GridContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px'
  };
});
