import warranty from 'assets/benefits/warranty.svg';
import gift from 'assets/benefits/gift.svg';
import dues from 'assets/benefits/dues.svg';

export const BENEFITS = [
  {
    id: 1,
    primaryText: 'Garantía',
    secondaryText: 'Internacional'
  },
  {
    id: 2,
    primaryText: 'Servicios en',
    secondaryText: 'Todo el país'
  },
  {
    id: 3,
    primaryText: 'Diagnóstico',
    secondaryText: 'Bonificado'
  },
  {
    id: 4,
    primaryText: 'Cuotas',
    secondaryText: 'Sin interés'
  }
];

export const BENEFITS_SERVICES = [
  {
    image: warranty,
    primaryText: 'Garantía',
    secondaryText: 'Internacional',
    id: 1
  },
  {
    image: dues,
    primaryText: 'Cuotas',
    secondaryText: 'Sin interés',
    id: 2
  },
  {
    image: gift,
    primaryText: 'Diagnóstico',
    secondaryText: 'Bonificado',
    id: 3
  }
];
