import { styled as muiStyled } from '@mui/material/styles';

export const StyledMenuItem = muiStyled('a')(({ theme, ...props }) => {
  return {
    color: props.color ? props.color : theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: props.fontWeight,
    fontSize: props.fontSize,
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Inter'
  };
});
