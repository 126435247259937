import { StyledSpan } from 'components/common/styled-span';
import Theme from 'styles/theme';
import StyledSection from 'components/common/styled-section';
import PartnerCard from '../../../../components/partners/partner-card/partner-card';
import { fullImageURL } from '../../../../utilities/utils';
import { Grid } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

const PartnersSection = ({ partners }) => {
  return (
    <StyledSection>
      <ComponentContainer>
        <StyledSpan fontWeight="bold" color={Theme.palette.primary.main} fontSize={24}>
          Nuestros partners
        </StyledSpan>
        <StyledSpan fontWeight="light" color={Theme.palette.primary.main} fontSize={18}>
          Somos mejores gracias a nuestros socios estratégicos; colaboradores logísticos,
          proveedores tecnológicos y comerciales.
        </StyledSpan>
        <Grid container spacing={3} justifyContent={'center'}>
          {partners.map((partner) => (
            <Grid item xs="auto" key={partner.id}>
              <PartnerCard image={fullImageURL(partner.imagen)} />
            </Grid>
          ))}
        </Grid>
      </ComponentContainer>
    </StyledSection>
  );
};

export default PartnersSection;

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  };
});
