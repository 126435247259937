import { styled as muiStyled } from '@mui/material/styles';
import Card from '@mui/material/Card';

const StyledCard = muiStyled(Card)(({ theme, ...props }) => {
  return {
    borderRadius: '10px',
    border: '2px solid transparent',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    '&:hover': {
      border: !props.disableHover && `2px solid ${theme.palette.success.main}`,
      cursor: !props.disableHover && 'pointer',
      transform: !props.disableHover && `scale(${props.scale ?? 1.1})`
    }
  };
});

export default StyledCard;
