import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from 'components/common/styled-span';
import theme from '../../styles/theme';
import StyledCard from '../common/styled-card';

const ColorCard = ({ color }) => {
  const { color_nombre, color_codigo } = color;
  return (
    <ComponentContainer>
      <ColorContainer>
        <ColorCircle color={color_codigo} />
      </ColorContainer>
      <StyledSpan fontWeight={700} fontSize={12} color={theme.palette.lightblue.main}>
        {color_nombre}
      </StyledSpan>
    </ComponentContainer>
  );
};

export default ColorCard;

const ColorCircle = muiStyled('div')(({ ...props }) => {
  return {
    borderRadius: 50,
    backgroundColor: props.color,
    height: '100%'
  };
});

const ColorContainer = muiStyled(StyledCard)(({ theme }) => {
  return {
    height: '64px',
    width: '64px',
    borderRadius: 50,
    border: `2px solid ${theme.palette.lightblue.main}`,
    padding: 7
  };
});

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    height: '100px',
    width: '80px'
  };
});
