import { get } from 'utilities/http';

const id_region = () => {
  return JSON.parse(localStorage.getItem('region-id'));
};

export const getReviews = () => {
  return get(`/web/calificacionesFixup/1`);
};

export const getOfficialBrands = () => {
  return get(`/web/marcasOficiales/${id_region()}`);
};

export const getFixUpInMedia = () => {
  return get(`/web/mediosFixup/${id_region()}`);
};

export const getSucursalesCercanas = () => {
  return get(`/web/sucursalesCercanasIP/${id_region()}`);
};

export const getPartners = () => {
  return get(`/web/partnersFixup/${id_region()}`);
};

export const getInvestors = () => {
  return get(`/web/investorsFixup/${id_region()}`);
};

export const getModelsByText = (text) => {
  return get(`/web/buscador/${id_region()}/${text}`);
};

export const getTopBanner = (path) => {
  return get(`/web/topBarFixup/${id_region()}${path === '/' ? '/home' : path}`);
};

export const getPromotionalBanner = (path) => {
  return get(`/web/promocionFixup/${id_region()}${path === '/' ? '/home' : path}`);
};

export const getFAQ = () => {
  return get(`/web/preguntasFrecuentes/${id_region()}`);
};

export const getFAQPath = (path) => {
  return get(`/web/preguntasFrecuentes/${id_region()}${path === '/' ? '/1' : path}`);
};
