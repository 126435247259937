import { styled as muiStyled } from '@mui/material/styles';
import rocket from 'assets/banners/rocket.png';
import StyledSection from 'components/common/styled-section';
import TextContainer from 'components/common/styled-text-section';
import { StyledDescription, StyledTitle } from 'components/common/styled-span';

const RocketBanner = () => {
  return (
    <StyledSection id="rocket-banner">
      <ComponentContainer>
        <TextContainer>
          <StyledTitle>
            Nacimos con el propósito de darle una segunda vida a la tecnología.
          </StyledTitle>
          <StyledDescription>
            En el mundo ya hay demasiada basura electrónica. Nosotros buscamos reducir eso, dándole
            una nueva oportunidad a los dispositivos de miles de personas.
          </StyledDescription>
        </TextContainer>
        <ImageContainer>
          <Image src={rocket} alt="rocket" />
        </ImageContainer>
      </ComponentContainer>
    </StyledSection>
  );
};

export default RocketBanner;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px 0 30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '30px'
    }
  };
});

const Image = muiStyled('img')(({ theme }) => {
  return {
    height: '540px',
    [theme.breakpoints.down('sm')]: {
      height: '250px',
      position: 'relative',
      bottom: '10px'
    }
  };
});

const ImageContainer = muiStyled('div')(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      height: '370px',
      position: 'relative',
      top: '-60px',
      right: '60px',
      width: '350px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '150px',
      margin: 'auto'
    }
  };
});
