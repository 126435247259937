import ReviewsGrid from 'components/reviews/reviews-grid/reviews-grid';
import { StyledSpan } from 'components/common/styled-span';
import Theme from 'styles/theme';
import './reviews-section.scss';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from 'redux/actions/common.actions';
import CustomCarousel from 'components/carousel/carousel';
import ReviewCard from 'components/reviews/review-card/review-card';
import STATUS from 'constants/status';
import LoaderComponent from '../../../../components/loader/loader-component';

const ReviewsSection = ({ mobileView }) => {
  const reviews = useSelector((state) => state.common.reviews);
  const reviewsState = useSelector((state) => state.common.getReviewsStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getReviews.request());
  }, [dispatch]);
  return (
    <section className="reviews-section">
      <StyledSpan color={Theme.palette.primary.main} fontWeight={700} fontSize="24px">
        Miles de personas ya vivieron la experiencia FixUP!
      </StyledSpan>
      {reviewsState === STATUS.loading && <LoaderComponent />}
      {reviews && (
        <>
          {mobileView ? (
            <CustomCarousel loop renderArrow={() => <div />}>
              {reviews.map((review) => (
                <ReviewCard key={review.id} review={review} />
              ))}
            </CustomCarousel>
          ) : (
            <ReviewsGrid reviews={reviews} />
          )}
        </>
      )}
    </section>
  );
};

export default ReviewsSection;
