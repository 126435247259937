import StyledSection from 'components/common/styled-section';
import { useState, useEffect } from 'react';
import { Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import theme from 'styles/theme';
import PricingStatus from './components/pricing-status/pricing-status';
import { StyledSpan } from 'components/common/styled-span';
import DeviceStep from './steps/device-step/device-step';
import BrandStep from './steps/brand-step/brand-step';
import ModelStep from './steps/model-step/model-step';
import FlawStep from './steps/flaw-step/flaw-step';
import ClientStep from './steps/client-step/client-step';
import ServiceStep from './steps/service-step/service-step';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer } from 'components/common/styled-page-container';
import StyledButton from '../../components/common/styled-button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import pricingActions from '../../redux/actions/pricing.actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Onboarding from '../../components/onboarding/onboarding';

const RepararPage = () => {
  const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [activeStep, setActiveStep] = useState(0);
  const [activeServiceStep, setActiveServiceStep] = useState(0);
  const serviceSelected = useSelector((state) => state.pricing.service.type);
  const deviceType = useSelector((state) => state.pricing.device.type);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { device, brand } = useParams();
  const showOnboarding = JSON.parse(localStorage.getItem('show-onboarding'));
  let state = location.state;
  useEffect(() => {
    setActiveStep(0);
    if (brand && device) setActiveStep(2);
    else if (device) setActiveStep(1);
  }, []);
  useEffect(() => {
    if (state?.modelSelected && deviceType?.nombre) {
      dispatch(pricingActions.getDeviceByName.request(deviceType.nombre));
      setActiveStep(3);
    }
  }, [state]);
  useEffect(() => {
    if (state?.deviceState) {
      dispatch(pricingActions.device.update('type', state.deviceState));
      setActiveStep(1);
    }
  }, [state]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      dispatch(
        pricingActions.getGeolocation.request(position.coords.latitude, position.coords.longitude)
      );
    });
  }, []);
  const backButton = () => {
    if (activeStep === 4) {
      dispatch(pricingActions.device.updateAnswers('CLEAR'));
    }

    if (activeStep === 5 && activeServiceStep !== 0) {
      setActiveServiceStep((prevCount) => Math.max(prevCount - 1, 0));
    } else {
      setActiveStep((prevCount) => Math.max(prevCount - 1, 0));
      if (activeStep < 3) {
        let newUrl = location.pathname.substring(0, location.pathname.lastIndexOf('/') + 1);
        if (newUrl.endsWith('/')) {
          newUrl = newUrl.substring(0, newUrl.length - 1);
        }
        navigate(newUrl);
      }
    }
  };
  const steps = [
    {
      id: 1,
      label: 'Equipo',
      content: DeviceStep
    },
    { id: 2, label: 'Marca', content: BrandStep },
    { id: 3, label: 'Modelo', content: ModelStep },
    { id: 4, label: 'Falla', content: FlawStep },
    { id: 5, label: 'Datos', content: ClientStep },
    { id: 6, label: 'Servicio', content: ServiceStep }
  ];
  const ActualStep = steps[activeStep].content;

  return (
    <PageContainer>
      <StyledSection>
        {showOnboarding && <Onboarding />}
        <BackButtonContainer>
          {activeStep !== 0 && (
            <StyledButton
              gap={1}
              onClick={backButton}
              variant="contained"
              color="white"
              height={40}
              width="max-content">
              <ArrowBackIosIcon sx={{ height: '15px' }} />
              Atrás
            </StyledButton>
          )}
        </BackButtonContainer>
        <PageContent>
          <StepperContainer>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              sx={{
                '& .MuiStepConnector-line': {
                  borderTopWidth: '2px'
                },
                '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
                  borderColor: theme.palette.success.main
                },
                '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
                  borderColor: theme.palette.success.main
                },
                width: '100%'
              }}>
              {steps.map((step) => (
                <Step
                  key={step.id}
                  sx={{
                    '& .MuiStepLabel-iconContainer .Mui-active': {
                      color: theme.palette.background.default,
                      border: `2px solid ${theme.palette.success.main}`,
                      borderRadius: 50
                    },
                    '& .MuiStepLabel-iconContainer .Mui-completed': {
                      color: theme.palette.success.main
                    },
                    '& .MuiStepIcon-text': {
                      fontSize: '0px'
                    }
                  }}>
                  <StepLabel>
                    <StyledSpan fontWeight={700} fontSize={12}>
                      {step.label}
                    </StyledSpan>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {isNotMobile && (
              <PricingStatus
                progress={(activeStep * 100) / steps.length}
                serviceSelected={serviceSelected}
              />
            )}
          </StepperContainer>
          <ActualStep
            file={file}
            setFile={setFile}
            activeServiceStep={activeServiceStep}
            setActiveStep={setActiveStep}
            setActiveServiceStep={setActiveServiceStep}
          />
        </PageContent>
      </StyledSection>
    </PageContainer>
  );
};

export default RepararPage;

const BackButtonContainer = muiStyled('div')(() => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'flex-start',
    margin: '10px 0px 20px 0px',
    height: 40
  };
});

const PageContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  };
});

const StepperContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    width: '100%',
    alignItems: 'center'
  };
});
