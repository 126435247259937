import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: '#0E194F',
      light: '#A2A9CC'
    },
    blue: {
      main: '#116FFF'
    },
    secondary: {
      main: '#FF393A'
    },
    grey: {
      main: '#36373B'
    },
    lightblue: {
      main: '#596394'
    },
    background: {
      default: 'rgb(245, 247, 250)'
    },
    lightgray: {
      main: '#A2A9CC'
    },
    success: {
      main: '#6CE194'
    },
    white: {
      main: '#FFFFFF'
    }
  }
});

export default theme;
