import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../common/styled-span';
import { isMobile } from '../../utilities/utils';

const ReasonCard = ({ reason }) => {
  const { image, title, description } = reason;
  return (
    <ComponentContainer>
      <Image src={image} alt="reasons to choose fixup" />
      <StyledSpan fontWeight="bold" fontSize="16px">
        {title}
      </StyledSpan>
      <StyledSpan textalign={isMobile() && 'center'} fontWeight={400} fontSize="12px">
        {description}
      </StyledSpan>
    </ComponentContainer>
  );
};

export default ReasonCard;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: 'max-content',
    maxWidth: '270px',
    gap: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

const Image = muiStyled('img')(() => {
  return {
    height: 80,
    width: 'max-content'
  };
});
