import { PageContainer } from 'components/common/styled-page-container';
import FixUpInMediaSection from 'components/media/fix-up-in-media-section/fix-up-in-media-section';
import PartnersSection from '../reseñas/sections/partners-section/partners-section';
// import NumbersSpeakForUs from 'components/numbers-speak-for-us/numbers-speak-for-us';
import TransformBanner from './components/transform-banner/transform-banner';
import RocketBanner from './components/rocket-banner/rocket-banner';
import TopBanner from './components/top-banner/top-banner';
import { useEffect } from 'react';
import commonActions from '../../redux/actions/common.actions';
import { useDispatch, useSelector } from 'react-redux';
import InvestorsSection from '../../components/investors-section/investors-section';

const SobrePage = () => {
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.common.partners);
  const media = useSelector((state) => state.common.fixUpInMedia);
  const investors = useSelector((state) => state.common.investors);
  useEffect(() => {
    dispatch(commonActions.getPartners.request());
    dispatch(commonActions.getFixUpInMedia.request());
    dispatch(commonActions.getInvestors.request());
  }, [dispatch]);
  return (
    <PageContainer>
      <TopBanner />
      <RocketBanner />
      <TransformBanner />
      {/* <NumbersSpeakForUs /> */}
      <PartnersSection partners={partners} />
      <FixUpInMediaSection media={media} />
      <InvestorsSection investors={investors} />
    </PageContainer>
  );
};

export default SobrePage;
