import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../common/styled-span';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import pricingActions from '../../redux/actions/pricing.actions';
import StyledCard from '../common/styled-card';
import { fullImageURL } from '../../utilities/utils';
import theme from '../../styles/theme';

const FlawCheckbox = ({ flaw, selected, defaultImage }) => {
  const { nombre, imagen } = flaw;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(selected);
  const handleChange = () => {
    setChecked(!checked);
    dispatch(pricingActions.device.updateFlaw(flaw));
  };
  return (
    <div style={{ height: 56 }}>
      <FlawContainer checked={checked} onClick={handleChange} scale={1}>
        <FlawInfo>
          <div
            style={{ width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={fullImageURL(imagen ?? defaultImage)}
              alt="flaw"
              height={40}
              style={{ maxWidth: 40 }}
              loading="lazy"
            />
          </div>
          <StyledSpan fontSize={14} fontWeight={700}>
            {nombre}
          </StyledSpan>
        </FlawInfo>
        <Checkbox
          checked={checked}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{
            '&.Mui-checked': {
              color: theme.palette.success.main
            }
          }}
        />
      </FlawContainer>
    </div>
  );
};

export default FlawCheckbox;

const FlawContainer = muiStyled(StyledCard)(({ theme, ...props }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    border: props.checked ? '2px solid #6CE194' : '1px solid #A2A9CC',
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  };
});

const FlawInfo = muiStyled('div')(() => {
  return {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  };
});
