import image1 from 'assets/how-fixup-works/1.png';
import image2 from 'assets/how-fixup-works/2.png';
import image3 from 'assets/how-fixup-works/3.png';
import image4 from 'assets/how-fixup-works/4.png';
import image5 from 'assets/how-fixup-works/5.png';

export const STEPS = [
  {
    id: 1,
    title: 'Busca tu equipo',
    description: 'Selecciona tu tipo de \n' + 'equipo, marca, modelo\n' + 'y la falla que tiene.',
    image: image1
  },
  {
    id: 2,
    title: 'Elige el servicio',
    description: '¿Prefieres llevarlo, \n' + 'servicio a domicilio\n' + 'o asistencia remota?',
    image: image2
  },
  {
    id: 3,
    title: 'Seguí el estado de\n' + 'tu reparación',
    description: 'Puedes ver el estado de tu reparación en tiempo real\n' + '24x7x365.',
    image: image3
  },
  {
    id: 4,
    title: 'Paga online,\n' + 'seguro y en cuotas',
    description: 'Paga 100% Online y en cuotas sin interés.',
    image: image4
  },
  {
    id: 5,
    title: 'Te lo entregamos\n' + 'como nuevo',
    description: 'Una vez listo, entregamos tu equipo reparado ¡Cómo nuevo!.',
    image: image5
  }
];
