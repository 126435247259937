import { styled as muiStyled } from '@mui/material/styles';
import { STEPS } from './steps';
import StepComponent from './step-component';
import StyledSection from '../common/styled-section';
import { StyledDescription, StyledTitle } from '../common/styled-span';
import TextContainer from 'components/common/styled-text-section';

const HowFixupWorks = () => (
  <StyledSection>
    <TextContainer>
      <div style={{ display: 'flex', gap: '5px' }}>
        <StyledTitle>¿Cómo funciona?</StyledTitle>
      </div>
      <StyledDescription>¡Es muy simple! Mira:</StyledDescription>
    </TextContainer>
    <HorizontalLine />
    <ComponentContainer>
      {STEPS.map((step) => (
        <StepComponent key={step.id} step={step} />
      ))}
    </ComponentContainer>
  </StyledSection>
);

export default HowFixupWorks;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '25px',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

const HorizontalLine = muiStyled('hr')(({ theme }) => {
  return {
    [theme.breakpoints.up('xs')]: {
      borderTop: `dotted 1px ${theme.palette.primary.main}`,
      position: 'relative',
      top: '45px',
      width: `calc(100% - 85px)`,
      zIndex: -1,
      padding: '0px'
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      borderLeft: '1px solid hsla(200, 10%, 50%,100)',
      height: '0px'
    }
  };
});
