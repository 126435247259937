import { useState } from 'react';
import ChooseServiceStep from './choose-service-step';
import ChooseSucursal from './deliver-types/ITakeIt/choose-sucursal';
import TakeitChooseDate from './deliver-types/ITakeIt/takeit-choose-date';
import ChooseAddress from './deliver-types/AtHome/chooseAddress';
import RemoteChooseDate from './deliver-types/Remote/remote-choose-date';
import YourselfChooseAddress from './deliver-types/DoItYourself/yourself-choose-address';

const ServiceStep = ({ setActiveServiceStep, activeServiceStep }) => {
  const [steps, setSteps] = useState([ChooseServiceStep]);
  const SERVICES_STEPS = {
    ITakeIt: [ChooseServiceStep, ChooseSucursal, TakeitChooseDate],
    AtHome: [ChooseServiceStep, ChooseAddress],
    Remote: [ChooseServiceStep, RemoteChooseDate],
    DoItYourself: [ChooseServiceStep, YourselfChooseAddress]
  };
  const addSteps = (service) => {
    setSteps(SERVICES_STEPS[service]);
    setActiveServiceStep(1);
  };
  const ActiveServiceStep = steps[activeServiceStep];
  return <ActiveServiceStep setActiveServiceStep={setActiveServiceStep} addSteps={addSteps} />;
};

export default ServiceStep;
