import TextContainer from 'components/common/styled-text-section';
import { StyledSpan } from 'components/common/styled-span';
import { useEffect, useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import StyledSection from 'components/common/styled-section';
import Search from 'components/search/search';
import StyledButton from 'components/common/styled-button';
import DEVICES from '../../../utilities/devices';
import { isMobile } from '../../../utilities/utils';
import { useDispatch, useSelector } from 'react-redux';
import commonActions from '../../../redux/actions/common.actions';
import STATUS from '../../../constants/status';
import pricingActions from '../../../redux/actions/pricing.actions';
import { useNavigate } from 'react-router-dom';
import backgroundDesktop from './backgroundDesktop.png';
import backgroundMobile from './backgroundMobile.png';

const SearchBanner = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deviceType, setDeviceType] = useState({ index: 0, device: DEVICES[0] });
  const [searchWord, setSearchWord] = useState('');
  const models = useSelector((state) => state.common.modelsByText);
  const modelsStatus =
    useSelector((state) => state.common.getModelsByTextStatus) === STATUS.loading;

  useEffect(() => {
    function changeDevice() {
      if (deviceType.index === DEVICES.length - 1) {
        setDeviceType({ index: 0, device: DEVICES[0] });
      } else {
        setDeviceType({ index: deviceType.index + 1, device: DEVICES[deviceType.index + 1] });
      }
    }
    setTimeout(() => {
      changeDevice();
    }, 2500);
  }, [deviceType.index, dispatch]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchWord) dispatch(commonActions.getSearchModels.request(searchWord));
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchWord, dispatch]);

  const handleClick = () => {
    let modelSelected = models.filter((model) => model.equipo === searchWord)[0];

    if (modelSelected) {
      dispatch(
        pricingActions.device.update('type', {
          id: modelSelected.grupo_equipo_id,
          nombre: modelSelected.grupo_equipo_nombre
        })
      );
      dispatch(
        pricingActions.device.update('brand', {
          marca_id: modelSelected.marca_id,
          marca_nombre: modelSelected.marca_nombre
        })
      );
      dispatch(pricingActions.device.update('model', modelSelected.modelo_id));
      navigate(`/reparar`, { state: { modelSelected: true } });
    } else navigate('/reparar');
  };

  return (
    <StyledSection>
      <ComponentContainer>
        <TextContainer marginTop={isMobile() && '40px'}>
          <StyledSpan fontWeight="bold" fontSize={isMobile() ? 28 : 48}>
            ¡Tu equipo como nuevo!
          </StyledSpan>
          <StyledSpan fontSize={isMobile() ? 20 : 24} fontWeight={400}>
            ¿Se rompió tu <b>{deviceType.device.name}</b>?
          </StyledSpan>
        </TextContainer>
        <BannerContainer>
          <SearchBar>
            <Search
              options={models}
              loading={modelsStatus}
              onChange={setSearchWord}
              label="Buscar equipo"
              value={searchWord}
              field={'equipo'}
            />
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={handleClick}
              height={isMobile() && 50}
              padding={'0px 30px 0px 30px'}>
              Cotizar ahora
            </StyledButton>
          </SearchBar>
          <ImageContainer>
            <Image src={isMobile() ? backgroundMobile : backgroundDesktop} alt="devices" />
          </ImageContainer>
        </BannerContainer>
      </ComponentContainer>
    </StyledSection>
  );
};

export default SearchBanner;

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginTop: 50
    }
  };
});

const Image = muiStyled('img')(({ theme }) => {
  return {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 50,
      left: '65%',
      transform: 'translateX(-50%)'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      maxWidth: '90vw'
    }
  };
});

const ImageContainer = muiStyled('div')(() => {
  return {
    zIndex: -1
  };
});

const BannerContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    gap: '20px',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%'
    }
  };
});

const SearchBar = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    width: '100%',
    maxWidth: '800px',
    gap: 20,
    height: 'max-content',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%'
    }
  };
});
