import { styled as muiStyled } from '@mui/material/styles';
import HeaderBanner from 'assets/banners/sobreBanner.jpg';
import { StyledSpan } from '../../../../components/common/styled-span';

export const KnowMoreContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white'
  };
});

export const BannerContent = muiStyled('div')(() => {
  return {
    maxWidth: '900px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  };
});

export const BannerContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  };
});

export const BannerImageContainer = muiStyled('div')(() => {
  return {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100vh',
    backgroundImage: `url('${HeaderBanner}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  };
});

export const StyledBannerTitle = muiStyled(StyledSpan)(({ theme }) => {
  return {
    fontWeight: 'bold',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: 42
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 64
    }
  };
});

export const StyledBannerSubtitle = muiStyled(StyledSpan)(({ theme }) => {
  return {
    fontWeight: 300,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24
    }
  };
});

export const StyledBottomText = muiStyled(StyledSpan)(({ theme }) => {
  return {
    fontWeight: 300,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    }
  };
});
